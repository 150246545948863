import { Icon } from '@iconify/react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
// import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, Container, Link, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { FullLogo } from '../../components/Logo';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  const { t: translate } = useTranslation('login');
  const { logout, user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  if (!user.email || user.isActive) {
    handleLogout();
  }

  return (
    <RootStyle
      title={`${translate('verification_title')} | ${translate(
        'global:company'
      )}`}
    >
      <HeaderStyle>
        <RouterLink to="/">
          <FullLogo />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Button
            size="small"
            onClick={handleLogout}
            startIcon={
              <Icon icon="eva:arrow-ios-back-fill" width={20} height={20} />
            }
            sx={{ mb: 3 }}
          >
            {translate('login:menu_logout')}
          </Button>

          <Typography variant="h3" gutterBottom>
            {translate('verification_title')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {translate('verification_text').replace('{email}', user.email)}
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifyCodeForm />
          </Box>

          <Typography variant="body2" align="center">
            {translate('verification_resend')} &nbsp;
            <Link variant="subtitle2" underline="none" onClick={() => {}}>
              {translate('verification_resend_link')}
            </Link>
          </Typography>
        </Box>
      </Container>
    </RootStyle>
  );
}
