import { Box, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { DateRangeFilter } from '../../@types/filters';

import SelectTimePeriod from '../filters/SelectTimePeriod';
import ProfitRow from './ProfitRow';
import useProfit from '../../hooks/useProfit';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import { APIStatus } from '../../@types/APIStatus';
import { getPercentage } from '../../utils/helper';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import { FilterSelect } from '../items/filters/FilterSelect';

function Profit() {
  const { t: translate } = useTranslation(['component']);
  const { data, filters, updateProfit, error, APIStatus: state } = useProfit();
  const {
    article: { articleId }
  } = useArticlesDetail();
  const {
    orders,
    grossVolume,
    netVolume,
    discount,
    cancellations,
    returns,
    shippingCosts,
    buildingCosts,
    storageCosts,
    paymentFees,
    advertisingCosts,
    otherFees1,
    otherFees2,
    marketPlaceFees
  } = data;
  const [timePeriod, setTimePeriod] = useState<DateRangeFilter>(
    filters.timePeriod
  );

  const netSalesAfterCancellations = netVolume - cancellations;
  const netSalesAfterReturns = netSalesAfterCancellations - returns;

  const contributionMarginDB1 =
    netSalesAfterCancellations - (buildingCosts + otherFees1);
  const contributionMarginDB1Percent = getPercentage(
    contributionMarginDB1,
    netSalesAfterCancellations
  );

  const contributionMarginDB2 =
    contributionMarginDB1 - (storageCosts + paymentFees + otherFees2);
  const contributionMarginDB2Percent = getPercentage(
    contributionMarginDB2,
    netSalesAfterCancellations
  );

  const contributionMarginDB3 =
    contributionMarginDB2 - (advertisingCosts + marketPlaceFees);
  const contributionMarginDB3Percent = getPercentage(
    contributionMarginDB3,
    netSalesAfterCancellations
  );

  const [selectProfit, setSelectProfit] = useState<number>(0);

  useEffect(() => {
    updateProfit({ timePeriod, invoice: selectProfit }, `${articleId}`);
  }, [timePeriod, selectProfit, articleId]);

  return (
    <Card>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      <CardHeader title={translate('profit')} />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ maxWidth: '230px', marginRight: 2 }}>
                <FilterSelect
                  selected={selectProfit}
                  title={translate('profit_by')}
                  labels={[translate('by_order'), translate('by_invoice')]}
                  onChangeSelected={setSelectProfit}
                />
              </Box>

              <SelectTimePeriod
                startFilter={filters.timePeriod.startFilter}
                endFilter={filters.timePeriod.endFilter}
                onChangePeriod={setTimePeriod}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ProfitRow
              label={translate('orders')}
              value={orders}
              type="numeric"
            />
            <ProfitRow
              label={translate('volume_brutto')}
              value={grossVolume}
              type="currency"
            />
            <ProfitRow
              dark
              label={translate('volume_net')}
              value={netVolume}
              type="currency"
            />
            <ProfitRow
              label={translate('discount')}
              value={discount}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('cancelations')}
              value={cancellations}
              substract
              type="currency"
            />
            <ProfitRow
              dark
              label={translate('net_sales_after_cancelation')}
              value={netSalesAfterCancellations}
              type="currency"
            />

            <ProfitRow
              label={translate('returns')}
              value={returns}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('shipping_costs')}
              value={shippingCosts}
              substract
              type="currency"
            />
            <ProfitRow
              dark
              label={translate('net_sales_after_returns_shipping_costs')}
              value={netSalesAfterReturns}
              type="currency"
            />
            <ProfitRow
              label={translate('produktkosten_building_costs')}
              value={buildingCosts}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('other_fees')}
              substract
              value={otherFees1}
              type="currency"
            />
            <ProfitRow
              label={translate('db1_contribution_margin')}
              dark
              value={contributionMarginDB1}
              type="currency"
            />
            <ProfitRow
              label={translate('profit_margin_percent')}
              dark
              value={contributionMarginDB1Percent}
              type="percent"
            />

            <ProfitRow
              label={translate('storage_costs')}
              value={storageCosts}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('payment_fees')}
              value={paymentFees}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('other_fees')}
              substract
              value={otherFees2}
              type="currency"
            />
            <ProfitRow
              label={translate('db2_contribution_margin')}
              dark
              value={contributionMarginDB2}
              type="currency"
            />
            <ProfitRow
              label={translate('profit_margin_percent')}
              dark
              value={contributionMarginDB2Percent}
              type="percent"
            />
            <ProfitRow
              label={translate('advertising_costs')}
              value={advertisingCosts}
              substract
              type="currency"
            />
            <ProfitRow
              label={translate('marketplace_fees')}
              substract
              value={marketPlaceFees}
              type="currency"
            />
            <ProfitRow
              label={translate('db3_contribution_margin')}
              dark
              value={contributionMarginDB3}
              type="currency"
            />
            <ProfitRow
              label={translate('profit_margin_percent')}
              dark
              value={contributionMarginDB3Percent}
              type="percent"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Profit;
