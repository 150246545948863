import { Button, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StockRange } from './widgets/StockRange';
import { ProfitSummary } from './widgets/ProfitSummary';
import { AveragePrice } from './widgets/AveragePrice';
import { Returns } from './widgets/Returns';
import { HeaderTop } from './header/HeaderTop';
import { DateRangeFilter } from '../../@types/filters';

const useStyles = makeStyles<
  Theme,
  { colorProgress: string; colorOne: string; colorTwo: string }
>((theme) => ({
  columnFlex: {},
  circularProgressContainer: {
    position: 'relative'
  },
  colorCircularProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: ({ colorProgress }) => colorProgress
  },
  greyCircularProgress: {
    color: theme.palette.divider
  },
  lagerReichWeiter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  header: {
    // border: '1px solid red',
    height: 'auto',
    width: '100%',
    // marginBottom: 32,
    // minWidth: 360,
    [theme.breakpoints.up('lg')]: {
      height: 382,
      marginBottom: 117
    },
    padding: theme.spacing(2),
    borderRadius: 10,
    backgroundColor: '#fafafa'
    /* background: ({ colorTwo }) =>
      `linear-gradient(135deg, #fafafa 0%, #6c7b88 100%)` */
  },
  product: {
    border: '1px solid white'
  },
  labelWidget: {
    marginBottom: theme.spacing(2)
  },
  averagePrice: {
    border: '1px solid red',
    width: '100%',
    // margin: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  graphics: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  widgets: {
    marginTop: theme.spacing(3)
  },
  widgetContainer: {
    width: '100%',
    height: 183.28
  },
  widgetGridItem: {
    // border: '1px solid green',
  }
}));

type Props = {
  timePeriod: DateRangeFilter;
  onDateChange: (timePeriod: DateRangeFilter) => void;
  onTabChange: (tab: string, scroll: boolean) => void;
};

export default function ProductDetail({
  timePeriod,
  onDateChange,
  onTabChange
}: Props) {
  const history = useHistory();
  const { t: translate } = useTranslation(['component']);
  const classes = useStyles({
    colorProgress: '#43cbff',
    colorOne: '#637281',
    colorTwo: '#96a0aa'
  });

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Button variant="text" onClick={() => history.goBack()}>
          {translate('global:backLabel')}
        </Button>
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Paper variant="outlined" className={classes.header}>
          <HeaderTop
            timePeriod={timePeriod}
            onDateChange={onDateChange}
            onVariantsClicked={() => onTabChange('3', true)}
          />
          <Grid
            container
            direction="row"
            spacing={3.4}
            className={classes.widgets}
          >
            <Grid item xs={12} md={6} lg={3} className={classes.widgetGridItem}>
              <Paper elevation={5} className={classes.widgetContainer}>
                <AveragePrice />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.widgetGridItem}>
              <Paper elevation={5} className={classes.widgetContainer}>
                <ProfitSummary />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.widgetGridItem}>
              <Paper elevation={5} className={classes.widgetContainer}>
                <StockRange />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={classes.widgetGridItem}>
              <Paper elevation={5} className={classes.widgetContainer}>
                <Returns />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
