import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { ColumnCircle } from './ColumnCircle';
import { APIStatus } from '../../@types/APIStatus';
import AreaChartFilter from '../filters/AreaChartFilter';
import useProductHealth from '../../hooks/useProductHealth';
import { AreaChartFiltersState } from '../../@types/filterBars';

type Props = {
  title?: string;
  articleId?: number;
};

export function Circles({ title = 'produktstatus', articleId = 0 }: Props) {
  const { t: translate } = useTranslation(['component']);
  const {
    getProductHealth,
    filters,
    APIStatus: state,
    response: { columnOne, columnThree, columnTwo }
  } = useProductHealth();
  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);

  useEffect(() => getProductHealth(articleId, filterBar), [filterBar]);

  return (
    <AreaChartFilter
      ready={state === APIStatus.FULFILLED}
      showCountry={false}
      title={translate(title)}
      filters={filterBar}
      onFiltersChange={setFilterBar}
      columnsByFilter={4}
    >
      <Grid container spacing={2} columns={3} sx={{ height: '100%' }}>
        <Grid item flex={1}>
          <ColumnCircle
            title={translate('returns')}
            progress={columnOne.percent}
            count={columnOne.units}
            count2={columnOne.returnsRate}
            invert={true}
          />
        </Grid>
        <Grid item flex={1}>
          <ColumnCircle
            title={translate('db3')}
            progress={columnTwo.percent}
            count={columnTwo.units}
            count2={columnTwo.db3Rate}
            type="currency"
          />
        </Grid>
        <Grid item flex={1}>
          <ColumnCircle
            title={translate('proportion_of_total_revenue')}
            progress={columnThree.percent}
            count={columnThree.units}
            count2={columnThree.revenueShare}
            type="currency"
          />
        </Grid>
      </Grid>
    </AreaChartFilter>
  );
}
