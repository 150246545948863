import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import ArticlesPurchaseTable from './ArticlesPurchaseTable';
import usePurchaseArticles from '../../hooks/usePurchaseArticles';
import { SortBy } from '../../@types/filters';
import MenuMore from '../general/MenuMore';
import { APIStatus } from '../../@types/APIStatus';

type Props = {
  title?: string;
  id: string;
};

export default function ArticlesPurchase({ id, title = 'articles' }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = TablePaginationClasses();
  const {
    count,
    getPurchaseArticles,
    csvExportData,
    cancelFetch,
    APIStatus: status
  } = usePurchaseArticles();
  const [rowsPerPage, setRowsPerPage] = useState(count);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState<SortBy>({
    by: 'orderDate',
    order: 'desc'
  });

  useEffect(() => {
    if (status !== APIStatus.PENDING) {
      getPurchaseArticles(id);
    }
    return () => {
      cancelFetch();
    };
  }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Card>
      <CardHeader
        title={translate(title)}
        action={
          <MenuMore
            options={[
              {
                id: 0,
                label: translate('component:download_csv'),
                icon: 'ph:file-csv-duotone'
              }
            ]}
            csvData={csvExportData}
            fileNameSection={`${translate('component:purchase')}_${translate(
              'component:articles'
            )}`}
          />
        }
      />
      <CardContent>
        <ArticlesPurchaseTable
          sortBy={sortBy}
          onSortChange={setSortBy}
          rowsPerPage={count}
          page={page}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <TablePagination
            component="div"
            className={classes.root}
            rowsPerPageOptions={[-1]}
            labelRowsPerPage={
              !isMobile ? translate('global:rows_per_page') : ''
            }
            count={count}
            rowsPerPage={10}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
