import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '../../@types/notifications';
import { NotificationsState } from '../../@types/reduxStates';
import { APIError, APIStatus } from '../../@types/APIStatus';

const initialState: NotificationsState = {
  data: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'notificationsState',
  initialState,
  reducers: {
    setNotifications: (
      state: NotificationsState,
      action: PayloadAction<Array<NotificationType>>
    ) => {
      state.data = action.payload;
    },
    getNotifications: (state: NotificationsState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getNotificationsSuccess: (
      state: NotificationsState,
      action: PayloadAction<Array<NotificationType>>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getNotificationsError: (
      state: NotificationsState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setNotifications,
  getNotificationsSuccess,
  getNotificationsError,
  getNotifications
} = slice.actions;

/* export function fetchNotifications() {
  return async (dispatch: any) => {
    try {
      dispatch(getNotifications());
      console.log('Sending notifications query...');
      console.log(`.../rest/v1/notifications/...`);
      const response = resp;
      dispatch(getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(getNotificationsError());
    }
  };
} */

/* const resp: { data: Array<any> } = {
  data: [
    {
      id: '0',
      date: '12 Feb',
      title: 'Orders',
      subtitle: 'You partially submitted the required documents',
      priority: 'critical',
      type: 'products',
      images: [
        { src: '/static/home/hero.png' },
        { src: '/static/home/multipage.png' },
        { src: '/static/home/darkmode.png' },
        { src: '/static/home/darkmode.png' }
      ]
    },
    {
      id: '1',
      date: '11 Feb',
      title: 'Joined',
      subtitle: 'Welcome to the platform',
      priority: 'info',
      type: 'markets',
      images: [{ src: '/static/icons/navbar/ic_editor.svg' }]
    },
    {
      id: '2',
      date: '9 Feb',
      title: 'Low stock',
      subtitle: 'Stock 9%: ean 1231231231',
      priority: 'info',
      type: 'news',
      images: [
        { src: '/static/icons/navbar/ic_analytics.svg' },
        { src: '/static/icons/navbar/ic_blog.svg' }
      ]
    },
    {
      id: '3',
      date: '22 Jan',
      title: 'Profile verification',
      subtitle: 'You partially submitted the required documents',
      priority: 'critical',
      type: 'news',
      images: []
    },
    {
      id: '4',
      date: '21 Jan',
      title: 'Joined',
      subtitle: 'Welcome to the platform',
      priority: 'critical',
      type: 'account',
      images: [
        { src: '/static/icons/navbar/ic_editor.svg' },
        { src: '/static/icons/navbar/ic_blog.svg' }
      ]
    },
    {
      id: '5',
      date: '19 Jan',
      title: 'Low stock',
      subtitle: 'Stock 9%: ean 1231231231',
      priority: 'info',
      type: 'products',
      images: [
        { src: '/static/icons/navbar/ic_blog.svg' },
        { src: '/static/icons/navbar/ic_calendar.svg' },
        { src: '/static/icons/navbar/ic_copy.svg' }
      ]
    },
    {
      id: '6',
      date: '12 Jan',
      title: 'Profile verification',
      subtitle: 'You partially submitted the required documents',
      priority: 'critical',
      type: 'news',
      images: []
    },
    {
      id: '7',
      date: '11 Jan',
      title: 'Joined',
      subtitle: 'Welcome to the platform',
      priority: 'info',
      type: 'markets',
      images: [
        { src: '/static/icons/navbar/ic_editor.svg' },
        { src: '/static/icons/navbar/ic_blog.svg' },
        { src: '/static/icons/navbar/ic_error.svg' },
        { src: '/static/icons/navbar/ic_blog.svg' },
        { src: '/static/icons/navbar/ic_mail.svg' },
        { src: '/static/icons/navbar/ic_blog.svg' }
      ]
    },
    {
      id: '8',
      date: '9 Jan',
      title: 'Low stock',
      subtitle: 'Stock 9%: ean 1231231231',
      priority: 'critical',
      type: 'products',
      images: []
    }
  ]
}; */
