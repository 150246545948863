import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tending from '../../general/Tending';
import { ChartDataSerie, ChartSerieDateString } from '../../../@types/products';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles<Theme, { direction: 'up' | 'down' | 'right' }>(
  (theme) => ({
    container: {
      position: 'relative',
      // border:'1px solid red',
      width: '145px',
      height: '145px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },

    graphicTexts: {
      // border:'1px solid green',
      paddingLeft: theme.spacing(3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    graphic: {
      position: 'absolute',
      bottom: 15,
      width: 145,
      height: 80,
      display: 'flex',
      // flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
      // flexDirection: { xs: 'column', md: 'row' },
      // border: '1px solid yellow'
    },
    quantity: {
      position: 'absolute',
      bottom: 0,
      marginLeft: theme.spacing(3)
      // border: '1px solid green',
    }
    /* iconTrending: {
      width: 27,
      height: 27,
      borderRadius: '50%',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
      transformOrigin: '50% 50%',
      transform: ({ direction }) =>
        `rotate(${
          direction === 'up' ? 180 : direction === 'right' ? 270 : 0
        }deg)`
    } */
  })
);

type LineGraphProps = {
  qty?: number;
  name: string;
  data: Array<{ x: number; y: number }>;
  tending: number;
  type: 'number' | 'euro';
};

export function LineGraph({
  qty = 0,
  name,
  data,
  tending,
  type
}: LineGraphProps) {
  const [direction, setDirection] = useState<'up' | 'down' | 'right'>('up');
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const classes = useStyles({ direction });
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const formatterValue = (value: number): string => {
    if (type === 'euro') {
      return new Intl.NumberFormat(currentLang.value, {
        style: 'currency',
        currency: iso
      }).format(value);
    }
    return `${value}`;
  };

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      colors: ['#6c7b88'],
      width: 1,
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      colors: ['#6c7b88'],
      gradient: {
        type: 'vertical',
        opacityFrom: 0.4,
        opacityTo: 0
      }
    },
    grid: {
      show: false
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: (value: any) =>
          new Intl.DateTimeFormat(currentLang.value, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }).format(new Date(value ?? 0))
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: true,
      x: {
        show: false
      },
      y: {
        formatter(y: number) {
          return formatterValue(y);
        }
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.graphicTexts}>
        <Typography
          variant="body1"
          sx={{ color: 'text.primary', marginBottom: '8px' }}
        >
          {name}
        </Typography>
        <Tending value={tending} showTrend={true} />
      </Box>
      <Box className={classes.graphic}>
        <ReactApexChart
          options={options}
          series={[{ name, data: data || [] }]}
          type="area"
          height="100%"
          width="100%"
        />
      </Box>
      <Typography variant="h5" className={classes.quantity}>
        {formatterValue(qty)}
      </Typography>
    </Box>
  );
}
