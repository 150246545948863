import { HeaderTableFilter } from '../@types/filters';

type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function sanitizeToSort(str: string) {
  return str
    .normalize('NFD') // REMOVE ACCENTED CHARS
    .replace(/[\u0300-\u036f]/g, '') // REMOVE DIACRITICS
    .toLowerCase();
}

export function sortByProperty(
  arr: Array<any>,
  property: string,
  order: Order = 'asc',
  orderType?: HeaderTableFilter
): Array<any> {
  const tempProps = JSON.parse(JSON.stringify(arr));
  tempProps.forEach((item: any) => {
    if (orderType) {
      switch (orderType) {
        case 'letter': {
          item.tempProp = sanitizeToSort(item[property]);
          break;
        }
        case 'arrSize': {
          item.tempProp = item[property].length;
          break;
        }
        case 'cronActive': {
          item.tempProp = item[property]
            .map((c: any) => c.active)
            .reduce((a: number, b: number) => a + b);
          break;
        }
        case 'date': {
          const arrDate = item[property].split('-');
          item.tempProp = new Date(
            arrDate[2],
            arrDate[1] - 1,
            arrDate[0]
          ).getTime();
          break;
        }
        default: {
          item.tempProp = Number(item[property]);
          break;
        }
      }
    } else if (typeof item[property] == 'number') {
      item.tempProp = item[property];
    } else if (typeof item[property] == 'string') {
      item.tempProp = sanitizeToSort(item[property]);
    }
  });
  tempProps.sort((a: any, b: any) => {
    if (order === 'asc') {
      if (a.tempProp > b.tempProp) {
        return 1;
      }
      if (a.tempProp < b.tempProp) {
        return -1;
      }
    } else {
      if (a.tempProp > b.tempProp) {
        return -1;
      }
      if (a.tempProp < b.tempProp) {
        return 1;
      }
    }
    return 0;
  });
  tempProps.forEach((item: any) => delete item.tempProp);
  return tempProps;
}

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]; // o[propertyName] is of type T[K]
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
