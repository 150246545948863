import { useTranslation } from 'react-i18next';
import { Grid, Paper, Theme, makeStyles } from '@material-ui/core';

import { useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import { DataResumeTotal } from '../general/DataResumeTotal';
import { APIStatus } from '../../@types/APIStatus';
import AreaChart from './AreaChart';
import AreaChartFilter from '../filters/AreaChartFilter';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import useRevenueAndOrders from '../../hooks/useRevenueAndOrders';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    position: 'relative',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    textAlign: 'center'
  }
}));

type RevenueAndOrdersData = {
  xaxis: Array<number>;
  revenueTotal: number;
  revenueSerie: Array<{ x: number; y: number }>;
  returnsTotal: number;
  returnsSerie: Array<{ x: number; y: number }>;
  ordersTotal: number;
  ordersSerie: Array<{ x: number; y: number }>;
  marginTotal: number;
  marginSerie: Array<{ x: number; y: number }>;
};

type Props = {
  title?: string;
  articleId?: number;
};

export default function RevenueAndOrders({
  title = 'revenue_and_orders',
  articleId = 0
}: Props) {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  const {
    response: { days },
    filters,
    APIStatus: state,
    error,
    getRevenueAndOrders,
    cancelFetch
  } = useRevenueAndOrders();

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);

  const dataChart = useMemo(
    () =>
      days.reduce<RevenueAndOrdersData>(
        (accumulator, current) => {
          const mDate = new Date(current.date).getTime() / 1000;
          accumulator.xaxis.push(mDate);
          accumulator.revenueTotal += current.revenue;
          accumulator.returnsTotal += current.returns;
          accumulator.ordersTotal += current.orders;
          accumulator.marginTotal += current.margin;
          accumulator.revenueSerie.push({
            x: mDate,
            y: current.revenue
          });
          accumulator.returnsSerie.push({
            x: mDate,
            y: current.returns
          });
          accumulator.ordersSerie.push({
            x: mDate,
            y: current.orders
          });
          accumulator.marginSerie.push({
            x: mDate,
            y: current.margin
          });

          return accumulator;
        },
        {
          xaxis: [],
          revenueTotal: 0,
          revenueSerie: [],
          returnsTotal: 0,
          returnsSerie: [],
          ordersTotal: 0,
          ordersSerie: [],
          marginTotal: 0,
          marginSerie: []
        }
      ),
    [days]
  );

  useEffect(() => {
    getRevenueAndOrders(filterBar, articleId);
    return () => {
      cancelFetch();
    };
  }, [filterBar]);

  return (
    <AreaChartFilter
      ready={state === APIStatus.FULFILLED || state === APIStatus.REJECTED}
      title={title}
      filters={filters}
      onFiltersChange={setFilterBar}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Paper variant="outlined" className={classes.paper}>
            <AreaChart
              euroSeries={[0, 3]}
              series={[
                {
                  name: translate('component:revenue'),
                  data: dataChart.revenueSerie ?? [{ x: 0, y: 0 }]
                },
                {
                  name: translate('component:ordered_units'),
                  data: dataChart.ordersSerie ?? [{ x: 0, y: 0 }]
                },
                {
                  name: translate('component:returns'),
                  data: dataChart.returnsSerie ?? [{ x: 0, y: 0 }]
                },
                {
                  name: translate('component:margin'),
                  data: dataChart.marginSerie ?? [{ x: 0, y: 0 }]
                }
              ]}
              xaxis={dataChart.xaxis}
            />
            {state === APIStatus.PENDING && <CardLoading />}
            {state === APIStatus.REJECTED && <CardError />}
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <DataResumeTotal
            title={translate('component:revenue')}
            chartData={dataChart.revenueSerie}
            state={state}
            error={error}
            values={[
              {
                value: dataChart.revenueTotal,
                type: 'currency'
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataResumeTotal
            title={translate('component:ordered_units')}
            chartData={dataChart.ordersSerie}
            state={state}
            error={error}
            values={[
              {
                value: dataChart.ordersTotal,
                type: 'numeric'
                // wrap: 'bar',
                // post: translate('unit', { count: ordersTotal })
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataResumeTotal
            title={translate('component:returns')}
            chartData={dataChart.returnsSerie}
            state={state}
            error={error}
            values={[
              { value: dataChart.returnsTotal, type: 'numeric' }
              /* {
                value: getDifferenceInPercentage(ordersTotal, returnsTotal),
                type: 'percentage',
                wrap: 'parentesis'
              } */
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataResumeTotal
            title={translate('component:margin')}
            chartData={dataChart.marginSerie}
            state={state}
            error={error}
            values={[
              {
                value: dataChart.marginTotal,
                type: 'currency'
              }
              /* {
                value: getDifferenceInPercentage(costsTotal, revenueTotal),
                type: 'percentage',
                wrap: 'parentesis'
              } */
            ]}
          />
        </Grid>
      </Grid>
    </AreaChartFilter>
  );
}
