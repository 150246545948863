import {
  Box,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReturnsWidgetProps } from '../../../@types/products';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import useLocales from '../../../hooks/useLocales';
import { helperStyles } from '../../../theme/HelperClasses';
import { HeaderWidget } from './HeaderWidget';

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(1)
    // border: '1px solid red'
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0)
  }
}));

export function Returns() {
  const {
    article: {
      returns: { percent, qty }
    }
  } = useArticlesDetail();
  const { currentLang } = useLocales();
  const classes = useStyles();
  const helperClasses = helperStyles();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();

  const color = () => {
    // if (percent === 0) return theme.palette.text.primary;
    if (percent === 0) return theme.palette.success.main;
    if (percent > 0) return theme.palette.error.main;
    return theme.palette.success.main;
  };
  return (
    <HeaderWidget
      title={`${translate('returns')}`}
      content={
        <Box className={classes.content}>
          <Box className={helperClasses.row}>
            <Box className={helperClasses.cell}>
              <Typography variant="body1">{translate('returns')}</Typography>
            </Box>
            <Box className={helperClasses.cell}>
              <Typography variant="body1">
                {translate('returns_rate')}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />

          <Box className={helperClasses.rowFlex}>
            <Box className={`${helperClasses.cell} ${helperClasses.maxHeight}`}>
              <Typography
                variant="h4"
                fontWeight={700}
                sx={{ color: 'text.primary' }}
              >
                {qty}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.maxHeight}`}>
              <Typography
                variant="h4"
                align="right"
                // className={classes.textPercent}
                fontWeight={700}
                sx={{
                  minWidth: '65px',
                  color
                }}
              >
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                  useGrouping: false
                  // @ts-ignore
                  // signDisplay: 'exceptZero'
                }).format(percent)}
              </Typography>
            </Box>
            {/* percent !== 0 &&
              <Box className={`${helperClasses.cell} ${helperClasses.start}`}>
                <TendingIcon inverse tending={percent} />
              </Box> */}
          </Box>
        </Box>
      }
    />
  );
}
