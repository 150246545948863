//
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// components
import Grid from '@material-ui/core/Grid';
import useConfig from '../hooks/useConfig';
import Page from '../components/Page';
import ProductDetail from '../components/productdetail/ProductDetail';
import useArticlesDetail from '../hooks/useArticlesDetail';
import { APIStatus } from '../@types/APIStatus';

import { DateRangeFilter } from '../@types/filters';
import { DetailTabs } from '../components/productdetail/DetailTabs';
import useDashboardTab from '../hooks/useDashboardTab';
import useOrdersTab from '../hooks/useOrdersTab';

export default function Product() {
  const { id, tab } = useParams<{
    id: string;
    tab: string;
  }>();
  const { pathname } = useLocation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const {
    APIStatus: articleStatus,
    getOneArticle,
    cancelFetch,
    filters: { timePeriod }
  } = useArticlesDetail();

  const { config } = useConfig();
  const { t: translate } = useTranslation(['menu', 'component']);

  const [timePeriodSelect, setTimePeriodSelect] =
    useState<DateRangeFilter>(timePeriod);

  useEffect(() => {
    getOneArticle(id, timePeriodSelect);
    return () => {
      cancelFetch();
    };
  }, [timePeriodSelect]);

  const executeScroll = () =>
    tabsRef.current?.scrollIntoView({ behavior: 'smooth' });

  const [tabSelected, setTabSelected] = useState<string>(tab);
  const { resetTab: resetDasboardTab } = useDashboardTab();
  const { resetTab: resetOrdersTab } = useOrdersTab();

  const handleTabSelected = (tSelected: string, scroll: boolean = false) => {
    resetDasboardTab();
    resetOrdersTab();
    setTabSelected(tSelected);
    if (scroll) {
      executeScroll();
    }
  };

  useEffect(() => {
    setProductTabURL(pathname, tabSelected);
  }, [tabSelected]);

  function setProductTabURL(
    pathname: string,
    tabSel: string = '0',
    search: string = ''
  ) {
    if (tabSel === '0' || tabSel === '2') {
      const newUrl = pathname.split('/filters')[0].split('/');
      newUrl[newUrl.length - 1] = tab;
      const pathName = newUrl.join('/');
      window.history.replaceState(null, '', `${pathName}${search}`);
    }
  }

  if (articleStatus === APIStatus.REJECTED) {
    return <div>No component found</div>;
  }

  return (
    <Page title={`${config.company} - ${translate('product')}`}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <ProductDetail
              timePeriod={timePeriodSelect}
              onDateChange={setTimePeriodSelect}
              onTabChange={handleTabSelected}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailTabs
              tabsRef={tabsRef}
              tabSelected={tabSelected}
              onSelectTab={handleTabSelected}
              onSelectProduct={(articleId) =>
                getOneArticle(`${articleId}`, timePeriodSelect)
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
