import {
  alpha,
  Box,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100% - 7px)',
    position: 'absolute',
    // border: '1px solid red',
    borderRadius: theme.spacing(2),
    backgroundColor: alpha('#fff', 0.75),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    transition: 'background-color 0.3s'
  }
}));

export function CardNoResult() {
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  return (
    <Box className={classes.root}>
      <Typography
        textAlign="center"
        variant="body1"
        sx={{ color: theme.palette.text.secondary }}
      >
        {translate('no_search_result')}
      </Typography>
    </Box>
  );
}
