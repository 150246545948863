import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ProgressCircle } from '../general/ProgressCircle';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  circularProgressContainer: {
    position: 'relative'
  },
  colorCircularProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: ({ color }) => color
  },
  greyCircularProgress: {
    color: theme.palette.divider
  },
  column: {
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textBlockLegend: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1)
  }
}));

export interface IColumnCircleProps {
  title: string;
  progress: number;
  invert?: boolean;
  count: number;
  count2: number;
  type?: 'percent' | 'integer' | 'currency';
}

export function ColumnCircle(props: IColumnCircleProps) {
  const {
    title,
    progress,
    invert = false,
    type = 'integer',
    count,
    count2 = ''
  } = props;
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });
  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    minimumFractionDigits: 2
  });

  const [color, setColor] = useState<string>('#ff1943');
  const classes = useStyles({ color });
  useEffect(() => {
    if (progress <= 15) {
      setColor('#ff1c00');
    } else if (progress <= 35) {
      setColor('#ff7e00');
    } else if (progress <= 45) {
      setColor('#ffac00');
    } else if (progress <= 65) {
      setColor('#ffd600');
    } else if (progress <= 85) {
      setColor('#a5fb00');
    } else {
      setColor('#00a83a');
    }
  }, [progress]);
  return (
    <Paper variant="outlined">
      <Box className={classes.column}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, color: 'text.primary' }}
        >
          {title}
        </Typography>
        <ProgressCircle
          invert={invert}
          value={progress}
          size={100}
          centerLegend={
            <Box className={classes.textBlockLegend}>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {progress}
              </Typography>
            </Box>
          }
        />
        <Box className={classes.textBlock}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 700 }}
            textAlign="center"
          >
            {type === 'percent'
              ? percent.format(count)
              : type === 'currency'
              ? euro.format(count)
              : count}{' '}
            {count2 !== '' && `(${count2}%)`}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.secondary' }}
            textAlign="center"
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
