import { alpha, makeStyles, Theme } from '@material-ui/core';

export const HEIGHT_ROW_SUMMARY = 60;

export const TableStyleClasses = makeStyles<
  Theme,
  { height: number; numRows: number; maxSize?: number }
>((theme) => ({
  paper: {
    boxSizing: 'border-box'
  },
  tablePaper: {
    boxSizing: 'border-box',
    minHeight: ({ height, numRows }) =>
      height * numRows + HEIGHT_ROW_SUMMARY * 2,
    position: 'relative'
  },
  firstTableCellLink: {
    '&.MuiTableCell-root:first-of-type': {
      padding: '0px',
      minHeight: ({ height }) => height,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&.dayActivityStream': {
        padding: '8px',
        justifyContent: 'center'
      }
    }
  },
  linkIconButton: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5)
  },
  cellScroll: {
    overflowY: 'scroll',
    minHeight: ({ height }) => height,
    maxHeight: '100px',
    '&::-webkit-scrollbar': {
      height: '0.5em',
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#d3d3d3',
      transition: 'all .3s ease-out'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#c1c1c1'
    }
  },
  tableContainer: {
    overflowY: 'hidden',
    minHeight: ({ height }) =>
      height > 0 ? height * 3 + HEIGHT_ROW_SUMMARY * 2 + 8 : 'auto',
    height: ({ height, numRows }) =>
      height > 0 ? height * numRows + HEIGHT_ROW_SUMMARY * 2 + 8 : 'auto',
    // maxHeight: ({ maxSize }) => (maxSize ? `${maxSize - 240}px` : 'auto'),

    // overflowY: 'scroll',
    // height: ({ height, numRows }) =>
    // height > 0 ? height * numRows + HEIGHT_ROW_SUMMARY * 2 + 8 : 'auto',
    // maxHeight: ({ maxSize }) => (maxSize ? `${maxSize - 240}px` : 'auto'),

    // height: '100%',
    // overflowX: "initial",
    '&::-webkit-scrollbar': {
      height: '0.5em',
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#d3d3d3',
      transition: 'all .3s ease-out'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#c1c1c1'
    }
  },
  tableBody: {
    // height: ({ height, numRows }) => height * numRows + HEIGHT_ROW_SUMMARY
  },
  tableRow: {
    boxSizing: 'border-box',
    height: ({ height }) => (height > 0 ? height : 'auto'),
    '&:last-child': {
      paddingBottom: 2
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#f6f7f8'
    },
    '&.Mui-selected': {
      backgroundColor: '#f6f7f8'
    }
    // '&.MuiTableRow-root': '1px solid red'
  },
  tableCellCenterContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCellNoPaddingVertical: {
    padding: theme.spacing(0, 1)
  },
  productNameCell: {
    '&:first-of-type': {
      padding: theme.spacing(0),
      minWidth: '220px'
    }
  },
  returnsProductCell: {
    '&:first-of-type': {
      padding: theme.spacing(0.2),
      paddingLeft: theme.spacing(1),
      width: '220px'
    }
  },
  productActiveMarketsCell: {
    height: 51,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  cancelHeight: {
    marginTop: '4px',
    height: 'auto'
  },
  headerCell: {
    '&.noMinWidth': {
      minWidth: 'auto'
    },
    '&.minWidth100': {
      minWidth: '100px'
    },
    minWidth: '150px',
    height: HEIGHT_ROW_SUMMARY,
    padding: theme.spacing(0),
    '& .MuiButtonBase-root': {
      '&.bold': {
        // color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '14px'
      },
      /* display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis', */
      // border: '1px solid green',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: theme.spacing(1),
      transition: 'padding .3s ease',
      '& span': {
        // border: '1px solid red',
      },
      /* '& .MuiTableSortLabel-icon': {
        //opacity: 0
        // border: '1px solid green',
        // paddingTop: theme.spacing(1)
      }, */
      '&.Mui-active': {
        // border: '1px solid red',
        // paddingLeft: theme.spacing(0),
      },
      '&:hover': {
        // border: '1px solid red',
        paddingLeft: theme.spacing(0),
        '& .MuiTableSortLabel-icon': {
          opacity: 1
        }
      }
    }
  },
  headerCellSticky: {
    minWidth: '220px',
    padding: theme.spacing(0, 0, 0, 3),
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 4
    },
    boxShadow: `2px 0px 4px -2px ${theme.palette.grey[500]}`
    // left: 0,
    // zIndex: 1
  },
  cellGrey: {
    backgroundColor: '#f6f7f8'
    /* '& .MuiPaper-root': {

    } */
    /* '&:hover': {
      backgroundColor: theme.palette.grey[200]
    } */
  },
  cellSticky: {
    '&.no-padding': {
      padding: '0px !important'
    },
    '&.overChart': {
      zIndex: 2
    },
    '&.MuiTableCell-root:first-of-type': {
      '&.withPaddingStart': {
        paddingLeft: '24px'
      },
      padding: theme.spacing(1, 1)
    },
    padding: theme.spacing(1, 1),
    // margin: theme.spacing(1, 1),
    // boxShadow: `-3px 0px 0px -2px ${theme.palette.grey[500]} inset`,
    // boxShadow: `2px 0px 4px -2px ${theme.palette.grey[500]}`,
    // boxShadow: `inset 2px 0px 4px -2px ${theme.palette.grey[500]}`,
    boxShadow: `inset -2px 0px 3px -2px ${theme.palette.grey[500]}`,
    // borderRight: '1px solid #f1f3f4',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      boxShadow: ' -3px 0px 0px -2px #f1f3f4 inset'
    },
    position: 'sticky',
    left: 0,
    // zIndex: 2,
    backgroundColor: '#fff',
    '.MuiTableRow-hover:hover &': {
      backgroundColor: '#f6f7f8'
    },
    '.Mui-selected &': {
      backgroundColor: '#f6f7f8'
    },
    /* '&:first-of-type': {
      padding: theme.spacing(0),
    }, */
    '&:withPadding': {
      padding: theme.spacing(2)
    }
  },

  widthTresCienCincuen: {
    minWidth: '350px'
  },
  tableCellPaddingRightSmall: {
    paddingRight: theme.spacing(1)
  },
  loading: {
    width: '100%',
    height: '100%',
    // border: '1px solid red',
    position: 'absolute',
    borderRadius: 2,
    backgroundColor: alpha('#fff', 0.4),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    transition: 'background-color 0.3s'
  },
  centerCellContent: {
    paddingRight: '38px',
    '&.MuiTableCell-root:last-of-type': {
      paddingRight: '38px'
    }
  }
}));

/* export const tableRowStyleClasses = makeStyles<
  Theme,
  { height: number }
>((theme) => ({
  tableRow: {
    boxSizing: 'border-box',
    height: ({ height }) => height,
    '&:last-child': {
      paddingBottom: 2
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#f6f7f8'
    },
    '&.Mui-selected': {
      backgroundColor: '#f6f7f8'
    }
  },


})) */

export const topSeller = makeStyles((theme) => ({
  image: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    margin: theme.spacing(0, 2, 0, 0),
    borderRadius: theme.shape.borderRadiusSm
  },
  table: {
    minWidth: 440
  },
  firstCell: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  }
}));

export const TablePaginationClasses = makeStyles<Theme>((theme) => ({
  root: {
    border: 0
  },
  loading: {
    width: '100%',
    height: '100%',
    // border: '1px solid red',
    position: 'absolute',
    borderRadius: theme.spacing(2),
    backgroundColor: alpha('#fff', 0.6),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    transition: 'background-color 0.3s'
  }
}));
