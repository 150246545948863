//
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { MenuItem, TNavItem } from '../../@types/menu';
import { SideMenuState } from '../../@types/reduxStates';
import axios from '../../utils/axios';
// @types
// import { Menu } from '../../@types/menu';

const initialState: SideMenuState = {
  menu: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    getMenu: (state: SideMenuState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getMenuSuccess(
      state: SideMenuState,
      action: PayloadAction<Array<MenuItem>>
    ) {
      state.menu = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getMenuError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function menuTree(iso: string) {
  return async (dispatch: any) => {
    dispatch(getMenu());
    try {
      const response = await axios.get(`/api/v1/dashboard/menu/${iso}.json`);
      dispatch(getMenuSuccess(response.data));
    } catch (error) {
      dispatch(getMenuError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

export const { getMenu, getMenuSuccess, getMenuError } = slice.actions;
