import { Box, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 210,
    width: 210,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%'
  }
}));

type ProductImageProps = {
  src: string;
  index: number;
};

export function ProductImageBig({ src, index }: ProductImageProps) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        component="img"
        className={classes.image}
        alt={`Product image ${index}`}
        src={src}
      />
    </Box>
  );
}
