import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { APIStatus } from '../../../@types/APIStatus';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { helperStyles } from '../../../theme/HelperClasses';
import { textStyles } from '../../../theme/TextClasses';
import { CardError } from '../../general/CardError';
import { CardLoading } from '../../general/CardLoading';

const useStyles = makeStyles<Theme, { isDouble?: boolean }>((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  title: {
    width: ({ isDouble }) => (isDouble ? '100%' : 'auto'),
    // width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.4),
    padding: theme.spacing(0, 1),
    // border: '1px solid red',
    display: 'flex',
    justifyContent: ({ isDouble }) => (isDouble ? 'space-between' : 'center'),
    alignItems: 'center'
  },
  label: {
    // border: '1px solid green',
    borderBottom: `2px dotted ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  content: {
    height: '100%',
    width: '100%'
    // border: '1px solid blue',
    // padding: theme.spacing(0, 1),
  }
}));

type HeaderWidgetProps = {
  title?: string;
  title2?: string;
  content: React.ReactNode;
};

export function HeaderWidget({ title, title2, content }: HeaderWidgetProps) {
  const classes = useStyles({ isDouble: title2 !== undefined });
  const textClasses = textStyles({});
  const helperClasses = helperStyles();
  const { APIStatus: state, error } = useArticlesDetail();
  return (
    <Box className={classes.container}>
      {title && (
        <Box className={classes.title}>
          <Box className={classes.label}>
            <Typography variant="body1" className={textClasses.ellipsis}>
              {title}
            </Typography>
          </Box>
          {title2 && (
            <Box className={classes.label}>
              <Typography variant="body1" className={textClasses.ellipsis}>
                {title2}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {error && <CardError />}
      {state === APIStatus.PENDING && <CardLoading />}
      {state === APIStatus.FULFILLED && (
        <Box className={classes.content}>{content}</Box>
      )}
    </Box>
  );
}
