import {
  makeStyles,
  Theme,
  Paper,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { useLocation, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SelectTimePeriod from '../filters/SelectTimePeriod';
import { ActivityStreamFiltersState } from '../../@types/filterBars';
import useFilters from '../../hooks/useFilters';
import { FilterSelect } from '../items/filters/FilterSelect';
import useActivityStream from '../../hooks/useActivityStream';
import { stringToNumber } from '../../utils/helper';
import { useCopyFilters } from '../../hooks/useCopyFilters';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  }
}));

type Props = {
  rowsPerPage: number;
  setInitialPage: () => void;
};

export enum ActivityMessageImportance {
  ALL,
  STANDARD,
  IMPORTANT,
  CRITICAL
}

export function ActivityStreamFilterBar({
  rowsPerPage,
  setInitialPage
}: Props) {
  const { id } = useParams<{ id?: string }>();
  const articleId = stringToNumber(id);
  const { pathname, search } = useLocation();
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);

  const { getActivityStream, filters } = useActivityStream();

  const {
    response: { sources }
  } = useFilters();

  const urlValuesFilter = assignInitialValues(search, filters);
  const [filterBar, setFilterBar] =
    useState<ActivityStreamFiltersState>(urlValuesFilter);

  const { copyUrlToClipboard, iconData } = useCopyFilters();

  useEffect(() => {
    getActivityStream(
      filterBar,
      { skip: 0, limit: rowsPerPage },
      { by: 'date', order: 'desc' },
      articleId
    );
    setInitialPage();
  }, [filterBar]);

  function assignInitialValues(
    search: String,
    filtersState: ActivityStreamFiltersState
  ): ActivityStreamFiltersState {
    const f: ActivityStreamFiltersState = { ...filtersState };
    if (search.length > 0) {
      search
        .split('?')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'from':
              {
                f.timePeriod = { ...f.timePeriod, startFilter: value };
              }
              break;
            case 'till':
              {
                f.timePeriod = { ...f.timePeriod, endFilter: value };
              }
              break;
            case 'shop':
            case 'source':
            case 'eventType':
            case 'section':
            case 'status':
            case 'groupBy':
            case 'level':
              {
                (f[name as keyof ActivityStreamFiltersState] as number) =
                  parseInt(value, 10);
              }
              break;
            case 'searchName':
              {
                f.searchName = value;
              }
              break;
            case 'readed':
              {
                f.readed = parseInt(value, 10) === 1;
              }
              break;
          }
        });
    }
    window.history.replaceState(null, '', pathname);
    return f;
  }

  return (
    <Paper elevation={5} className={classes.root}>
      <Grid
        container
        item
        spacing={2}
        justifyContent="flex-end"
        xs={12}
        direction="row"
        sx={{ padding: '16px', marginTop: '8px' }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FilterSelect
            selected={filterBar.eventType}
            title={translate('event_type')}
            labels={[
              translate('filter:all'),
              translate('component:returns'),
              translate('component:profit_report'),
              translate('component:stock'),
              translate('component:sales_performance')
            ]}
            onChangeSelected={(option) =>
              setFilterBar((old) => ({
                ...old,
                eventType: option
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FilterSelect
            selected={filterBar.section}
            title={translate('filter:sections')}
            labels={[
              translate('filter:all'),
              translate('component:source'),
              translate('component:customer_class'),
              translate('filter:category'),
              translate('component:product')
            ]}
            onChangeSelected={(option) =>
              setFilterBar((old) => ({
                ...old,
                section: option
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FilterSelect
            selected={filterBar.status}
            title={translate('component:priority')}
            labels={[
              translate('filter:all'),
              translate('component:msg_standard'),
              translate('component:msg_important'),
              translate('component:msg_critical')
            ]}
            onChangeSelected={(option) =>
              setFilterBar((old) => ({
                ...old,
                status: option
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FilterSelect
            disabled={false}
            selected={
              sources.findIndex((s) => s.sourceId === filterBar.source) + 1 ?? 0
            }
            title={translate('sources')}
            labels={[
              translate('filter:all_sources'),
              ...sources.map((source) => source.sourceName)
            ]}
            onChangeSelected={(option) => {
              setFilterBar((old) => ({
                ...old,
                source: option === 0 ? 0 : sources[option - 1].sourceId
              }));
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SelectTimePeriod
            startFilter={filterBar.timePeriod.startFilter}
            endFilter={filterBar.timePeriod.endFilter}
            onChangePeriod={(timePeriod) =>
              setFilterBar((old) => ({ ...old, timePeriod }))
            }
          />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end">
          <Tooltip title={iconData.tooltip}>
            <IconButton
              color="primary"
              aria-label="copy url"
              onClick={() => {
                copyUrlToClipboard(filterBar);
              }}
            >
              <Icon icon={iconData.icon} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
}
