import { Box } from '@material-ui/core';
import Lottie from 'react-lottie';

type Props = {
  json: any;
  width?: number;
  height?: number;
  stop?: boolean;
};

function SidebarIcon({ json, width = 32, height = 32, stop = true }: Props) {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box>
      <Lottie
        options={defaultOptions}
        height={width}
        width={height}
        isStopped={stop}
      />
    </Box>
  );
}

export default SidebarIcon;
