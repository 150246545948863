import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { WidgetFiltersState } from '../../@types/filterBars';
import { AverageOrderValueState, Pagination } from '../../@types/reduxStates';
import { AverageOrderValueResponse } from '../../@types/responsesAPI';
import { SortBy } from '../../@types/filters';

const initialState: AverageOrderValueState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  response: {
    rows: [],
    total: {
      source: '',
      orders: 0,
      revenue: 0,
      averageItems: 0,
      averageRevenue: 0
    },
    totalCount: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'averageOrderValue',
  initialState,
  reducers: {
    setFilters: (
      state: AverageOrderValueState,
      action: PayloadAction<WidgetFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getAverageOrderValue: (state: AverageOrderValueState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getAverageOrderValueSuccess: (
      state: AverageOrderValueState,
      action: PayloadAction<AverageOrderValueResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getAverageOrderValueError: (
      state: AverageOrderValueState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchAverageOrderValue(
  filterBarState: WidgetFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBarState));
    try {
      dispatch(getAverageOrderValue());
      const params = {
        skip,
        limit,
        by,
        order,
        from: filterBarState.timePeriod
          ? filterBarState.timePeriod.startFilter
          : undefined,
        till: filterBarState.timePeriod
          ? filterBarState.timePeriod.endFilter
          : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/api/v1/averageOrderValue',
        { signal: abortController.signal, params }
      );
      dispatch(getAverageOrderValueSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getAverageOrderValueError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getAverageOrderValue,
  getAverageOrderValueSuccess,
  getAverageOrderValueError
} = slice.actions;
