import {
  alpha,
  Box,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ProductImageBig } from './ProductImageBig';
import useArticlesDetail from '../../../hooks/useArticlesDetail';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    // marginRight: theme.spacing(3),
    width: 290,
    height: 210,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
    /* [theme.breakpoints.down(420)]: {
      flexDirection: 'column',
      width: '100%',
      height: 'auto'
    }, */
  },
  imageBig: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    maxWidth: 210,
    maxHeight: 210,
    width: '100%',
    height: '100%'
  },
  imageNavigation: {
    color: '#fff',
    backgroundColor: alpha('#000', 0.6),
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1)
  },
  iconButton: {
    color: '#beb6b5',
    padding: theme.spacing(0.5)
  },
  imageSmall: {
    maxWidth: 65,
    maxHeight: 210,
    /* display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', */
    width: '100%',
    height: '100%'
    /* [theme.breakpoints.down(420)]: {
      marginTop: theme.spacing(1),
      maxWidth: 210,
      maxHeight: 64
    } */
  }
}));

export function ProductImages() {
  const {
    article: { images, cover }
  } = useArticlesDetail();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isVertical, setVertical] = useState<boolean>(window.innerWidth > 420);
  const sliderBigRef = useRef<any>();
  const sliderSmallRef = useRef<any>();

  const maxSteps = images.length;
  useEffect(() => {
    if (images.length > 1) {
      // console.log("active Index")
      // console.log(activeIndex)
      sliderBigRef.current.slickGoTo(activeIndex);
      sliderSmallRef.current.slickGoTo(activeIndex);
    }
  }, [activeIndex]);

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % maxSteps;
    setActiveIndex(newIndex);
  };

  const handleBack = () => {
    const newIndex = (maxSteps + activeIndex - 1) % maxSteps;
    setActiveIndex(newIndex);
  };

  const handleStepChange = (step: number) => {
    setActiveIndex(step);
  };

  const settingsBig = {
    arrows: false,
    // infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
    // focusOnSelect: true
  };

  const settingsSmall = {
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: isVertical,
    verticalSwiping: isVertical,
    focusOnSelect: true
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.imageBig}>
        <Slider {...settingsBig} ref={sliderBigRef}>
          {images.length > 0 &&
            images.map((img) => (
              <ProductImageBig
                key={`img_${img.position}`}
                src={img.url}
                index={img.position}
              />
            ))}
          {images.length === 0 && cover.length > 0 && (
            <ProductImageBig key="img_cover" src={cover} index={0} />
          )}
        </Slider>

        {images.length > 1 && (
          <Box className={classes.imageNavigation}>
            <IconButton
              className={classes.iconButton}
              component="span"
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <Box sx={{ paddingBottom: 0.3 }}>
              <Typography sx={{ fontWeight: 700 }}>{`${
                activeIndex + 1
              }/${maxSteps}`}</Typography>
            </Box>
            <IconButton
              className={classes.iconButton}
              component="span"
              onClick={handleNext}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        )}
      </Box>

      <Box className={classes.imageSmall}>
        {images.length > 1 && (
          <Slider {...settingsSmall} ref={sliderSmallRef}>
            {images.map((img, i) => (
              <Box
                onClick={() => handleStepChange(i)}
                key={`mini_${i}`}
                component="div"
                sx={{
                  border: () =>
                    activeIndex === i ? '3px solid #f12566' : 'none',
                  width: 65,
                  height: 65,
                  backgroundImage: `url(${img.urlPreview})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 1
                }}
              />
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
}

/* <Box
              onClick={() => handleStepChange(i)}
              key={`mini_${i}`}
              component="div"
              sx={{
                width: 210,
                height: 210,
                backgroundImage: `url(${img})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                borderRadius: 1
              }}
            /> */
