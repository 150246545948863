import {
  Box,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useLocales from '../../hooks/useLocales';
import { helperStyles } from '../../theme/HelperClasses';
import { textStyles } from '../../theme/TextClasses';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme>((theme) => ({
  priceText: {
    flex: 0.7,
    height: '100%',
    // border: '1px solid orange',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  absolute: {
    position: 'absolute',
    width: '100%',
    top: 5
    // height: '20px',
    // bottom: '-40%',
    // border: '1px solid black',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  graphContainer: {
    flex: 0.7,
    height: '90px'
    // border: '1px solid blue',
  },
  relative: {
    position: 'relative',
    width: '100%',
    height: '100%',
    // border: '1px solid gold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  title: {
    paddingTop: theme.spacing(1),
    borderBottom: `2px dotted ${theme.palette.primary.main}`
  },
  euroContainer: {
    flex: 0.3
  },
  underline: {
    width: '100%'
  }
}));

type Props = {
  name: string;
  chart: Array<{ x: number; y: number }>;
  average?: number;
};

export function AveragePriceChart({ name, chart, average = 0 }: Props) {
  const classes = useStyles();
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const helperClasses = helperStyles();
  const textClasses = textStyles({});

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: iso
  });

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: [theme.palette.primary.main],
    dataLabels: {
      enabled: false
    },
    /* noData: {
        text: translate('no_data')
      }, */
    stroke: {
      colors: ['#1f2fa0'],
      width: 3,
      curve: 'straight'
    },
    grid: {
      show: false
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: (value: any) =>
          new Intl.DateTimeFormat(currentLang.value, {
            year: 'numeric',
            month: 'short'
          }).format(value)
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    markers: {},
    annotations: {
      yaxis: [
        {
          y: average ?? 0,
          borderColor: 'red',
          strokeDashArray: 0
        }
      ],
      points: [
        {
          x: chart && chart.length > 0 ? chart[0].x : 0,
          y: chart && chart.length > 0 ? chart[0].y : 0,
          marker: {
            size: 4,
            fillColor: '#fff',
            strokeColor: '#1f2fa0',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          }
        },
        {
          x: chart && chart.length > 0 ? chart[chart.length - 1].x : 0,
          y: chart && chart.length > 0 ? chart[chart.length - 1].y : 0,
          marker: {
            size: 4,
            fillColor: '#fff',
            strokeColor: '#1f2fa0',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          }
        }
      ]
    },
    yaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: true,
      x: {
        show: false
      },
      y: {
        title: {
          formatter(seriesName: string) {
            return '';
          }
        },
        formatter(y: number) {
          return new Intl.NumberFormat(currentLang.value, {
            style: 'currency',
            currency: 'EUR'
          }).format(Number(y));
        }
      }
    }
  };

  return (
    <Box className={classes.relative}>
      <Box className={classes.title}>
        <Typography variant="body1" className={textClasses.ellipsis}>
          {name}
        </Typography>
      </Box>
      <Box className={classes.absolute}>
        <Box className={helperClasses.row}>
          <Box className={classes.graphContainer}>
            <ReactApexChart
              options={options}
              series={[{ name, data: chart || [] }]}
              type="line"
              height="100%"
              width="100%"
            />
          </Box>
          <Box className={classes.euroContainer}>
            <Typography variant="body1" textAlign="center">
              {euro.format(average)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
