import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { RootState } from '../redux/rootReducer';
import { fetchPurchaseArticles } from '../redux/slices/purchaseArticles';
import { unknownToNumber } from '../utils/helper';
import { PurchaseArticlesRow, TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';

function usePurchaseArticles() {
  const dispatch = useDispatch();
  const {
    response: {
      rows,
      total,
      totalCount: { count }
    },
    APIStatus,
    error
  } = useSelector((state: RootState) => state.purchaseArticles);

  const { currentLang } = useLocales();

  const { t: translate } = useTranslation(['component']);

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 0,
        field: 'product',
        label: translate('component:product'),
        align: 'center'
      },
      {
        id: 1,
        field: 'articleStock',
        label: translate('component:stock'),
        align: 'center',
        summary: {
          value: unknownToNumber(
            rows
              .map((r) => unknownToNumber(r.articleStock))
              .reduce((a, b) => a + b, 0)
          ),
          value2: unknownToNumber(total.articleStock),
          type: 'qty',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 2,
        field: 'purchasePrice',
        label: translate('component:purchase_price'),
        align: 'center',
        summary: {
          value: unknownToNumber(
            rows.length > 0
              ? rows
                  .map((r) => unknownToNumber(r.purchasePrice))
                  .reduce((a, b) => a + b, 0) / rows.length
              : 0
          ),
          value2: unknownToNumber(total.purchasePrice),
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'average'
        }
      },
      {
        id: 3,
        field: 'netProductValue',
        label: translate('component:net_product_value'),
        align: 'center',
        summary: {
          value:
            rows
              .map((r) => unknownToNumber(r.netProductValue))
              .reduce((a, b) => a + b, 0) ?? 0,
          value2: unknownToNumber(total.netProductValue),
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 4,
        field: 'orderedUnits',
        label: translate('component:ordered_units'),
        align: 'center',
        summary: {
          value:
            rows
              .map((r) => unknownToNumber(r.orderedUnits))
              .reduce((a, b) => a + b, 0) ?? 0,
          value2: unknownToNumber(total.orderedUnits),
          type: 'qty',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 5,
        field: 'receivedUnits',
        label: translate('component:received_units'),
        align: 'center',
        summary: {
          value:
            rows
              .map((r) => unknownToNumber(r.receivedUnits))
              .reduce((a, b) => a + b, 0) ?? 0,
          value2: unknownToNumber(total.receivedUnits),
          type: 'qty',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 6,
        field: 'estimatedTimeOfArrival',
        label: translate('component:estimated_time_of_arrival'),
        align: 'center',
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 7,
        field: 'actualArrivalTime',
        label: translate('component:actual_arrival_time'),
        align: 'center',
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 8,
        field: 'stockLocation',
        label: translate('component:stock_location'),
        align: 'center',
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 12,
        field: 'delayDelivery',
        label: translate('component:delay_delivery'),
        align: 'center',
        summary: {
          value: unknownToNumber(
            rows.length > 0
              ? rows
                  .map((r) => unknownToNumber(r.delayDelivery))
                  .reduce((a, b) => a + b, 0) / rows.length
              : 0
          ),
          value2: unknownToNumber(total.delayDelivery),
          type: 'days',
          align: 'left',
          paddingRight: '16px',
          calculation: 'average'
        }
      }
    ],
    [rows, currentLang]
  );

  const csvExportData = useMemo(
    () => ({
      headers: [
        { label: translate('component:id'), key: 'articleId' },
        { label: translate('component:name'), key: 'articleName' },
        { label: translate('component:asin'), key: 'articleAsin' },
        { label: translate('component:ean'), key: 'articleEan' },
        { label: translate('component:stock_status'), key: 'stockLabel' },
        ...summary
          .filter((s) => s.field !== 'product')
          .map((t): { label: string; key: string } => ({
            label: t.label,
            key: t.field
          }))
      ],
      data: getCsvRows(rows),
      date: {
        startFilter: format(new Date(), 'yyyy-MM-dd'),
        endFilter: ''
      }
    }),
    [summary]
  );

  function getCsvRows(
    responseData: Array<PurchaseArticlesRow>
  ): Array<{ [key: string]: string | number }> {
    return responseData.map((cell) => {
      const fRow: { [key: string]: string | number } = {};
      let k: keyof typeof cell;
      for (k in cell) {
        if (Object.prototype.hasOwnProperty.call(cell, k)) {
          switch (k) {
            case 'purchasePrice':
            case 'netProductValue': {
              fRow[k] = Number(cell[k]).toFixed(2);
              break;
            }
            case 'stockLabel': {
              const label = translate(`component:${cell[k] as string}`);
              fRow[k] = label;
              break;
            }
            case 'articleAsin':
            case 'articleEan':
            case 'articleId':
            case 'articleExtId':
            case 'articleName':
            case 'stockLocation':
            case 'estimatedTimeOfArrival':
            case 'actualArrivalTime': {
              fRow[k] = cell[k];
              break;
            }
            default: {
              fRow[k] = Number(cell[k]);
              break;
            }
          }
        }
      }
      return fRow;
    });
  }

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getPurchaseArticles = (purchaseId: string) => {
    cancelFetch();
    dispatch(fetchPurchaseArticles(purchaseId, abortController.current));
  };

  return {
    csvExportData,
    summary,
    rows,
    total,
    count,
    APIStatus,
    error,
    cancelFetch,
    getPurchaseArticles
  };
}

export default usePurchaseArticles;
