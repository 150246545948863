import { Typography } from '@material-ui/core';
import { VariantSize } from '../../@types/text';
import useLocales from '../../hooks/useLocales';

type NumericProps = {
  numeric: number;
  fractionDigits?: number;
  // parentesis?: boolean;
  variantSize?: VariantSize;
};

function Numeric({ numeric, variantSize, fractionDigits = 0 }: NumericProps) {
  const { currentLang } = useLocales();
  return (
    <Typography variant={variantSize} component="span">
      {new Intl.NumberFormat(currentLang.value, {
        maximumFractionDigits: fractionDigits
      }).format(numeric)}
    </Typography>
  );
}

export default Numeric;
