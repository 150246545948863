import { useDispatch, useSelector } from 'react-redux';
// redux
import { RootState } from '../redux/store';
import {
  login,
  logout,
  register,
  resetPassword,
  updatePassword,
  updateProfile,
  verifyCode
} from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

type LoginParams = {
  email: string;
  password: string;
  remember: boolean;
};

type RegisterParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  iso: string;
};

type ResetParams = {
  email: string;
  iso: string;
};

type ProfileParams = {
  company: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  phoneNumber: string;
  country: string;
  address: string;
  city: string;
  zipCode: string;
  isPublic: boolean;
  uuid: string;
  currency: object;
};

type UpdatePasswordParams = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  iso: string;
  uuid: string;
};

type VerifyParams = {
  code: string;
  email: string;
};

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state: RootState) => state.authJwt
  );

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password, remember }: LoginParams) =>
      dispatch(
        login({
          email,
          password,
          remember
        })
      ),

    register: ({ email, password, firstName, lastName, iso }: RegisterParams) =>
      dispatch(
        register({
          email,
          password,
          firstName,
          lastName,
          iso
        })
      ),

    logout: () => dispatch(logout()),

    updateProfile: ({
      company,
      firstName,
      lastName,
      photoURL,
      phoneNumber,
      country,
      address,
      city,
      zipCode,
      isPublic,
      uuid
    }: ProfileParams) =>
      dispatch(
        updateProfile({
          company,
          firstName,
          lastName,
          photoURL,
          phoneNumber,
          country,
          address,
          city,
          zipCode,
          isPublic,
          uuid
        })
      ),

    resetPassword: ({ email, iso }: ResetParams) =>
      dispatch(
        resetPassword({
          email,
          iso
        })
      ),

    updatePassword: ({
      oldPassword,
      newPassword,
      confirmNewPassword,
      iso,
      uuid
    }: UpdatePasswordParams) =>
      dispatch(
        updatePassword({
          oldPassword,
          newPassword,
          confirmNewPassword,
          iso,
          uuid
        })
      ),

    verifyCode: ({ code, email }: VerifyParams) =>
      dispatch(verifyCode({ code, email }))
  };
}
