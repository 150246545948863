import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { RootState } from '../redux/rootReducer';
import { fetchArticle } from '../redux/slices/articlesDetail';
import { DateRangeFilter } from '../@types/filters';

function useArticlesDetail() {
  const dispatch = useDispatch();
  const {
    response: article,
    APIStatus,
    error,
    filters
  } = useSelector((state: RootState) => state.articlesDetails);
  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getOneArticle = (idArticle: string, filters: DateRangeFilter) => {
    cancelFetch();
    dispatch(fetchArticle(idArticle, filters, abortController.current));
  };

  return {
    article,
    // revenueAndOrdersComponent,
    APIStatus,
    error,
    filters,
    cancelFetch,
    getOneArticle
  };
}

export default useArticlesDetail;
