import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@material-ui/core';

import { RootState } from '../redux/rootReducer';
import { PurchaseStatusSelectItem } from '../@types/filters';
import useLocales from './useLocales';
import { fetchPurchaseDetail } from '../redux/slices/purchaseDetail';

// ----------------------------------------------------------------------

function usePurchaseDetail() {
  const dispatch = useDispatch();
  const { error, APIStatus, response } = useSelector(
    (state: RootState) => state.purchaseDetail
  );
  const { t: translate } = useTranslation(['component', 'filter']);
  const theme: Theme = useTheme();
  const { currentLang } = useLocales();

  const purchaseStatusOptions: Array<PurchaseStatusSelectItem> = useMemo(
    () => [
      {
        purchaseStatusId: 0,
        purchaseStatusName: translate('filter:all'),
        color: 'transparent'
      },
      {
        purchaseStatusId: 1,
        purchaseStatusName: translate('filter:suggested'),
        color: theme.palette.warning.main
      },
      {
        purchaseStatusId: 2,
        purchaseStatusName: translate('filter:open'),
        color: theme.palette.info.main
      },
      {
        purchaseStatusId: 3,
        purchaseStatusName: translate('filter:closed'),
        color: theme.palette.grey[600]
      }
    ],
    [currentLang]
  );

  function getPurchaseStatus(id: number): PurchaseStatusSelectItem {
    return (
      purchaseStatusOptions.find((p) => p.purchaseStatusId === id) ??
      purchaseStatusOptions[0]
    );
  }

  return {
    error,
    APIStatus,
    response,
    purchaseStatusOptions,
    getPurchaseStatus,
    getPurchaseDetail: (purchaseId: string) =>
      dispatch(fetchPurchaseDetail(purchaseId))
  };
}

export default usePurchaseDetail;
