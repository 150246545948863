import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@material-ui/core/styles';
import { MenuItem, ListItemText, Typography, Badge } from '@material-ui/core';
// hooks
import AccountCircleIcon from '@material-ui/icons/Info';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

export default function CompanyChange() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [MENU_OPTIONS, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = () => {
    axios
      .get('/api/v1/companyList')
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setSession = (accessToken: string | null) => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };
  const changeCompany = (companyTo: any) => {
    axios
      .get(`/api/v1/companyChange/${companyTo}`)
      .then((res) => {
        if (res.data.success === true) {
          setSession(res.data.token);
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {MENU_OPTIONS.length > 0 && (
        <>
          <MIconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
              padding: 0,
              width: 44,
              height: 44,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                }
              })
            }}
          >
            <AccountCircleIcon style={{ fontSize: 35 }} />
          </MIconButton>

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 'auto' }}
          >
            {MENU_OPTIONS.map((option: any) => (
              <MenuItem
                key={option.label}
                onClick={() => changeCompany(option.companyTo)}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <ListItemText>{option.label}</ListItemText>
                {option.companySwitch === false && (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      DS
                    </Typography>
                    <>&nbsp;</>
                    <Typography variant="body2" color="text.secondary">
                      MS
                    </Typography>
                    <>&nbsp;</>
                    <>&nbsp;</>
                    <>&nbsp;</>
                    <>&nbsp;</>
                    <>&nbsp;</>
                    <Typography variant="body2" color="text.secondary">
                      {option.isLive && (
                        <Badge badgeContent="" color="success" />
                      )}
                      {!option.isLive && (
                        <Badge badgeContent="" color="primary" />
                      )}
                    </Typography>
                  </>
                )}
              </MenuItem>
            ))}
          </MenuPopover>
        </>
      )}
    </>
  );
}
