import { useTheme } from '@emotion/react';
import { Box, TablePagination, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { SalesByCountryTable } from './SalesByCountryTable';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { APIStatus } from '../../@types/APIStatus';
import AreaChartFilter from '../filters/AreaChartFilter';
import useSalesByCountry from '../../hooks/useSalesByCountry';

type Props = {
  title?: string;
  articleId?: number;
};

export function SalesByCountry({
  title = 'sales_by_country',
  articleId = 0
}: Props) {
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translate } = useTranslation(['component']);
  const classes = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const {
    getSalesByCountry,
    cancelFetch,
    response,
    filters,
    APIStatus: state
  } = useSalesByCountry();

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);

  useEffect(() => {
    getSalesByCountry(filterBar, articleId);
    setPage(0);
    return () => {
      cancelFetch();
    };
  }, [filterBar]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <AreaChartFilter
      ready={state === APIStatus.FULFILLED || state === APIStatus.REJECTED}
      showCountry={false}
      title={translate(title)}
      filters={filterBar}
      onFiltersChange={setFilterBar}
      columnsByFilter={4}
    >
      <SalesByCountryTable page={page} rowsPerPage={rowsPerPage} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <TablePagination
          component="div"
          className={classes.root}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={!isMobile ? translate('global:rows_per_page') : ''}
          count={response.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
        />
      </Box>
    </AreaChartFilter>
  );
}
