import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
// axiosInstance.defaults.timeout = 50

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400: {
          console.log(error.response.data);
          break;
        } // Bad request data
        case 401 && window.location.href.indexOf('/auth/') === -1: // Auth error
        case 403 && window.location.href.indexOf('/auth/') === -1: // Forbidden
        case 500: {
          // redirect to login because there is no authentication or the server is down
          window.location.href = `${window.location.protocol}//${window.location.host}/auth/login`;
          break;
        }
        default: {
          return Promise.reject(
            (error.response && error.response.data) || 'Something went wrong'
          );
        }
      }
      return Promise.reject(
        (error.response && error.response.data) || 'Something went wrong'
      );
    }
    const err = {
      code: 0,
      status: '',
      message: 'Something went wrong'
    };
    err.status = error.code;
    switch (error.code) {
      case 'ECONNABORTED': // Timeout (client stops)
        err.message = 'timeout';
        break;
      case 'ERR_CANCELED': // Request cancelled
        err.message = `${error.message} ${error.config.url}`;
        break;
      default: {
        err.message = 'server_error';
        break;
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
