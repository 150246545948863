import {
  alpha,
  Box,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { APIStatus } from '../../@types/APIStatus';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: theme.spacing(2),
    backgroundColor: alpha('#fff', 0.75),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    transition: 'background-color 0.3s'
  }
}));

export function CardError() {
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  return (
    <Box className={classes.root}>
      <Typography
        textAlign="center"
        variant="h6"
        sx={{ color: theme.palette.grey[500] }}
      >
        {translate('server_error')}
      </Typography>
    </Box>
  );
}
