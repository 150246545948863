import { useDispatch, useSelector } from 'react-redux';
import { PricesBySourceFilterState } from '../@types/filterBars';
import { HeaderTableFilter } from '../@types/filters';
import { RootState } from '../redux/rootReducer';
import {
  fetchDashboardTab,
  fetchPriceList,
  resetDashboardTab,
  sortPriceListBy
} from '../redux/slices/dashboardTab';

function useDashboardTab() {
  const dispatch = useDispatch();
  const { data, APIStatus, error, filters } = useSelector(
    (state: RootState) => state.dashboardTab
  );

  return {
    data,
    filters,
    APIStatus,
    error,
    resetTab: () => {
      dispatch(resetDashboardTab());
    },
    getDashboardTabData: (articleId: number, language?: string) => {
      dispatch(fetchDashboardTab(filters, articleId, language));
    },
    /* revenueAndOrdersCancelFetch: () => {
      revenueAndOrdersAbortController.abort()
    },
    updateRevenueAndOrders: (
      updatedFilters: AreaChartFiltersState,
      articleId?: number
    ) => {
      dispatch(fetchRevenueAndOrders(updatedFilters, revenueAndOrdersAbortController, articleId));
    }, */
    /* updateSalesByCountry: (
      updatedFilters: AreaChartFiltersState,
      articleId?: number
    ) => {
      dispatch(fetchSalesByCountry(updatedFilters, articleId));
    },
    sortSalesByCountry: (orderBy: string, order: 'asc' | 'desc') => {
      dispatch(sortSalesByCountryBy(data.salesByCountry.data, orderBy, order));
    }, */
    /* updateProductHealth: (
      updatedFilters: AreaChartFiltersState,
      articleId: number
    ) => {
      dispatch(fetchProductHealth(updatedFilters, articleId));
    }, */
    updatePriceList: (
      updatedFilters: PricesBySourceFilterState,
      articleId?: number
    ) => {
      dispatch(fetchPriceList(updatedFilters, articleId));
    },
    sortPriceList: (
      orderBy: string,
      order: 'asc' | 'desc',
      orderType?: HeaderTableFilter
    ) => {
      dispatch(sortPriceListBy(data.priceList.data, orderBy, order, orderType));
    }
  };
}

export default useDashboardTab;
