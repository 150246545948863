import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { RootState } from '../redux/rootReducer';
import { fetchOrder } from '../redux/slices/order';

function useOrder() {
  const dispatch = useDispatch();
  const {
    response: order,
    APIStatus,
    error,
    filters
  } = useSelector((state: RootState) => state.order);
  const numPositions = order.articles.length;
  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getOrder = (idOrder: string) => {
    cancelFetch();
    dispatch(fetchOrder(idOrder, abortController.current));
  };

  return {
    numPositions,
    order,
    APIStatus,
    error,
    filters,
    cancelFetch,
    getOrder
  };
}

export default useOrder;
