import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { Dispatch } from 'react';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { SalesByCountryState } from '../../@types/reduxStates';
import { SalesByCountryResponse } from '../../@types/responsesAPI';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { SalesByCountryRow } from '../../@types/tableRows';
import { sortByProperty } from '../../utils/sortValues';
import { SortBy } from '../../@types/filters';

// ----------------------------------------------------------------------

const initialState: SalesByCountryState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    customerId: 0,
    profit: 0
  },
  response: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'salesByCountry',
  initialState,
  reducers: {
    setSalesByCountry: (
      state: SalesByCountryState,
      action: PayloadAction<SalesByCountryResponse>
    ) => {
      state.response = action.payload;
    },
    setFilters: (
      state: SalesByCountryState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getSalesByCountry: (state: SalesByCountryState) => {
      state.APIStatus = APIStatus.PENDING;
    },
    getSalesByCountrySuccess: (
      state: SalesByCountryState,
      action: PayloadAction<SalesByCountryResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
      state.error = undefined;
    },
    getSalesByCountryError: (
      state: SalesByCountryState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function sortTableBy(list: Array<SalesByCountryRow>, sortBy: SortBy) {
  const orderedList: SalesByCountryResponse = sortByProperty(
    list,
    sortBy.by,
    sortBy.order
  );
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setSalesByCountry(orderedList));
}

export function fetchSalesByCountry(
  filters: AreaChartFiltersState,
  abortController: AbortController,
  articleId: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getSalesByCountry());

      const params = {
        idArticle: articleId > 0 ? articleId : undefined,
        from: filters.timePeriod ? filters.timePeriod.startFilter : undefined,
        till: filters.timePeriod ? filters.timePeriod.endFilter : undefined,
        sourceId:
          filters.sourceId && filters.sourceId > 0
            ? filters.sourceId
            : undefined,
        shopId:
          filters.shopId && filters.shopId > 0 ? filters.shopId : undefined,
        customerId:
          filters.customerId && filters.customerId > 0
            ? filters.customerId
            : undefined
      };
      const response = await axios.get('/api/v2/article/salesByCountry', {
        signal: abortController.signal,
        params
      });
      dispatch(getSalesByCountrySuccess(response.data));
    } catch (error) {
      dispatch(getSalesByCountryError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setSalesByCountry,
  setFilters,
  getSalesByCountry,
  getSalesByCountrySuccess,
  getSalesByCountryError
} = slice.actions;
