import { makeStyles, Theme } from '@material-ui/core';

export const usefilterBarStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  filter: {
    padding: theme.spacing(2)
  },
  noPaddingBottom: {
    paddingBottom: 0
  },
  rowFlex: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  rowFlexBottom: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  end: {
    justifyContent: 'flex-end'
  },
  filterContainer: {
    width: '220px',
    marginRight: theme.spacing(2)
  },
  noPadding: {
    margin: 0
  },
  filterRight: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  filterNoPaddingVertical: {
    padding: theme.spacing(0, 2)
  },
  smallPaddingBottom: {
    paddingBottom: theme.spacing(0.5)
  },
  content: {
    width: '100%',
    backgroundColor: 'white'
  }
}));
