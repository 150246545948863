import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { sortByProperty } from '../../utils/sortValues';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState, FilterBarState } from '../../@types/filterBars';
import { CategoriesState } from '../../@types/reduxStates';
import {
  ByChannelReponse,
  PerformanceByCategoryResponse
} from '../../@types/responsesAPI';
import { HeaderTableFilter } from '../../@types/filters';

const initialState: CategoriesState = {
  performanceByCategory: {
    filters: {
      timePeriod: {
        startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
      },
      sourceId: 0,
      countryId: 0,
      shopId: 0,
      profit: 0,
      customerId: 0
    },
    data: [],
    APIStatus: APIStatus.IDLE
  },
  profitByCategory: {
    filters: {
      timePeriod: {
        startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
      },
      sourceId: 0,
      countryId: 0,
      shopId: 0,
      profit: 0,
      customerId: 0
    },
    data: [
      {
        name: '',
        margin: 0,
        marginBefore: 0,
        marginTending: 0,
        marginPercent: 0,
        revenue: 0,
        revenueBefore: 0,
        revenuePercent: 0,
        revenueTending: 0
      }
    ],
    APIStatus: APIStatus.IDLE
  }
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setPerformanceByCategory: (
      state: CategoriesState,
      action: PayloadAction<CategoriesState>
    ) => {
      state.performanceByCategory = action.payload.performanceByCategory;
    },
    setProfitByCategory: (
      state: CategoriesState,
      action: PayloadAction<CategoriesState>
    ) => {
      state.profitByCategory = action.payload.profitByCategory;
    },
    setPerformanceByCategoryFilters: (
      state: CategoriesState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.performanceByCategory.filters = action.payload;
    },
    setProfitByCategoryFilters: (
      state: CategoriesState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.profitByCategory.filters = action.payload;
    },
    getPerformanceByCategory: (state: CategoriesState) => {
      state.performanceByCategory.APIStatus = APIStatus.PENDING;
      state.performanceByCategory.error = undefined;
    },
    getPerformanceByCategorySuccess: (
      state: CategoriesState,
      action: PayloadAction<PerformanceByCategoryResponse>
    ) => {
      state.performanceByCategory.APIStatus = APIStatus.FULFILLED;
      state.performanceByCategory.data = action.payload;
    },
    sortPerformanceByCategory: (
      state: CategoriesState,
      action: PayloadAction<PerformanceByCategoryResponse>
    ) => {
      state.performanceByCategory.data = action.payload;
    },
    getPerformanceByCategoryError: (
      state: any,
      action: PayloadAction<APIError>
    ) => {
      state.performanceByCategory.APIStatus = APIStatus.REJECTED;
      state.performanceByCategory.error = action.payload;
    },
    sortProfitByCategory: (
      state: CategoriesState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.profitByCategory.data = action.payload;
    },
    getProfitByCategory: (state: CategoriesState) => {
      state.profitByCategory.APIStatus = APIStatus.PENDING;
      state.profitByCategory.error = undefined;
    },
    getProfitByCategorySuccess: (
      state: CategoriesState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.profitByCategory.APIStatus = APIStatus.FULFILLED;
      state.profitByCategory.data = action.payload;
    },
    getProfitByCategoryError: (state: any, action: PayloadAction<APIError>) => {
      state.profitByCategory.APIStatus = APIStatus.REJECTED;
      state.profitByCategory.error = action.payload;
    }
  }
});

export function fetchPerformanceByCategory(
  filters: AreaChartFiltersState,
  idArticle: string = ''
) {
  return async (dispatch: any) => {
    dispatch(setPerformanceByCategoryFilters(filters));
    try {
      dispatch(getPerformanceByCategory());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: string;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle.length > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/performanceByCategory',
        { params }
      );
      dispatch(getPerformanceByCategorySuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getPerformanceByCategoryError(error as APIError));
    }
  };
}

export function fetchProfitByCategory(
  filters: AreaChartFiltersState,
  idArticle: string = ''
) {
  return async (dispatch: any) => {
    dispatch(setProfitByCategoryFilters(filters));
    try {
      dispatch(getProfitByCategory());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: string;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle.length > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/profitByCategory',
        { params }
      );
      dispatch(getProfitByCategorySuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getProfitByCategoryError(error as APIError));
    }
  };
}

export function sortPerformanceByCategoryBy(
  list: PerformanceByCategoryResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortPerformanceByCategory(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setPerformanceByCategory,
  setPerformanceByCategoryFilters,
  sortPerformanceByCategory,
  getPerformanceByCategory,
  getPerformanceByCategorySuccess,
  getPerformanceByCategoryError,
  setProfitByCategory,
  setProfitByCategoryFilters,
  sortProfitByCategory,
  getProfitByCategory,
  getProfitByCategorySuccess,
  getProfitByCategoryError
} = slice.actions;
