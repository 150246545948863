import {
  Box,
  makeStyles,
  Slider,
  styled,
  Theme,
  Typography
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import { SlideRangeFilter } from '../../../@types/filters';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    width: '100%',
    // maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  label: {
    width: '100%',
    color: theme.palette.grey[500]
  }
}));

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2
});

const CustomSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabelCircle': {
    backgroundColor: 'transparent'
  },
  '& .MuiSlider-valueLabelLabel': {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700
  }
}));

type Props = {
  type?: 'percent' | 'euro' | 'number';
  label: string;
  values: SlideRangeFilter;
  onValueChange: (value: Array<number>) => void;
  step?: number;
  // disabled?: boolean;
};

export default function FilterSlide({
  values: { range, config },
  label,
  type,
  step,
  onValueChange
}: Props) {
  const { currentLang } = useLocales();
  const classes = useStyles();

  const [value, setValue] = useState<number[]>([range.min, range.max]);
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    setValue([range.min, range.max]);
  }, [range]);

  const euroFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const percentFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  function getValueText(valueNum: number): string {
    switch (type) {
      case 'euro':
        return euroFormat.format(valueNum);
      case 'percent':
        return percentFormat.format(valueNum / 100);
      default:
        return `${valueNum}`;
    }
  }

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.label}
        variant="subtitle2"
        fontSize="0.70rem"
        fontWeight={400}
        align="left"
      >
        {label}
      </Typography>

      <CustomSlider
        // disabled={config.max === 0}
        onChangeCommitted={(e: any, v) => onValueChange(v as number[])}
        min={config.min}
        max={config.max}
        getAriaLabel={getValueText}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        valueLabelFormat={getValueText}
        step={step}
        // getAriaValueText={getValueText}
        // marks={true}
      />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: -0.3
        }}
      >
        <TinyText>{getValueText(value[0])}</TinyText>
        <TinyText>{getValueText(value[1])}</TinyText>
      </Box>
    </Box>
  );
}
