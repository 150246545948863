import { Menu, IconButton, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
// import { useTranslation } from 'react-i18next';

type Props = {
  options: Array<string>;
  onMenuAction: (option: number) => void;
};
const ITEM_HEIGHT = 48;

function MenuDots({ options, onMenuAction }: Props) {
  // const { t: translate } = useTranslation(['component']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(ev) => handleClick(ev)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu-articles"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleClose();
              onMenuAction(i);
            }}
          >
            <Typography
              key={i}
              variant="body2"
              color="text.primary"
              fontWeight={500}
            >
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MenuDots;
