import React, { useEffect, useState } from 'react';

type IntersectionObserverOptions = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
};

const useIntersectionObserver = (
  elementRef: React.RefObject<Element>,
  options: IntersectionObserverOptions = {}
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const { top } = entry.boundingClientRect;
        const { top: rootTop } = entry.rootBounds ?? { top: 0 };
        setIsVisible(top < rootTop ? entry.isIntersecting : true);
      },
      {
        root: options.root || null,
        rootMargin: options.rootMargin || '0px',
        threshold: options.threshold || 0
      }
    );

    if (elementRef.current) {
      const fixedTableHeader = elementRef.current as HTMLElement;
      observer.observe(fixedTableHeader);
    }

    return () => {
      if (elementRef.current) {
        const fixedTableHeader = elementRef.current as HTMLElement;
        observer.unobserve(fixedTableHeader);
      }
    };
  }, [elementRef, options]);

  return isVisible;
};

export default useIntersectionObserver;
