import { Box, Typography, useTheme, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import useLocales from '../../hooks/useLocales';

type Props = {
  event: number;
  values: Array<number>;
};

function OneItemPercents({ event, values }: Props) {
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  const getPercentType = () => {
    switch (event) {
      case 1: {
        return translate('returns_rate');
      }
      case 2: {
        return translate('db3_contribution_margin');
      }
      default: {
        return translate('stock');
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
      }}
    >
      <Box sx={{ padding: '0px 8px' }}>
        <Box>
          <Typography textAlign="center" variant="caption">
            {getPercentType()}
          </Typography>
        </Box>
        <Box>
          <Typography
            textAlign="center"
            variant="h6"
            color={theme.palette.error.main}
          >
            {event === 3 ? values[0] : percent.format(Number(values[1] / 100))}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: '0px 8px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon
            width={14}
            height={14}
            icon="mdi:diameter-variant"
            color={theme.palette.text.secondary}
          />
          <Typography
            sx={{ marginLeft: '4px' }}
            textAlign="center"
            variant="caption"
          >
            {event === 3 ? translate('range') : getPercentType()}
          </Typography>
        </Box>
        <Box>
          <Typography
            textAlign="center"
            variant="h6"
            color={theme.palette.error.main}
          >
            {event === 3
              ? `${values[1]} ${translate('days_other')}`
              : percent.format(Number(values[0] / 100))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default OneItemPercents;
