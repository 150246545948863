import {
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useActivityStream from '../../hooks/useActivityStream';

type Props = {};

function EmailModal({}: Props) {
  const isValidEmail = (testEmail: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      testEmail
    );
  const { t: translate } = useTranslation(['component']);
  const [email, setEmail] = useState<string>('');
  const {
    uidMessage,
    sendEmailNotification,
    showEmailNotificationModal,
    setEmailModalVisible
  } = useActivityStream();

  const handleDialogClose = () => {
    setEmailModalVisible(false, '');
  };
  const handleSendEmail = () => {
    sendEmailNotification(email);
    setEmailModalVisible(false, '');
  };

  return (
    <Dialog
      open={showEmailNotificationModal}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate('send_email_notification')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>Activity ID: {uidMessage}</Typography>
          <Typography>
            Enter a valid email address to send a notification.
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!isValidEmail(email)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{translate('cancel')}</Button>
        <Button
          onClick={handleSendEmail}
          disabled={email.length === 0 || !isValidEmail(email)}
        >
          {translate('send_email')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmailModal;
