import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      // Tooltip
      '.apexcharts-tooltip,.apexcharts-xaxistooltip': {
        border: '0 !important',
        boxShadow: `${theme.customShadows.z24} !important`,
        color: `${theme.palette.text.primary} !important`,
        borderRadius: `${theme.shape.borderRadiusSm}px !important`,
        backgroundColor: `${theme.palette.background.default} !important`
      },
      '.apexcharts-tooltip-title': {
        border: '0 !important',
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${theme.palette.grey[500_16]} !important`,
        color:
          theme.palette.text[
            theme.palette.mode === 'light' ? 'secondary' : 'primary'
          ]
      },
      '.apexcharts-xaxistooltip-bottom': {
        '&:before': {
          borderBottomColor: 'transparent !important'
        },
        '&:after': {
          borderBottomColor: `${theme.palette.background.paper} !important`
        }
      },

      // Legend
      '.apexcharts-legend': {
        padding: '0 !important'
      },
      '.apexcharts-legend-series': {
        alignItems: 'center',
        display: 'flex !important'
      },
      '.apexcharts-legend-marker': {
        marginTop: '2px !important',
        marginRight: '8px !important'
      },
      '.apexcharts-legend-text': {
        lineHeight: '18px',
        textTransform: 'capitalize'
      }
    }
  })
);

type AreaChartProps = {
  series: Array<{
    name: string;
    data: Array<{ x: number; y: number }>;
    type?: string;
  }>;
  xaxis: Array<number>;
  titleChart?: string;
  euroSeries?: Array<Number>;
};

function AreaChart({
  series,
  xaxis,
  titleChart = '',
  euroSeries = []
}: AreaChartProps) {
  useStyles();
  const { t: translate } = useTranslation();
  const theme: Theme = useTheme();
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    minimumFractionDigits: 2
  });

  const options: ApexOptions = {
    colors: [
      theme.palette.success.main,
      theme.palette.error.main,
      theme.palette.primary.main,
      theme.palette.warning.main
    ],
    fill: {
      type: 'gradient',
      opacity: 1,
      colors: [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.primary.main,
        theme.palette.warning.main
      ],
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    stroke: {
      colors: [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.primary.main,
        theme.palette.warning.main
      ],
      width: 3,
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '75%'
        // borderRadius: 0,
        /* colors: {
          
          backgroundBarColors: ['#f2f2'],
          backgroundBarOpacity: 1,

        } */
      }
    },
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },
    xaxis: {
      categories: xaxis,
      type: 'datetime',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily,
          colors: theme.palette.grey[500]
        },
        // show: showLabels,
        formatter(value: any) {
          return new Date(value * 1000).toLocaleDateString(currentLang.value);
        },
        rotate: isMobile ? -90 : -45
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
          fontFamily: theme.typography.fontFamily,
          colors: [theme.palette.grey[500]]
        },
        formatter: (value: any) => euro.format(value)
      },
      title: {
        text: !isMobile ? `${translate('component:revenue')}` : undefined,
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.grey[500]
        }
      }
    },
    title: {
      text: !isMobile && titleChart ? titleChart : undefined,
      style: {
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[500]
      }
    },
    legend: {
      offsetY: titleChart && titleChart.length > 0 ? 0 : 30,
      horizontalAlign: isMobile ? 'center' : 'right',
      position: isMobile ? 'bottom' : 'top',
      show: true,
      fontSize: '13',
      markers: { radius: 12 },
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        fillColors: [
          theme.palette.success.main,
          theme.palette.error.main,
          theme.palette.primary.main,
          theme.palette.warning.main
        ]
      },
      style: {
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily
      },
      x: {
        show: false
      },
      y: {
        formatter(y: any, { seriesIndex }: any) {
          if (euroSeries.includes(seriesIndex)) {
            return euro.format(y);
          }
          return `${y.toFixed(2)}`;
        }
      }
    },
    dataLabels: {
      enabled: false,
      // offsetY: -15,
      formatter(value: any) {
        return euro.format(value);
      }
    },
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider
    },
    chart: {
      toolbar: { show: !isMobile },
      zoom: { enabled: !isMobile },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88
        }
      }
    }
  };
  return (
    <ReactApexChart
      type={series[0].data.length === 1 ? 'bar' : 'area'}
      series={series}
      options={options}
      height={320}
    />
  );
}

export default AreaChart;
