import { alpha, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useLocales from '../../../hooks/useLocales';

const useStyles = makeStyles<Theme, { color: string; noData: boolean }>(
  (theme) => ({
    root: {
      height: ({ noData }) => (noData ? '45px' : '100%'),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: ({ noData }) => (noData ? 'flex-start' : 'center'),
      alignItems: 'center'
    },
    status: {
      borderRadius: 7,
      padding: '4px 10px',
      marginTop: ({ noData }) => (noData ? 0 : 4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: ({ color }) => color,
      backgroundColor: ({ color }) => alpha(color, 0.1)
    }
  })
);

type StockCellProps = {
  stock: number;
  label: string;
};

export function StockCell({ stock, label }: StockCellProps) {
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const color = (labelStock: string) => {
    switch (labelStock) {
      case 'stock_available':
      case 'available': {
        return '#57CA22';
      }
      case 'stock_low':
      case 'low': {
        return '#FFA319';
      }
      default: {
        return '#FF1943';
      }
    }
  };

  const classes = useStyles({
    color: color(label),
    noData: label === 'stock_nodata'
  });

  const dot = new Intl.NumberFormat(currentLang.value);

  return (
    <Box className={classes.root}>
      {label !== 'stock_nodata' && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {dot.format(stock)}
        </Typography>
      )}

      <Box className={classes.status}>
        <Typography variant="caption" sx={{ fontWeight: 300 }}>
          {translate(label)}
        </Typography>
      </Box>
    </Box>
  );
}
