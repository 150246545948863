import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { store } from '../store';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { LogFilesState, Pagination } from '../../@types/reduxStates';
import { LogFilesResponse } from '../../@types/responsesAPI';
import { sortByProperty } from '../../utils/sortValues';
import { HeaderTableFilter, SortBy } from '../../@types/filters';
import { LogFilesFilterBarState } from '../../@types/filterBars';

// ----------------------------------------------------------------------

const initialState: LogFilesState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  response: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'logFiles',
  initialState,
  reducers: {
    setLogFiles(state: LogFilesState, action: PayloadAction<LogFilesResponse>) {
      state.response = action.payload;
    },
    setFilters: (
      state: LogFilesState,
      action: PayloadAction<LogFilesFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getLogFiles: (state: LogFilesState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getLogFilesSuccess: (
      state: LogFilesState,
      action: PayloadAction<LogFilesResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getLogFilesError: (
      state: LogFilesState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchLogFiles(
  filters: LogFilesFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy
) {
  return async (dispatch: any) => {
    try {
      dispatch(getLogFiles());
      const response = await axios.get(`/api/v2/logFiles/rows`, {
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          skip,
          limit,
          by,
          order
        }
      });
      dispatch(getLogFilesSuccess(response.data));
    } catch (error) {
      dispatch(getLogFilesError(error as APIError));
    }
  };
}

export function sortLogFilesBy(
  list: LogFilesResponse,
  sortBy: SortBy,
  orderType?: HeaderTableFilter
): LogFilesResponse {
  return sortByProperty(list, sortBy.by, sortBy.order, orderType);
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setLogFiles,
  getLogFiles,
  getLogFilesSuccess,
  getLogFilesError
} = slice.actions;
