import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, subDays } from 'date-fns';
import {
  CategoryFilterItem,
  CountrySelectItem,
  CustomerSelectItem,
  DateRangeFilter,
  ManufactureItem,
  MultiselectItem,
  MultiselectTreeItem,
  PurchaseStatusSelectItem,
  ShopSelectItem,
  SlideRangeFilter,
  SourceSelectItem,
  StatusSelectItem,
  StockLabelItem,
  SupplierSelectItem
} from '../@types/filters';
import { TableUrlParams } from '../@types/tableRows';
import { GeneralFilterBarState } from '../@types/filterBars';

type OnlineMarketingSliderNameType =
  | 'dailyBudgetFrom'
  | 'dailyBudgetTill'
  | 'clicksFrom'
  | 'clicksTill'
  | 'conversionsFrom'
  | 'conversionsTill'
  | 'impressionsFrom'
  | 'impressionsTill';

function isOnlineMarketingSliderNameType(
  value: any
): value is OnlineMarketingSliderNameType {
  return [
    'dailyBudgetFrom',
    'dailyBudgetTill',
    'clicksFrom',
    'clicksTill',
    'conversionsFrom',
    'conversionsTill',
    'impressionsFrom',
    'impressionsTill'
  ].includes(value);
}

type FilterBar = {
  timePeriod?: DateRangeFilter;
  timeInterval?: number;
  status?: number | Array<StatusSelectItem>;
  channelTags?: Array<SourceSelectItem>;
  customers?: Array<CustomerSelectItem>;
  amount?: SlideRangeFilter;
  margin?: SlideRangeFilter;
  countryId?: number;
  returns?: boolean | SlideRangeFilter;
  text?: string;
  shop?: number | ShopSelectItem;
  source?: number;
  searchName?: string;
  searchNameDp?: string;
  eventType?: number;
  section?: number;
  groupBy?: number;
  groupByCategory?: number;
  columns?: Array<{ id: number; label: string }> | Array<string>;
  level?: number;
  readed?: boolean;
  stock?: number | Array<StockLabelItem>;
  countries?: Array<CountrySelectItem>;
  sources?: Array<SourceSelectItem>;
  categories?: Array<CategoryFilterItem>;
  profitBy?: number;
  suppliers?: Array<SupplierSelectItem>;
  customerId?: number;
  revenue?: SlideRangeFilter;
  isActivity?: boolean;
  isBundle?: boolean;
  isRecommendations?: boolean;
  isCritical?: boolean;
  searchId?: string;
  page?: number;
  rowsPerPage?: number;
  by?: string;
  order?: string;
  purchaseStatus?: PurchaseStatusSelectItem;
  manufacturers?: Array<ManufactureItem>;
  /* Online marketing */
  campaignStart?: DateRangeFilter;
  campaignEnd?: DateRangeFilter;
  dailyBudget?: Array<number>;
  impressions?: Array<number>;
  clicks?: Array<number>;
  conversions?: Array<number>;
  channel?: Array<MultiselectItem>;
  campaign?: Array<MultiselectItem>;
  adGroupName?: Array<MultiselectTreeItem>;
  campaignType?: Array<MultiselectItem>;
  campaignStatus?: Array<MultiselectItem>;
  campaignStartEnabled?: boolean;
  campaignEndEnabled?: boolean;
};

function extractValues(
  key: keyof FilterBar,
  value: any
): { [key: string]: any } {
  const plainValues: { [key: string]: any } = {};
  if (typeof value === 'number' && value > 0) {
    plainValues[key] = value;
  }
  if (typeof value === 'boolean') {
    if (key === 'campaignStartEnabled' || key === 'campaignEndEnabled') {
      plainValues[key] = value ? 1 : 0;
    }
    if (value) {
      plainValues[key] = 1;
    }
  }
  if (typeof value === 'string') {
    if (value.length > 0 || key === 'searchNameDp') {
      plainValues[key] = value;
    }
  }
  if (Array.isArray(value)) {
    if (value.length > 0) {
      plainValues[key] = `${value
        .map((item) => {
          if (typeof item === 'number') {
            return item;
          }
          return (
            item.id ||
            item.abcId ||
            item.sourceId ||
            item.warehouseId ||
            item.customerId ||
            item.countryId ||
            item.orderStatusId ||
            item.supplierId ||
            item.categoryExtId ||
            item.purchaseStatusId ||
            item.manufactureId ||
            item.id
          );
        })
        .join('-')}`;
    }
  }
  if (typeof value === 'object') {
    if (key === 'purchaseStatus' && value.purchaseStatusId > 0) {
      plainValues.purchaseStatus = value.purchaseStatusId;
    }
    if (key === 'timePeriod') {
      plainValues.from = value.startFilter;
      plainValues.till = value.endFilter;
    }
    if (key === 'campaignStart') {
      plainValues.campaignStartFrom = value.startFilter;
      plainValues.campaignStartTill = value.endFilter;
    }
    if (key === 'campaignEnd') {
      plainValues.campaignEndFrom = value.startFilter;
      plainValues.campaignEndTill = value.endFilter;
    }
    if (key === 'shop' && value.shopId > 0) {
      plainValues.shop = value.shopId;
    }
    if (key === 'returns') {
      plainValues.returnsFrom = value.range.min;
      plainValues.returnsTo = value.range.max;
    }
    if (key === 'amount') {
      plainValues.amountFrom = value.range.min;
      plainValues.amountTo = value.range.max;
    }
    if (key === 'margin') {
      plainValues.margeFrom = value.range.min;
      plainValues.margeTo = value.range.max;
    }
  }
  if (key === 'revenue') {
    plainValues.revenueFrom = value.range.min;
    plainValues.revenueTo = value.range.max;
  }
  return plainValues;
}

function copyToClipboard(text: string): void {
  navigator.clipboard.writeText(text).catch((error) => {
    console.error('Error: ', error);
  });
}

function removeHashFromUrl() {
  const uri = window.location.toString();
  if (uri.indexOf('#') > 0) {
    const clean_uri = uri.substring(0, uri.indexOf('#'));
    window.history.replaceState({}, document.title, clean_uri);
  }
}

export function useCopyFilters() {
  const { pathname, hash } = useLocation();
  const { t: translate } = useTranslation(['component']);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [iconData, setIconData] = useState<{ icon: string; tooltip: string }>({
    icon: 'eva:copy-outline',
    tooltip: translate('copy_to_clipboard')
  });
  const [iconSave, setIconSave] = useState<{ icon: string; tooltip: string }>({
    icon: 'eva:save-outline',
    tooltip: translate('save_config')
  });

  function configURL(filterBar: FilterBar, table?: TableUrlParams) {
    const params = fromFilterBarToParams(filterBar, table);
    const size = [...new Set(params.keys())].length;
    const p = size > 0 ? `#${params.toString()}` : '';
    const result = `${window.location.origin}${pathname}${p}`;
    copyToClipboard(result);
  }

  function fromUrlToGeneralFilterBar(
    url: string,
    columns: Array<{ id: number; label: string }> = []
  ): GeneralFilterBarState {
    const filterbar: GeneralFilterBarState = {
      groupBy: 0,
      timePeriod: {
        startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
      },
      clicksFrom: 0,
      clicksTill: 0,
      costsFrom: 0,
      costsTill: 0,
      conversionsFrom: 0,
      conversionsTill: 0,
      impressionsFrom: 0,
      impressionsTill: 0,
      campaignType: 0,
      campaignStatus: 0,
      columns
    };

    const parseAndAssign = (name: string, value: string) => {
      switch (name) {
        case 'from':
          {
            if (filterbar.timePeriod) {
              filterbar.timePeriod = {
                ...filterbar.timePeriod,
                startFilter: value
              };
            }
          }
          break;
        case 'till':
          {
            if (filterbar.timePeriod) {
              filterbar.timePeriod = {
                ...filterbar.timePeriod,
                endFilter: value
              };
            }
          }
          break;
        case 'costsFrom':
        case 'costsTill':
        case 'clicksFrom':
        case 'clicksTill':
        case 'impressionsFrom':
        case 'impressionsTill':
        case 'conversionsFrom':
        case 'conversionsTill':
        case 'groupBy':
        case 'campaignType':
        case 'campaignStatus':
          {
            // if (filterbar.hasOwnProperty(name)) {
            if (Object.prototype.hasOwnProperty.call(filterbar, name)) {
              filterbar[name] = Number(value);
            }
          }
          break;
      }
    };

    if (url.length > 0) {
      url.split('&').forEach((part) => {
        const [name, value] = part.split('=');
        parseAndAssign(name, value);
      });
    }

    return filterbar;
  }

  const copyCurrentHashToClipboard = () => {
    const url = `${window.location.origin}${pathname}${hash}`;
    copyToClipboard(url);
  };

  function getUrlHash(filterBar: FilterBar, table?: TableUrlParams): string {
    const params = fromFilterBarToParams(filterBar, table);
    return params.toString();
  }

  function setUrlValues(
    filterBar: FilterBar,
    table?: TableUrlParams,
    updatePage: boolean = true
  ) {
    const params = fromFilterBarToParams(filterBar, table);
    const size = [...new Set(params.keys())].length;
    if (size > 0) {
      const newHash = params.toString();
      if (updatePage) {
        window.location.hash = newHash;
      } else {
        window.history.replaceState(null, '', `#${newHash}`);
      }
    } else {
      removeHashFromUrl();
    }
  }

  function fromParamsToFilterBar(config: string) {
    if (config.length > 0) {
      window.location.hash = config;
    } else {
      removeHashFromUrl();
    }
  }

  function fromFilterBarToParams(
    filterBar: FilterBar,
    table?: TableUrlParams
  ): URLSearchParams {
    const params = new URLSearchParams();
    /* eslint-disable */
    if (table) {
      //params.set('page',  `${table.page}`);
      //params.set('rowsPerPage',  `${table.rowsPerPage}`);
      params.set('by', table.by);
      params.set('order', table.order);
    }
    for (const key in filterBar) {
      if (Object.prototype.hasOwnProperty.call(filterBar, key)) {
        const values = extractValues(key as keyof FilterBar, filterBar[key as keyof FilterBar])
        for (const clave in values) {
          params.set(clave, values[clave]);
        }
      }
    }
    return params;
    /* eslint-enable */
  }

  const copyUrlToClipboard = (filterBar: FilterBar, table?: TableUrlParams) => {
    if (!isButtonDisabled) {
      setButtonDisabled(true);
      setIconData({
        icon: 'eva:copy-fill',
        tooltip: translate('url_copied_to_clipboard')
      });
      configURL(filterBar, table);
      setTimeout(() => {
        setIconData({
          icon: 'eva:copy-outline',
          tooltip: translate('copy_to_clipboard')
        });
        setButtonDisabled(false);
      }, 2000);
    }
  };

  function getUrlTableValues(tableUrlParams: TableUrlParams): TableUrlParams {
    const t: TableUrlParams = {
      ...tableUrlParams
    };
    if (hash.length > 0) {
      hash
        .split('#')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'page': {
              t.page = Number(value);
              break;
            }
            case 'rowsPerPage': {
              t.rowsPerPage = Number(value);
              break;
            }
            case 'by': {
              t.by = value;
              break;
            }
            case 'order': {
              t.order = value as 'asc' | 'desc';
              break;
            }
            default: {
              break;
            }
          }
        });
    }
    return t;
  }

  function clearHash() {
    removeHashFromUrl();
  }

  return {
    clearHash,
    getUrlHash,
    copyUrlToClipboard,
    copyCurrentHashToClipboard,
    fromParamsToFilterBar,
    fromUrlToGeneralFilterBar,
    iconData,
    iconSave,
    isButtonDisabled,
    setUrlValues,
    getUrlTableValues
  };
}
