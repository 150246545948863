import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { fetchBundle } from '../redux/slices/bundle';

// ----------------------------------------------------------------------

function useBundle() {
  const dispatch = useDispatch();
  const { response, APIStatus, error } = useSelector(
    (state: RootState) => state.bundle
  );

  return {
    response,
    APIStatus,
    error,
    getBundle: (articleId: number) => dispatch(fetchBundle(articleId))
  };
}

export default useBundle;
