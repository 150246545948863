import { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import OrderArticlesTable from './OrderArticlesTable';
import useOrder from '../../hooks/useOrder';

type Props = {
  title?: string;
};

export default function OrderArticles({ title = 'articles' }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = TablePaginationClasses();
  const { numPositions } = useOrder();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Card>
      <CardHeader title={translate(title)} />
      <CardContent>
        <OrderArticlesTable rowsPerPage={rowsPerPage} page={page} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <TablePagination
            component="div"
            className={classes.root}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={
              !isMobile ? translate('global:rows_per_page') : ''
            }
            count={numPositions}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
