import { Fade, Tooltip, Typography } from '@material-ui/core';

export interface ICellTooltipProps {
  align: 'center' | 'end' | 'start';
  title: string;
  tooltip: string;
}

export function CellTooltip(props: ICellTooltipProps) {
  const { title, tooltip, align } = props;

  return (
    <Tooltip
      title={tooltip}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <Typography
        variant="body1"
        sx={{ textAlign: (align && align) || 'start' }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
}
