import { Typography } from '@material-ui/core';

type Props = {
  label: string;
  value: any;
};

function ArticleLabel({ label, value }: Props) {
  return (
    <Typography
      variant="body2"
      color="text.primary"
      fontWeight={500}
      noWrap
      sx={{ display: 'inline-flex' }}
    >
      {label}:
      <Typography
        component="span"
        variant="body2"
        color="text.primary"
        fontWeight={600}
        noWrap
        overflow="hidden"
        sx={{ marginLeft: '4px' }}
      >
        {value}
      </Typography>
    </Typography>
  );
}

export default ArticleLabel;
