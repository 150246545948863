import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Drawer, Hidden } from '@material-ui/core';
// hooks
// components
//
import useMenu from '../../hooks/useMenu';
import useLocales from '../../hooks/useLocales';
import { APIStatus } from '../../@types/APIStatus';
import DashboardSidebarContent from './DashboardSidebarContent';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const { pathname } = useLocation();
  const { APIStatus: menuState } = useMenu();
  const [itemExpanded, setItemExpanded] = useState('');

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {menuState === APIStatus.FULFILLED && (
            <DashboardSidebarContent
              onExpand={setItemExpanded}
              itemExpanded={itemExpanded}
            />
          )}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {menuState === APIStatus.FULFILLED && (
            <DashboardSidebarContent
              onExpand={setItemExpanded}
              itemExpanded={itemExpanded}
            />
          )}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
