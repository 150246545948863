import { makeStyles, Theme } from '@material-ui/core';

export const helperStyles = makeStyles<Theme>((theme) => ({
  widgetRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // border: '1px solid green'
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1)
    // border: '1px solid black'
  },
  rowFlex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(0, 1)
    // border: '1px solid black',
  },
  column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
    // border: '1px solid gold'
  },
  rowNoPadding: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
    // border: '1px solid black'
  },
  half: {
    flex: 1
    // border: '1px solid red'
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  start: {
    // border: '1px solid blue',
    justifyContent: 'flex-start'
  },
  end: {
    justifyContent: 'flex-end'
    // border: '1px solid red'
  },
  maxHeight: {
    height: '100%'
  },
  shrinkHight: {
    flexShrink: 0
    // border: '1px solid red'
  },
  maxWidth: {
    width: '100%'
  }
}));
