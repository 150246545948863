import { makeStyles, Theme } from '@material-ui/core';

type TextProps = {
  lines?: number;
};

export const textStyles = makeStyles<Theme, TextProps>((theme) => ({
  ellipsis: {
    maxWidth: '100%',
    display: '-webkit-box',
    // direction: 'rtl',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: ({ lines }) => lines ?? 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
    /* '&:hover': {
      overflow: 'visible',
    } */
  },
  ellipsisFront: {
    border: '1px solid red',
    width: '100%',
    display: 'inline-block',
    direction: 'rtl',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));
