import { Icon } from '@iconify/react';
import { ReactNode, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

// theme
import { Location } from 'history';
import typography from '../../theme/typography';
import SidebarIcon from '../../components/general/SidebarIcon';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),

    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.noActive': {
    '& .MuiListItemText-root': {
      color: theme.palette.text.disabled
    },
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent'
    }
  }
}));

/* const SubIconStyle = styled('span')(({ theme }) => ({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    // backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform')
  }
})); */
// ----------------------------------------------------------------------

type NavItemProps = {
  active?: boolean;
  level: number;
  title: string;
  href?: string;
  info?: ReactNode;
  // icon?: ReactNode;
  icon: any;
  isOpen?: boolean;
  children?: ReactNode;
  // className?: string;
  itemExpanded: string;
  onExpand: (itemTitleExpanded: string) => void;
};

export default function SidebarItem({
  active = false,
  level,
  title,
  href,
  info,
  icon,
  isOpen = false,
  itemExpanded,
  children,
  // className,
  onExpand
}: NavItemProps) {
  const [show, setShow] = useState(false);
  const [animate, setAnimate] = useState<boolean>(false);
  const isSubItem = level > 0;
  useEffect(() => {
    setShow(title === itemExpanded);
  }, [itemExpanded]);

  const handleShow = () => {
    if (active) {
      setShow((show) => !show);
      if (!show) {
        onExpand(title);
      }
    }
  };
  if (children) {
    return (
      <Box
        component="div"
        onMouseEnter={() => setAnimate(true)}
        onMouseLeave={() => setAnimate(false)}
      >
        <ListItemStyle
          button
          disableGutters
          disableRipple={!active}
          disabled={!active}
          onClick={handleShow}
          className={!active ? 'noActive' : isOpen ? 'isActiveRoot' : ''}
        >
          <ListItemIcon>
            <SidebarIcon json={icon} stop={!active || !animate} />
          </ListItemIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {active && (
            <Box
              component={Icon}
              icon={
                show
                  ? 'eva:arrow-ios-downward-fill'
                  : 'eva:arrow-ios-forward-fill'
              }
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          )}
        </ListItemStyle>

        <Collapse in={show}>{children}</Collapse>
      </Box>
    );
  }

  return (
    <Box
      component="div"
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
    >
      <ListItemStyle
        button
        // @ts-ignore
        to={href || '/'}
        exact={isOpen}
        disableGutters
        disableRipple={!active}
        component={RouterLink}
        className={!active ? 'noActive' : ''}
        activeClassName={isSubItem ? 'isActiveSub' : 'isActiveRoot'}
        disabled={!active}
        isActive={(match: { url: string }, location: Location) => {
          if (!active || !match) {
            return false;
          }
          const { url } = match;
          const { pathname } = location;
          const isMatch = url === pathname;

          if (!isSubItem) {
            return url.length && pathname.includes(url);
          }

          return isMatch;
        }}
      >
        <ListItemIcon>
          {/* {isSubItem ? <SubIconStyle className="subIcon" /> : icon } */}
          {isSubItem ? (
            <Box sx={{ width: '6px', height: '22px', marginRight: '16px' }} />
          ) : (
            <SidebarIcon json={icon} stop={!active || !animate} />
          )}
          {/* {!isSubItem && icon } */}
        </ListItemIcon>
        <ListItemText disableTypography primary={title} />

        {info && info}
      </ListItemStyle>
    </Box>
  );
}
