import { Paper, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ic_cart from '../../lotties/ic_cart.json';
import ic_trolley from '../../lotties/ic_trolley.json';
import ic_invoice from '../../lotties/ic_invoice.json';
import useOrder from '../../hooks/useOrder';
import { fDateTimeOnly, fDateTimeline } from '../../utils/formatTime';
import OrderTimelineItem from './OrderTimelineItem';
import { TimelineDate } from '../../@types/orders';

export default function OrderTimeline() {
  const { t: translate } = useTranslation(['component']);
  const {
    order: {
      orderTime,
      orderInvoiceDate,
      orderHasInvoice,
      orderChangeTime,
      orderTypeId,
      orderExitDate
    }
  } = useOrder();

  const timelineOrdered = [
    orderTime
      ? {
          date: orderTime,
          text: translate('order_received'),
          formatDate: fDateTimeline(orderTime),
          formatTime: `${fDateTimeOnly(orderTime)} UHR`,
          icon: ic_cart
        }
      : null,
    orderHasInvoice && orderInvoiceDate && orderInvoiceDate.length > 0
      ? {
          date: orderInvoiceDate,
          text: translate('invoice_created'),
          formatDate: fDateTimeline(orderInvoiceDate),
          formatTime: `${fDateTimeOnly(orderInvoiceDate)} UHR`,
          icon: ic_invoice
        }
      : null,
    orderExitDate
      ? {
          date: orderExitDate,
          text: translate('outgoing_items_booked'),
          formatDate: fDateTimeline(orderExitDate),
          formatTime: `${fDateTimeOnly(orderExitDate)} UHR`,
          icon: ic_trolley
        }
      : null,
    orderTypeId === 3 && orderChangeTime && orderChangeTime.length > 0
      ? {
          date: orderChangeTime,
          text: translate('order_returned'),
          formatDate: fDateTimeline(orderChangeTime),
          formatTime: `${fDateTimeOnly(orderChangeTime)} UHR`
        }
      : null
  ]
    .filter((d): d is TimelineDate => d !== null)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <Card>
      <CardHeader title={translate('timeline')} />
      <CardContent>
        <Paper
          variant="outlined"
          sx={{ paddingTop: '24px', paddingBottom: '24px' }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Timeline>
                {timelineOrdered.map((tl, i) => (
                  <OrderTimelineItem key={i} timelineDate={tl} />
                ))}
              </Timeline>
            </Grid>
          </Grid>
        </Paper>
      </CardContent>
    </Card>
  );
}
