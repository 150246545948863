import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { WidgetFiltersState } from '../../@types/filterBars';
import { OrdersByCountryState, Pagination } from '../../@types/reduxStates';
import { OrdersByCountryResponse } from '../../@types/responsesAPI';
import { SortBy } from '../../@types/filters';

const initialState: OrdersByCountryState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  APIStatus: APIStatus.IDLE,
  response: {
    rows: [],
    total: {
      country: '',
      code: '',
      returns: 0,
      orders: 0,
      revenue: 0,
      percent: 0
    },
    totalCount: { count: 0 }
  }
};

const slice = createSlice({
  name: 'ordersByCountry',
  initialState,
  reducers: {
    setOrdersByCountryFilters: (
      state: OrdersByCountryState,
      action: PayloadAction<WidgetFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getOrdersByCountry: (state: OrdersByCountryState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrdersByCountrySuccess: (
      state: OrdersByCountryState,
      action: PayloadAction<OrdersByCountryResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOrdersByCountryError: (
      state: OrdersByCountryState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOrdersByCountry(
  filterBarState: WidgetFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setOrdersByCountryFilters(filterBarState));
    try {
      dispatch(getOrdersByCountry());
      const params = {
        skip,
        limit,
        by,
        order,
        from: filterBarState.timePeriod
          ? filterBarState.timePeriod.startFilter
          : undefined,
        till: filterBarState.timePeriod
          ? filterBarState.timePeriod.endFilter
          : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/api/v1/ordersByCountry',
        { signal: abortController.signal, params }
      );
      dispatch(getOrdersByCountrySuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getOrdersByCountryError(error as APIError));
    }
  };
}

/* export function sortOrdersByCountryBy(
  list: OrdersByCountryData,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortOrdersByCountry(orderedList));
} */

// Reducer
export default slice.reducer;

// Actions
export const {
  setOrdersByCountryFilters,
  getOrdersByCountry,
  getOrdersByCountrySuccess,
  getOrdersByCountryError
} = slice.actions;
