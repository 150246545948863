import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { SortBy } from '../../@types/filters';
import { ActivityStreamState, Pagination } from '../../@types/reduxStates';
import { ActivityStreamFiltersState } from '../../@types/filterBars';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ActivityStreamResponse } from '../../@types/responsesAPI';
import { ActivityStreamRow } from '../../@types/tableRows';

const initialState: ActivityStreamState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 0), 'yyyy-MM-dd')
    },
    searchName: '',
    eventType: 0,
    section: 0,
    status: 0,
    groupBy: 0,
    shop: 0,
    source: 0,
    level: 0,
    readed: false
  },
  response: {
    rows: [],
    count: 0,
    total: {
      articles: [],
      uid: '',
      year: 0,
      date: 0,
      section: 0,
      event: 0,
      message: '',
      messageData: [],
      priority: 0,
      isRead: true,
      periodDays: '',
      itemCount: 0,
      sourceIds: [],
      sourceNames: [],
      showYear: false
    }
  },
  APIStatus: APIStatus.IDLE,
  showEmailNotificationModal: false,
  uidMessage: ''
};

const slice = createSlice({
  name: 'activityStream',
  initialState,
  reducers: {
    updateActivityStreamRows(
      state: ActivityStreamState,
      action: PayloadAction<Array<ActivityStreamRow>>
    ) {
      state.APIStatus = APIStatus.FULFILLED;
      state.response.rows = action.payload;
    },
    setUidMessage(state: ActivityStreamState, action: PayloadAction<string>) {
      state.uidMessage = action.payload;
    },
    setEmailNotificationModalVisible(
      state: ActivityStreamState,
      action: PayloadAction<{ show: boolean; uid: string }>
    ) {
      state.showEmailNotificationModal = action.payload.show;
      state.uidMessage = action.payload.uid;
    },
    emailNotificationSent(state: ActivityStreamState) {
      state.APIStatus = APIStatus.FULFILLED;
    },
    setFilters: (
      state: ActivityStreamState,
      action: PayloadAction<ActivityStreamFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getActivityStream: (state: ActivityStreamState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getActivityStreamSuccess: (
      state: ActivityStreamState,
      action: PayloadAction<ActivityStreamResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getActivityStreamError: (
      state: ActivityStreamState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },
    setStatus(state: ActivityStreamState, action: PayloadAction<APIStatus>) {
      state.APIStatus = action.payload;
    }
  }
});

export function updateFilters(filters: ActivityStreamFiltersState) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
    } catch (error) {
      dispatch(getActivityStreamError(error as APIError));
    }
  };
}

export function setLoadingState(state: APIStatus) {
  return async (dispatch: any) => {
    try {
      dispatch(setStatus(state));
    } catch (error) {
      dispatch(getActivityStreamError(error as APIError));
    }
  };
}

export function setMessageReaded(
  rows: Array<ActivityStreamRow>,
  uidMessage: string = ''
) {
  return async (dispatch: any) => {
    if (uidMessage.length > 0) {
      dispatch(getActivityStream());
      axios
        .get('/api/v2/activityStream/readMessage', {
          params: { uidMessage, isRead: 1 }
        })
        .then((resp) => {
          if (resp.status === 200) {
            const updated = rows.map((m) =>
              m.uid === uidMessage ? { ...m, isRead: true } : m
            );
            dispatch(updateActivityStreamRows(updated));
          }
        })
        .catch((err) => dispatch(getActivityStreamError(err as APIError)));
    }
  };
}

export function sendEmailNotification(uidMessage: string, email: string) {
  return async (dispatch: any) => {
    if (uidMessage.length > 0) {
      dispatch(getActivityStream());
      axios
        .get('/rest/v2/activityStream/sendEmailNotification', {
          params: { uidMessage, email }
        })
        .then(
          (resp) => resp.status === 200 && dispatch(emailNotificationSent())
        )
        .catch((err) => dispatch(getActivityStreamError(err as APIError)));
    }
  };
}

export function fetchActivityStream(
  filters: ActivityStreamFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  idArticle: number = 0,
  lang: string
) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
      dispatch(getActivityStream());
      const response = await axios.get('/api/v2/activityStream/list', {
        params: {
          lang,
          idArticle: idArticle > 0 ? idArticle : undefined,
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          searchName:
            filters.searchName.length > 0 ? filters.searchName : undefined,
          eventType: filters.eventType > 0 ? filters.eventType : undefined,
          section: filters.section > 0 ? filters.section : undefined,
          shop: filters.shop > 0 ? filters.shop : undefined,
          source: filters.source > 0 ? filters.source : undefined,
          status: filters.status > 0 ? filters.status : undefined,
          groupBy: filters.groupBy > 0 ? filters.groupBy : undefined,
          level: filters.level > 0 ? filters.level : undefined,
          readed: filters.readed ? 1 : undefined,
          skip,
          limit,
          order,
          by
        }
      });

      dispatch(getActivityStreamSuccess(response.data));
    } catch (error) {
      dispatch(getActivityStreamError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  updateActivityStreamRows,
  emailNotificationSent,
  setUidMessage,
  setEmailNotificationModalVisible,
  getActivityStream,
  getActivityStreamSuccess,
  getActivityStreamError,
  setStatus
} = slice.actions;
