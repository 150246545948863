import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIStatus } from '../../@types/APIStatus';
import { SalesByCountryRow } from '../../@types/tableRows';
import useDashboardTab from '../../hooks/useDashboardTab';
import useLocales from '../../hooks/useLocales';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { CardError } from '../general/CardError';
import { CardLoading } from '../general/CardLoading';
import { CardNoResult } from '../general/CardNoResult';
import { CountryFlag } from '../general/CountryFlag';
import TableRowSummary from '../general/TableRowSummary';
import useSalesByCountry from '../../hooks/useSalesByCountry';
import useAuth from '../../hooks/useAuth';
import CardTableStatus from '../general/CardTableStatus';

type SalesByCountryTableProps = {
  // data: SalesByCountryResponse;
  page: number;
  rowsPerPage: number;
};

export function SalesByCountryTable({
  // data,
  page,
  rowsPerPage
}: SalesByCountryTableProps) {
  // const { data, page, rowsPerPage, onRequestSort } = props;
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const classes = TableStyleClasses({ height: 60, numRows: rowsPerPage });
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const {
    response: data,
    APIStatus: state,
    error,
    sortTableBy
  } = useSalesByCountry();

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('orders');
  const TABLE_HEAD = [
    { id_h: 'country', label: translate('filter:country'), align: 'left' },
    { id_h: 'sales', label: translate('units'), align: 'center' },
    { id_h: 'value', label: translate('value'), align: 'center' }
    // { id_h: 'orders', label: translate('orders'), align: 'center' }
  ];

  const [emptyRows, setEmptyRows] = useState<number>(0);
  const [sales, setSales] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [orders, setOrders] = useState<number>(0);
  // const [visibleRows, setVisibleRows] = useState<number>(rowsPerPage); // For average!!
  useEffect(() => {
    if (data.length > 0) {
      let vRows = 0;
      if (Math.floor(data.length / rowsPerPage) === page) {
        vRows = data.length % rowsPerPage;
        setEmptyRows(rowsPerPage - vRows);
      } else {
        vRows = rowsPerPage;
        setEmptyRows(0);
      }
      // setVisibleRows(vRows);
      updateSummary();
    }
  }, [data, page, orderBy]);

  const handleRequestSort = (property: string) => {
    let ord: 'asc' | 'desc' = 'desc';
    if (orderBy === property) {
      if (order === 'asc') {
        ord = 'desc';
      } else if (order === 'desc') {
        ord = 'asc';
      }
    }
    setOrder(ord);
    setOrderBy(property);
    sortTableBy({ by: property, order: ord });
  };

  function updateSummary() {
    let sal = 0;
    let valu = 0;
    let ord = 0;
    data.length > 0 &&
      data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach((row: SalesByCountryRow) => {
          sal += row.sales;
          valu += row.value;
          ord += row.orders;
        });
    setSales(sal);
    setValue(valu);
    setOrders(ord);
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <TableContainer
        className={classes.tableContainer}
        sx={{ position: 'relative' }}
      >
        <CardTableStatus
          isLoading={state === APIStatus.PENDING}
          isError={error !== undefined}
          isNoResult={state === APIStatus.FULFILLED && data.length === 0}
        />
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((header: any) => (
                <TableCell key={header.id_h} align={header.align}>
                  <TableSortLabel
                    sx={{ boxShadow: 0 }}
                    active={orderBy === header.id_h}
                    direction={orderBy === header.id_h ? order : 'asc'}
                    onClick={() => handleRequestSort(header.id_h)}
                  >
                    <span>{header.label}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 && (
              <TableRowSummary
                startEmptyCells={0}
                endEmptyCells={0}
                cells={[
                  {
                    value2: data.map((r) => r.sales).reduce((p, n) => p + n),
                    value: sales,
                    type: 'integer',
                    align: 'center',
                    paddingRight: '8px',
                    calculation: 'sum'
                  },
                  {
                    value2: data.map((r) => r.value).reduce((p, n) => p + n),
                    value,
                    type: 'currency',
                    align: 'center',
                    paddingRight: '8px',
                    calculation: 'sum'
                  }
                ]}
              />
            )}
            {data.length > 0 &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <TableRow
                    hover
                    key={`${key}-${row.sales}`}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <Grid container direction="row">
                        <CountryFlag widthPx={28} code={row.country.code} />
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, paddingLeft: 2 }}
                        >
                          {row.country.name}
                        </Typography>
                      </Grid>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, paddingRight: 3 }}
                      >
                        {row.sales}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {new Intl.NumberFormat(currentLang.value, {
                          style: 'currency',
                          currency: iso
                        }).format(row.value)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 &&
              [...Array(emptyRows)].map((_, key) => (
                <TableRow key={key} className={classes.tableRow} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
