import { alpha, Box, makeStyles, Theme } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

type TendingIconProps = {
  tending: number;
  inverse?: boolean;
  color?: string;
};

export function TendingIcon({
  tending,
  inverse = false,
  color
}: TendingIconProps) {
  const theme: any = useTheme();

  const icon =
    tending === 0
      ? 'eva:arrow-forward-fill'
      : tending > 0
      ? 'eva:trending-up-fill'
      : 'eva:trending-down-fill';
  const iconColor: string = useMemo(() => {
    switch (true) {
      case tending < 0:
        return theme.palette.error.main;
      case tending === 0:
        return theme.palette.warning.main;
      default:
        return theme.palette.success.main;
    }
  }, [tending]);

  const classes = useStyles({ color: iconColor });

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: alpha(iconColor, 0.16) }}
    >
      <Icon width={16} height={16} icon={icon} color={iconColor} />
    </Box>
  );
}
