import { useDispatch, useSelector } from 'react-redux';
import { AreaChartFiltersState } from '../@types/filterBars';

import { RootState } from '../redux/rootReducer';
import { fetchPriceChanges } from '../redux/slices/priceChanges';

// ----------------------------------------------------------------------

function usePriceChanges() {
  const dispatch = useDispatch();
  const { data, filters, APIStatus, error } = useSelector(
    (state: RootState) => state.PriceChanges
  );

  return {
    data,
    filters,
    APIStatus,
    error,
    getPriceChanges: (articleId?: number) => {
      dispatch(fetchPriceChanges(filters, articleId));
    },
    updatePriceChanges: (
      updatedFilters: AreaChartFiltersState,
      articleId?: number
    ) => {
      dispatch(fetchPriceChanges(updatedFilters, articleId));
    }
  };
}

export default usePriceChanges;
