import { Divider, Typography, Box, useTheme, Theme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

type Props = {};

function SummaryFirstCell({}: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: '180px',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
    >
      <Typography
        variant="body1"
        textAlign="start"
        fontWeight={700}
        color={theme.palette.text.primary}
        sx={{ paddingRight: '4px', height: '26px' }}
      >
        {translate('summary')}
      </Typography>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          width: '80px'
        }}
      >
        <Typography
          variant="body1"
          textAlign="start"
          fontWeight={700}
          color={theme.palette.text.primary}
        >
          {translate('current_page')}
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <Typography
          variant="body1"
          textAlign="start"
          fontWeight={700}
          color="text.secondary"
        >
          {translate('total')}
        </Typography>
      </Box>
    </Box>
  );
}

export default SummaryFirstCell;
