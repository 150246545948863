// import { Grid, Box, LinearProgress } from "@mui/material";
import { Box, LinearProgress, Typography } from '@material-ui/core';
import useLocales from '../../hooks/useLocales';
import { CountryFlag } from '../general/CountryFlag';
// import countries from '../../utils/countries.json';

type CountryCellProps = {
  percent: number;
  code: string;
  name: string;
  marginLeft?: number;
};

export default function CountryCell({
  percent,
  code,
  name,
  marginLeft = 3
}: CountryCellProps) {
  const { currentLang } = useLocales();
  const cPercent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  return (
    <Box
      sx={{
        minWidth: '220px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      {code === 'XX' ? (
        <Box sx={{ width: '28px' }} />
      ) : (
        <CountryFlag widthPx={28} code={code} />
      )}
      <Typography sx={{ marginLeft, width: '90px' }} variant="body2">
        {name}
      </Typography>
      <Box
        sx={{
          marginLeft: 1,
          width: 110,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{ textAlign: 'start', color: 'text.secondary' }}
          variant="subtitle2"
        >
          {cPercent.format(percent / 100)}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={Number(percent)} />
        </Box>
      </Box>
    </Box>
  );
}
