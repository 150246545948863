import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProfitFilterState } from '../../@types/filterBars';
import { ProfitState } from '../../@types/reduxStates';
import { ProfitReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: ProfitState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    invoice: 0
  },
  data: {
    orders: 0,
    grossVolume: 0,
    netVolume: 0,
    discount: 0,
    cancellations: 0,
    returns: 0,
    shippingCosts: 0,
    buildingCosts: 0,
    storageCosts: 0,
    paymentFees: 0,
    advertisingCosts: 0,
    otherFees1: 0,
    otherFees2: 0,
    marketPlaceFees: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'profit',
  initialState,
  reducers: {
    setFilters: (state: any, action: PayloadAction<ProfitFilterState>) => {
      state.filters = action.payload;
    },
    getProfit: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getProfitSuccess: (state: any, action: PayloadAction<ProfitReponse>) => {
      state.data = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getProfitError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchProfit(filters: ProfitFilterState, articleId: string) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getProfit());
    try {
      const params = {
        idArticle: articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        invoice: filters.invoice > 0 ? filters.invoice : undefined
      };
      const response = await axios.get('/api/v2/article/profit', {
        params
      });
      dispatch(getProfitSuccess(response.data));
    } catch (error) {
      dispatch(getProfitError(error as APIError));
    }
  };
}

export const { setFilters, getProfit, getProfitError, getProfitSuccess } =
  slice.actions;

export default slice.reducer;
