import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { AreaChartFiltersState } from '../@types/filterBars';
import { RootState } from '../redux/rootReducer';
import { fetchProductHealth } from '../redux/slices/productHealth';

// ----------------------------------------------------------------------

function useProductHealth() {
  const dispatch = useDispatch();
  const { response, filters, APIStatus, error } = useSelector(
    (state: RootState) => state.productHealth
  );
  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getProductHealth = (
    articleId: number,
    updatedFilters: AreaChartFiltersState
  ) => {
    cancelFetch();
    dispatch(
      fetchProductHealth(updatedFilters, abortController.current, articleId)
    );
  };

  return {
    response,
    filters,
    APIStatus,
    error,
    cancelFetch,
    getProductHealth
  };
}

export default useProductHealth;
