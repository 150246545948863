import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { APIStatus } from '../../@types/APIStatus';
import useLocales from '../../hooks/useLocales';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import PricesHistoryChart from '../charts/PricesHistoryChart';
import { CardError } from '../general/CardError';
import { CardLoading } from '../general/CardLoading';
import { CardNoResult } from '../general/CardNoResult';
import TableRowSummary from '../general/TableRowSummary';
import { textStyles } from '../../theme/TextClasses';
import useDashboardTab from '../../hooks/useDashboardTab';
import { divideIfNotZero } from '../../utils/helper';
import useAuth from '../../hooks/useAuth';

type PriceBySourceTableProps = {
  page: number;
  rowsPerPage: number;
};

export function PricesBySourceTable({
  page,
  rowsPerPage
}: PriceBySourceTableProps) {
  const HEIGHT_ROW = 100;
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const classes = TableStyleClasses({
    height: HEIGHT_ROW,
    numRows: rowsPerPage
  });

  const theme: any = useTheme();

  const {
    data: {
      priceList: { APIStatus: state, data, error }
    },
    APIStatus: tabState,
    error: tabError,
    sortPriceList
  } = useDashboardTab();

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('orders');
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const [emptyRows, setEmptyRows] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [activeMarkets, setActiveMarkets] = useState<number>(0);

  const textClasses = textStyles({ lines: 1 });

  const TABLE_HEAD = [
    { id_h: 'priceName', label: translate('price_name'), align: 'left' },
    { id_h: 'history', label: translate('price_history'), align: 'center' },
    { id_h: 'price', label: translate('price'), align: 'right' }
  ];

  useEffect(() => {
    if (data.length > 0) {
      let vRows = 0;
      if (Math.floor(data.length / rowsPerPage) === page) {
        vRows = data.length % rowsPerPage;
        setEmptyRows(rowsPerPage - vRows);
      } else {
        vRows = rowsPerPage;
        setEmptyRows(0);
      }
      makeSummary(vRows);
    }
  }, [data, page, orderBy]);

  function makeSummary(visibleRows: number) {
    let pri = 0;
    const am = new Set<number>([]);
    data.length > 0 &&
      data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach((row) => {
          pri +=
            row.prices.map((d) => d.y).reduce((a, b) => a + b) /
            row.prices.length;
          // row.prices.forEach((price) => am.add(Number(aMarket.marketId)));
        });
    const pric = divideIfNotZero(pri, visibleRows);
    setPrice(pric);
    setActiveMarkets(am.size);
  }

  const handleRequestSort = (property: string) => {
    let ord: 'asc' | 'desc' = 'desc';
    if (orderBy === property) {
      if (order === 'asc') {
        ord = 'desc';
      } else if (order === 'desc') {
        ord = 'asc';
      }
    }
    setOrder(ord);
    setOrderBy(property);
    sortPriceList(property, ord);
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      {state === APIStatus.FULFILLED && data.length === 0 && !error && (
        <CardNoResult />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((header: any) => (
                <TableCell
                  key={header.id_h}
                  align={header.align}
                  className={
                    header.id_h === 'price'
                      ? classes.tableCellNoPaddingVertical
                      : ''
                  }
                >
                  <TableSortLabel
                    sx={{ boxShadow: 0 }}
                    active={orderBy === header.id_h}
                    direction={orderBy === header.id_h ? order : 'asc'}
                    onClick={() => handleRequestSort(header.id_h)}
                  >
                    {header.id_h === 'price' && (
                      <Box sx={{ paddingLeft: '8px', paddingTop: '8px' }}>
                        <Icon
                          width={16}
                          height={16}
                          icon="mdi:diameter-variant"
                          color={theme.palette.text.main}
                        />
                      </Box>
                    )}
                    <span>{header.label}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* SUMARY ROW */}
            {data && data.length > 0 && (
              <TableRowSummary
                startEmptyCells={1}
                endEmptyCells={0}
                cells={[
                  {
                    value2: price,
                    value: price,
                    type: 'currency',
                    align: 'center',
                    paddingRight: '8px',
                    calculation: 'average'
                  }
                ]}
              />
            )}
            {/* DATA */}
            {data &&
              data.length > 0 &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i: number) => (
                  <TableRow key={i} className={classes.tableRow}>
                    <TableCell sx={{ textAlign: 'start', padding: 0 }}>
                      <Typography
                        // sx={{ paddingRight: 2 }}
                        className={textClasses.ellipsis}
                        variant="body1"
                        color="text.primary"
                        fontWeight={500}
                        textAlign="left"
                      >
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: 'center', padding: 0 }}>
                      <Box sx={{ height: `${HEIGHT_ROW}px` }}>
                        <PricesHistoryChart
                          name={row.name}
                          data={row.prices}
                          key={`${i}`}
                        />
                      </Box>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontWeight={500}
                        textAlign="end"
                      >
                        {new Intl.NumberFormat(currentLang.value, {
                          style: 'currency',
                          currency: iso
                        }).format(
                          row.prices.map((d) => d.y).reduce((a, b) => a + b) /
                            row.prices.length
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}

            {emptyRows > 0 &&
              [...Array(emptyRows)].map((_, key) => (
                <TableRow key={key} className={classes.tableRow} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
