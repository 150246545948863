import {
  Box,
  List,
  ListSubheader,
  styled,
  Typography
} from '@material-ui/core';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Scrollbar from '../../components/Scrollbar';
import { FullLogo } from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import { TNavItem } from '../../@types/menu';
import useMenu from '../../hooks/useMenu';
import SidebarItem from './SidebarItem';
// import SvgIconStyle from '../../components/SvgIconStyle';
// import SidebarIcon from '../../components/general/SidebarIcon';

import ic_dashboard from '../../lotties/ic_dashboard.json';
import ic_elements from '../../lotties/ic_elements.json';
import ic_cart from '../../lotties/ic_cart.json';
import ic_ecommerce from '../../lotties/ic_ecommerce.json';
import ic_charts from '../../lotties/ic_charts.json';
import ic_components from '../../lotties/ic_components.json';
import ic_user from '../../lotties/ic_user.json';
import ic_chat from '../../lotties/ic_chat.json';
import ic_sources from '../../lotties/ic_sources.json';
import ic_profit from '../../lotties/ic_profit.json';
import ic_integrations from '../../lotties/ic_integrations.json';
import ic_audience from '../../lotties/ic_audience.json';
import ic_activity_stream from '../../lotties/ic_activity_stream.json';
import ic_recommendations from '../../lotties/ic_recommendations.json';
import ic_user_management from '../../lotties/ic_user_management.json';
import ic_kpi_center from '../../lotties/ic_kpi_center.json';
import ic_products from '../../lotties/ic_products.json';
import ic_api_status from '../../lotties/ic_api_status.json';
import ic_stock_management from '../../lotties/ic_stock_management.json';
import ic_edi_acs from '../../lotties/ic_edi_acs.json';
import ic_log_files from '../../lotties/ic_log_files.json';
import axios from '../../utils/axios';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

type Props = {
  itemExpanded: string;
  onExpand: (itemTitleExpanded: string) => void;
};

function DashboardSidebarContent({ onExpand, itemExpanded }: Props) {
  const { user } = useAuth();
  const { t: translate } = useTranslation(['menu']);
  const { menu } = useMenu();
  const { pathname } = useLocation();
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    getCompanyName();
  }, []);

  const getCompanyName = () => {
    axios
      .get('/api/v1/displayCompany')
      .then((res) => {
        setCompanyName(
          user.role === 'Admin' && user.email === 'dev@digitalhub-h.de'
            ? 'Demo Account'
            : res.data.name
        );
      })
      .catch((err) => console.error(err));
  };

  const matchURL = (itemPath: string): boolean =>
    Boolean(
      matchPath(pathname, {
        path: itemPath,
        exact: false
      })
    );

  const getIcon = (iconString: string = 'ic_dashboard'): any => {
    switch (iconString) {
      case 'ic_dashboard':
        return ic_dashboard;
      case 'ic_elements':
        return ic_elements;
      case 'ic_cart':
        return ic_cart;
      case 'ic_ecommerce':
        return ic_ecommerce;
      case 'ic_activity_stream':
        return ic_activity_stream;
      case 'ic_charts':
        return ic_charts;
      case 'ic_components':
        return ic_components;
      case 'ic_user':
        return ic_user;
      case 'ic_chat':
        return ic_chat;
      case 'ic_sources':
        return ic_sources;
      case 'ic_profit':
        return ic_profit;
      case 'ic_integrations':
        return ic_integrations;
      case 'ic_audience':
        return ic_audience;
      case 'ic_recommendations':
        return ic_recommendations;
      case 'ic_kpi_center':
        return ic_kpi_center;
      case 'ic_user_management':
        return ic_user_management;
      case 'ic_products':
        return ic_products;
      case 'ic_api_status':
        return ic_api_status;
      case 'ic_stock_management':
        return ic_stock_management;
      case 'ic_edi_acs':
        return ic_edi_acs;
      case 'ic_log_files':
        return ic_log_files;
      default:
        return ic_dashboard;
    }
  };

  return (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <FullLogo />
        </RouterLink>
      </Box>

      <AccountStyle>
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {companyName}
          </Typography>
        </Box>
      </AccountStyle>

      {menu.map((list: TNavItem) => (
        <List
          disablePadding
          key={list.title}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: 'text.primary',
                typography: 'overline'
              }}
            >
              {translate(`menu:${list.title}`)}
            </ListSubheader>
          }
        >
          <List disablePadding>
            {list.items &&
              list.items.map((e) => (
                <SidebarItem
                  active={e.active === 1}
                  key={e.href}
                  level={0}
                  href={`/page/${e.href}`}
                  icon={getIcon(e.icon)}
                  // info={e.info}
                  title={translate(e.title)}
                  itemExpanded={itemExpanded}
                  onExpand={onExpand}
                  isOpen={matchURL(`/page/${e.href}`)}
                >
                  {e.items && e.items.length > 0 && (
                    <List disablePadding>
                      {e.items.map((se) => (
                        <SidebarItem
                          active={e.active === 1}
                          key={`${se.title} 1`}
                          level={1}
                          icon={getIcon(se.icon)}
                          // info={se.info}
                          href={`/page/${se.href}`}
                          title={translate(se.title)}
                          itemExpanded={itemExpanded}
                          onExpand={onExpand}
                        />
                      ))}
                    </List>
                  )}
                </SidebarItem>
              ))}
          </List>
        </List>
      ))}
    </Scrollbar>
  );
}

export default DashboardSidebarContent;
