import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ArticleDetailFilterState } from '../../@types/filterBars';
import { ArticleDetailState } from '../../@types/reduxStates';
import { ArticleDetailResponse } from '../../@types/responsesAPI';
import { DateRangeFilter } from '../../@types/filters';

const initialState: ArticleDetailState = {
  response: {
    articleExtId: 0,
    articleId: 0,
    cover: '',
    coverSmall: '',
    images: [],
    name: '',
    ean: '',
    asin: '',
    articleVariationId: '',
    sku: [],
    variants: 0,
    stock: 0,
    stockLabel: '',
    currentPurchasePrice: 0,
    isBundle: false,
    averagePrice: {
      average: 0,
      averageChart: [],
      averageBuying: 0,
      averageBuyingChart: []
    },
    profitSummary: {
      totalIncome: 0,
      totalExpenses: 0,
      totalProfit: 0
    },
    stockRange: {
      byableStock: 0,
      daysLeft: 0,
      noHistory: true
    },
    charts: [],
    revenueAndOrdersData: {
      chartTitle: '',
      days: [
        {
          date: '0',
          costs: 0,
          margin: 0,
          orders: 0,
          returns: 0,
          returnsValue: 0,
          revenue: 0
        }
      ]
    },
    returns: {
      qty: 0,
      percent: 0
    },
    articleNumber: '',
    orders: 0,
    revenue: 0,
    ordersPrev: 0,
    revenuePrev: 0,
    ordersTending: 0,
    revenueTending: 0
  },
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'articlesDetails',
  initialState,
  reducers: {
    setArticle(
      state: ArticleDetailState,
      action: PayloadAction<ArticleDetailResponse>
    ) {
      state.response = action.payload;
    },
    setFilters: (
      state: ArticleDetailState,
      action: PayloadAction<ArticleDetailFilterState>
    ) => {
      state.filters = action.payload;
    },
    getArticle: (state: ArticleDetailState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getArticleSuccess: (
      state: ArticleDetailState,
      action: PayloadAction<ArticleDetailResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getArticleError: (
      state: ArticleDetailState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchArticle(
  idArticle: string,
  timePeriod: DateRangeFilter,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    // const fullQuery = `/rest/v1/dashboard/article/${id}?period=${period}`;
    dispatch(setFilters({ timePeriod }));
    dispatch(getArticle());
    try {
      const params = {
        from: timePeriod.startFilter,
        till: timePeriod.endFilter
      };
      const response = await axios.get(
        `/api/v2/dashboard/article/${idArticle}`,
        {
          signal: abortController.signal,
          params
        }
      );
      dispatch(getArticleSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getArticleError(error as APIError));
    }
  };
}

export default slice.reducer;

export const {
  setArticle,
  getArticle,
  getArticleSuccess,
  getArticleError,
  setFilters
} = slice.actions;
