import { useTheme } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useLocales from '../../hooks/useLocales';

type Props = {
  name?: string;
  data?: Array<{ x: number; y: number }>;
};

function PricesHistoryChart({ name = 'No name', data = [] }: Props) {
  const theme = useTheme();
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const [average, setAverage] = useState<number>(0);

  useEffect(() => {
    if (data.length > 0) {
      setAverage(data.map((d) => d.y).reduce((a, b) => a + b) / data.length);
    }
  }, [data]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: [theme.palette.primary.main],
    dataLabels: {
      enabled: false
    },
    /* noData: {
        text: translate('no_data')
      }, */
    stroke: {
      colors: ['#1f2fa0'],
      width: 3,
      curve: 'straight'
    },
    grid: {
      show: false
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: (value: any) =>
          new Intl.DateTimeFormat(currentLang.value, {
            year: 'numeric',
            month: 'short'
          }).format(value)
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    markers: {},
    annotations: {
      yaxis: [
        {
          y: average ?? 0,
          borderColor: 'red',
          strokeDashArray: 0
        }
      ],
      points: [
        {
          x: data && data.length > 0 ? data[0].x : 0,
          y: data && data.length > 0 ? data[0].y : 0,
          marker: {
            size: 4,
            fillColor: '#fff',
            strokeColor: '#1f2fa0',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          }
        },
        {
          x: data && data.length > 0 ? data[data.length - 1].x : 0,
          y: data && data.length > 0 ? data[data.length - 1].y : 0,
          marker: {
            size: 4,
            fillColor: '#fff',
            strokeColor: '#1f2fa0',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          }
        }
      ]
    },
    yaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: true,
      x: {
        show: false
      },
      y: {
        title: {
          formatter(seriesName: string) {
            return '';
          }
        },
        formatter(y: number) {
          return new Intl.NumberFormat(currentLang.value, {
            style: 'currency',
            currency: 'EUR'
          }).format(Number(y));
        }
      }
    }
  };

  return (
    <ReactApexChart
      options={options}
      series={[{ name, data }]}
      type="line"
      height="100%"
      width="100%"
    />
  );
}

export default PricesHistoryChart;
