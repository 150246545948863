import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { stringToNumber } from '../../../utils/helper';

import Orders from '../../orders/Orders';
import RevenueByChannel from '../../revenuelist/RevenueByChannel';
import RevenueByCountry from '../../revenuelist/RevenueByCountry';

export function OrdersTab() {
  const { id: idArticle } = useParams<{ id?: string }>();
  const {
    article: { articleId, name }
  } = useArticlesDetail();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RevenueByCountry articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12}>
        <RevenueByChannel articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12}>
        <Orders tab="1" articleId={`${articleId}`} title={name} />
      </Grid>
    </Grid>
  );
}
