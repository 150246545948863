import {
  alpha,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from '@material-ui/core';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: `1px solid ${alpha('#919EAB', 0.24)}`
  }
}));
