import { Box, makeStyles, Typography } from '@material-ui/core';
import useLocales from '../../hooks/useLocales';
import { unknownToNumber } from '../../utils/helper';
import useAuth from '../../hooks/useAuth';

type Props = {
  title: string;
  value: number | string;
  isMobile: boolean;
  type?: 'currency' | 'qty';
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    paddingBottom: theme.spacing(2)
  },
  value: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    paddingBottom: theme.spacing(3)
  }
}));

export function HeaderBalance({ title, value, isMobile, type = 'qty' }: Props) {
  const classes = useStyles();
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: iso
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        <Typography fontWeight={500} variant={isMobile ? 'body1' : 'h6'}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.value}>
        <Typography fontWeight={700} variant={isMobile ? 'body1' : 'h6'}>
          {type === 'qty' && unknownToNumber(value)}
          {type === 'currency' && euro.format(unknownToNumber(value))}
        </Typography>
      </Box>
    </Box>
  );
}
