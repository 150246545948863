import { Typography } from '@material-ui/core';
import { VariantSize } from '../../@types/text';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';

type EuroProps = {
  euro: number;
  variantSize?: VariantSize;
};

function Euro({ euro, variantSize }: EuroProps) {
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  return (
    <Typography variant={variantSize} component="span">
      {new Intl.NumberFormat(currentLang.value, {
        style: 'currency',
        currency: iso
      }).format(euro)}
    </Typography>
  );
}

export default Euro;
