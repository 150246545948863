import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Box, FormHelperText, OutlinedInput } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// utils
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object: any) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(
      0,
      object.target.maxLength
    ));
  }
}

type InitialValues = {
  code: string;
  afterSubmit?: string;
};

type ValueNames = 'code';

export default function VerifyCodeForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { verifyCode, user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales();

  const VerifyCodeSchema = Yup.object().shape({
    code: Yup.number().required(translate('login:verification_code_required'))
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      code: ''
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await verifyCode({
          code: values.code,
          email: user.email
        });
        enqueueSnackbar(translate('login:verification_successful'), {
          variant: 'success'
        });
      } catch (error) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
    }
  });

  const {
    values,
    errors,
    isValid,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {Object.keys(values).map((item) => (
            <Box key={item} sx={{ mx: 1 }}>
              <OutlinedInput
                {...getFieldProps(item)}
                type="number"
                onInput={maxLength}
                error={Boolean(
                  touched[item as ValueNames] && errors[item as ValueNames]
                )}
                inputProps={{
                  maxLength: 6,
                  sx: {
                    padding: 0,
                    textAlign: 'center',
                    width: { xs: 216, sm: 336 },
                    height: { xs: 36, sm: 56 }
                  }
                }}
              />
            </Box>
          ))}
        </Box>

        <FormHelperText error={!isValid} style={{ textAlign: 'right' }}>
          {!isValid &&
            !errors.afterSubmit &&
            translate('login:verification_code_required')}
          {errors.afterSubmit && translate('login:verification_invalid')}
        </FormHelperText>

        <Box sx={{ mt: 3 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            pending={isSubmitting}
          >
            {translate('login:verification_button')}
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
