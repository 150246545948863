import { Icon } from '@iconify/react';
// import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
//
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import { useTranslation } from 'react-i18next';
import AccountPopover from './AccountPopover';
import CompanyChange from './CompanyChange';
import LanguagePopover from './LanguagePopover';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import { textStyles } from '../../theme/TextClasses';
import { MBreadcrumbs } from '../../components/@material-extend';
import useOrder from '../../hooks/useOrder';
import useLocales from '../../hooks/useLocales';
import { APIStatus } from '../../@types/APIStatus';
import usePurchaseDetail from '../../hooks/usePurchaseDetail';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  // border: '1px solid black',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
  onOpenSidebar
}: DashboardNavbarProps) {
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['menu', 'component']);
  const { pathname } = useLocation();
  const textClasses = textStyles({});
  const [links, setLinks] = useState<Array<{ name: string }>>([]);

  const {
    article: { name },
    APIStatus: articleStatus
  } = useArticlesDetail();
  const {
    order: { orderExtId },
    APIStatus: orderStatus
  } = useOrder();
  const {
    response: { orderExtId: purchaseId },
    APIStatus: purchaseStatus
  } = usePurchaseDetail();

  /* pathname
    .split('/')
    .filter((l) => l.length > 0)
    .map((link) => ({ name: capitalizeFirstLetter(link) })); */

  useEffect(() => {
    if (pathname.length > 0 && orderStatus !== APIStatus.PENDING) {
      const linksSplit = pathname
        .split('filters')[0]
        .split('/')
        .filter((l) => l.length > 0);
      if (linksSplit[0] === 'page') {
        // const bc = linksSplit.map((l) => ({ name: capitalizeFirstLetter(l) }));
        const bc = linksSplit.map((l) => ({ name: translate(l) }));
        setLinks(bc);
      } else if (linksSplit[0] === 'product') {
        if (articleStatus === APIStatus.PENDING) {
          setLinks([{ name: `${translate('component:loading')}...` }]);
        } else {
          setLinks([
            { name: translate('component:product') },
            ...(name && name.length > 0 ? [{ name }] : [])
          ]);
        }
      } else if (linksSplit[0] === 'order') {
        setLinks([
          { name: translate('orders') },
          ...(orderExtId > 0
            ? [{ name: `${translate('order_number')} ${orderExtId}` }]
            : [])
        ]);
      } else if (linksSplit[0] === 'purchase') {
        setLinks([
          { name: translate('component:purchase') },
          ...(purchaseId > 0
            ? [{ name: `${translate('component:order_number')} ${purchaseId}` }]
            : [])
        ]);
      }
    }
  }, [pathname, orderExtId, articleStatus, currentLang, purchaseId]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon="eva:menu-2-fill" />
          </IconButton>
        </Hidden>

        <Grid container>
          <Grid
            container
            item
            xs={12}
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {links.length > 0 ? links[links.length - 1].name : ''}
            </Typography>
            <MBreadcrumbs links={links} />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 }
            }
          }}
        >
          <LanguagePopover />
          <AccountPopover />
          <CompanyChange />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
