import {
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  Theme,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useOrder from '../../hooks/useOrder';
import useLocales from '../../hooks/useLocales';
import { useOrderStyles } from './styles';
import SidebarIcon from '../general/SidebarIcon';
import ic_cart from '../../lotties/ic_cart.json';
import HeaderWidget from './HeaderWidget';
import { fDateTime } from '../../utils/formatTime';
import { HeaderBalance } from './HeaderBalance';
import { HeaderBox } from './HeaderBox';
import useAuth from '../../hooks/useAuth';

type Props = {};

function Header({}: Props) {
  const { t: translate } = useTranslation(['menu', 'component', 'filter']);
  const classes = useOrderStyles();
  const { order } = useOrder();
  const { currentLang } = useLocales();
  const history = useHistory();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const label = () => {
    if (currentLang.value === 'de-DE') {
      if (order.orderStatusNameDE && order.orderStatusNameDE.length > 0) {
        return order.orderStatusNameDE.split(' - ')[0];
      }
      if (order.orderStatusNameEN && order.orderStatusNameEN.length > 0) {
        return order.orderStatusNameEN.split(' - ')[0];
      }
    } else if (currentLang.value === 'en-GB') {
      if (order.orderStatusNameEN && order.orderStatusNameEN.length > 0) {
        return order.orderStatusNameEN.split(' - ')[0];
      }
      if (order.orderStatusNameDE && order.orderStatusNameDE.length > 0) {
        return order.orderStatusNameDE.split(' - ')[0];
      }
    }
    return ' - ';
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Button variant="text" onClick={() => history.goBack()}>
            {translate('global:backLabel')}
          </Button>
        </Grid>
        <Paper variant="outlined" className={classes.container}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={5} md={3}>
              <SidebarIcon
                json={ic_cart}
                stop={true}
                width={isMobile ? 105 : 210}
                height={isMobile ? 105 : 210}
              />
            </Grid>
            <HeaderBox
              isMobile={isMobile}
              countryName={order.countryName}
              orderExtId={order.orderExtId}
              orderTime={order.orderTime}
            />

            <Grid item container justifyContent="flex-end" xs={12} md={3}>
              <Grid item xs={12} sm={6}>
                <HeaderBalance
                  isMobile={isMobile}
                  title={translate('component:article_qty')}
                  value={order.positionQtyTotal}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HeaderBalance
                  isMobile={isMobile}
                  type="currency"
                  title={translate('component:net_revenue')}
                  value={order.orderAmountNet}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HeaderBalance
                  isMobile={isMobile}
                  type="currency"
                  title={translate('component:revenue_brutto')}
                  value={order.orderAmount}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.widgetRoot}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                className={classes.widgetContainer}
              >
                <Grid item xs={12} sm={6} lg={3}>
                  <HeaderWidget title={translate('component:channel')}>
                    <Box className={`${classes.centerBox} column`}>
                      <Grid container spacing={2}>
                        <Grid item justifyContent="center" xs={12}>
                          <Typography
                            variant="body1"
                            fontWeight={700}
                            color="text.primary"
                            textAlign="center"
                          >
                            {order.sourceName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </HeaderWidget>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <HeaderWidget title={translate('component:actual_status')}>
                    <Box className={`${classes.centerBox} column`}>
                      <Typography
                        variant="body1"
                        fontWeight={700}
                        color="text.primary"
                        align="center"
                      >
                        {label()}
                      </Typography>
                    </Box>
                  </HeaderWidget>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <HeaderWidget title={translate('component:customer_class')}>
                    <Box className={`${classes.centerBox} column`}>
                      <Typography
                        variant="body1"
                        fontWeight={700}
                        color="text.primary"
                      >
                        {order.customerClassName || '.-.'}
                      </Typography>
                    </Box>
                  </HeaderWidget>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <HeaderWidget title={translate('component:invoicing')}>
                    <Box className={`${classes.centerBox} column`}>
                      {!order.orderHasInvoice && (
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          color="text.primary"
                        >
                          {translate('component:no_invoice')}
                        </Typography>
                      )}
                      {order.orderHasInvoice && (
                        <Grid container spacing={2}>
                          <Grid container item spacing={2} xs={6}>
                            <Grid item justifyContent="center" xs={12}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                textAlign="center"
                              >
                                {translate('component:created')}
                              </Typography>
                            </Grid>
                            <Grid item justifyContent="center" xs={12}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                textAlign="center"
                              >
                                {`${
                                  order.orderInvoiceDate &&
                                  order.orderInvoiceDate.length > 0
                                    ? fDateTime(order.orderInvoiceDate)
                                    : ''
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2} xs={6}>
                            <Grid item justifyContent="center" xs={12}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                textAlign="center"
                              >
                                {translate('filter:payment_type')}
                              </Typography>
                            </Grid>
                            <Grid item justifyContent="center" xs={12}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                textAlign="center"
                              >
                                {order.payMethodName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </HeaderWidget>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Header;
