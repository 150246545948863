import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  circularProgressContainer: {
    position: 'relative'
  },
  colorCircularProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: ({ color }) => color
  },
  greyCircularProgress: {
    color: theme.palette.divider
  },
  redCircularProgress: {
    color: '#cc3232'
  },
  textBlockLegend: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

type ProgressCircleProps = {
  centerLegend: React.ReactNode;
  value: number;
  size: number;
  invert: boolean;
};

export function ProgressCircle({
  centerLegend,
  value,
  size,
  invert = false
}: ProgressCircleProps) {
  const color = (value: number): string => {
    if (value <= 15) {
      return '#ff1c00';
    }
    if (value <= 35) {
      return '#ff7e00';
    }
    if (value <= 45) {
      return '#ffac00';
    }
    if (value <= 65) {
      return '#ffd600';
    }
    if (value <= 85) {
      return '#a5fb00';
    }
    return '#00a83a';
  };
  const classes = useStyles({ color: color(value) });

  return (
    <Box className={classes.circularProgressContainer}>
      <CircularProgress
        className={
          value > 0 ? classes.greyCircularProgress : classes.redCircularProgress
        }
        variant="determinate"
        value={100}
        size={size}
      />
      <CircularProgress
        className={classes.colorCircularProgress}
        variant="determinate"
        value={value}
        size={size}
      />
      <Box className={classes.textBlockLegend}>{centerLegend}</Box>
    </Box>
  );
}
