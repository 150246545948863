import { Theme, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APIStatus } from '../../@types/APIStatus';
import { SortBy } from '../../@types/filters';
import useBundle from '../../hooks/useBundle';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import BundleTable from './BundleTable';

type SummaryRow = {
  emptyRows: number;
};

type Props = {
  title?: string;
  articleId?: number;
};

function BundleProducts({ title = 'bundle_list', articleId = 0 }: Props) {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { t: translate } = useTranslation(['component']);
  const [sort, setSort] = useState<SortBy>({
    by: 'name',
    order: 'desc'
  });
  const {
    response: { count },
    APIStatus: state,
    getBundle
  } = useBundle();

  const [rowsPerPageOptions, setRowsPerPageOptions] = useState<Array<number>>([
    5, 10, 25
  ]);
  useEffect(() => {
    switch (true) {
      case count <= 5:
        setRowsPerPageOptions([5]);
        break;
      case count > 5 && count <= 10:
        setRowsPerPageOptions([5, 10]);
        break;
      case count > 10 && count <= 25:
        setRowsPerPageOptions([5, 10, 25]);
        break;
      case count > 25 && count <= 100:
        setRowsPerPageOptions([5, 10, 25, 100]);
        break;
      case count > 100 && count <= 250:
        setRowsPerPageOptions([5, 10, 25, 100, 250]);
        break;
      case count > 250:
        setRowsPerPageOptions([5, 10, 25, 100, 250, 500]);
        break;
    }
  }, [count]);

  useEffect(() => {
    getBundle(articleId);
  }, [articleId]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={translate(title)} />
          <CardContent>
            <BundleTable
              onSortChange={setSort}
              sortBy={sort}
              rowsPerPage={rowsPerPage}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={rowsPerPageOptions}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={(ev, p) => setPage(p)}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default BundleProducts;
