import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ActivityStreamFiltersState } from '../@types/filterBars';
import { SortBy } from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import {
  fetchActivityStream,
  sendEmailNotification,
  setEmailNotificationModalVisible,
  setLoadingState,
  setMessageReaded,
  setUidMessage,
  updateFilters
} from '../redux/slices/activityStream';
import useLocales from './useLocales';
import { APIStatus } from '../@types/APIStatus';
import { ArticlesCSVRow } from '../@types/tableRows';
import axiosInstance from '../utils/axios';

function useActivityStream() {
  const dispatch = useDispatch();
  const {
    response,
    filters,
    APIStatus: state,
    error,
    showEmailNotificationModal,
    uidMessage
  } = useSelector((state: RootState) => state.activityStream);
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);

  const [csvHeaders, setCsvHeaders] = useState<
    Array<{ label: string; key: string }>
  >([]);

  const [csvRows, setCsvRows] = useState<
    Array<{ [key: string]: string | number }>
  >([]);

  const tableHeaders = [
    { field: 'date', label: translate('date'), align: 'left' },
    { field: 'section', label: translate('section'), align: 'center' },
    { field: 'name', label: translate('name'), align: 'center' },
    { field: 'event', label: translate('event_type'), align: 'center' },
    { field: 'message', label: translate('event'), align: 'center' },
    { field: 'action', label: translate('action'), align: 'center' }
  ];

  const csvData = {
    basicHeader: [
      { label: translate('id'), key: 'articleId' },
      { label: translate('variationId'), key: 'variationId' },
      { label: translate('name'), key: 'articleName' },
      { label: translate('qty'), key: 'totalSales' },
      { label: translate('average_sold_price'), key: 'avgSoldPrice' },
      { label: translate('sku'), key: 'articleNumber' },
      { label: translate('ean'), key: 'articleEan' },
      { label: translate('asin'), key: 'articleAsin' },
      { label: `${translate('transport_costs')} (€)`, key: 'transportCosts' },
      { label: `${translate('storage_costs')} (€)`, key: 'storageCosts' },
      { label: `${translate('customs')} (€)`, key: 'customs' },
      { label: `${translate('operating_costs')} (€)`, key: 'operatingCosts' },
      { label: translate('event_uuid'), key: 'eventUuid' }
    ],
    returnsHeader: [
      { label: translate('variation_id'), key: 'variationId' },
      {
        label: `${translate('moving_average_price')} (€)`,
        key: 'movingAveragePrice'
      },
      { label: translate('units_sold'), key: 'orderQty' },
      { label: `${translate('revenue_netto')} (€)`, key: 'orderNetTotal' },
      {
        label: `${translate('revenue_brutto')} (€)`,
        key: 'orderGrossTotal'
      },
      {
        label: `${translate('order_purchase_total')} (€)`,
        key: 'orderPurchaseTotal'
      },
      { label: translate('returns_rate'), key: 'returnPercent' },
      { label: translate('returns'), key: 'returnQty' },
      {
        label: `${translate('return_net_total')} (€)`,
        key: 'returnNetTotal'
      },
      {
        label: `${translate('return_gross_total')} (€)`,
        key: 'returnGrossTotal'
      },
      {
        label: `${translate('return_purchase_total')} (€)`,
        key: 'returnPurchaseTotal'
      }
    ],
    profitHeader: [
      { label: translate('variation_id'), key: 'variationId' },
      { label: translate('order_status_id'), key: 'orderStatusId' },
      { label: translate('units_sold'), key: 'unitsSold' },
      { label: translate('cancellations'), key: 'cancellations' },
      { label: translate('units_return'), key: 'unitsReturn' },
      { label: `${translate('payment_fees')} (€)`, key: 'paymentFees' },
      {
        label: `${translate('advertising_costs')} (€)`,
        key: 'advertisingCosts'
      },
      {
        label: `${translate('marketplace_fees')} (€)`,
        key: 'marketplaceFees'
      },
      { label: `${translate('revenue_brutto')} (€)`, key: 'revenueBrutto' },
      { label: `${translate('revenue_netto')} (€)`, key: 'revenueNetto' },
      { label: `${translate('discount')} (€)`, key: 'discount' },
      {
        label: `${translate('net_after_cancellations')} (€)`,
        key: 'netAfterCancellations'
      },
      { label: translate('returns'), key: 'returns' },
      { label: translate('returns_rate'), key: 'returnsRate' },
      { label: `${translate('shipping_costs')} (€)`, key: 'shippingCosts' },
      { label: `${translate('credit_payout')} (€)`, key: 'creditPayout' },
      {
        label: `${translate('net_after_credit_payout')} (€)`,
        key: 'netAfterCreditPayout'
      },
      {
        label: `${translate('brutto_after_credit_payout')} (€)`,
        key: 'bruttoAfterCreditPayout'
      },
      { label: `${translate('product_costs')} (€)`, key: 'productCosts' },
      { label: `${translate('db1')} (€)`, key: 'db1' },
      { label: translate('db1_rate'), key: 'db1Rate' },
      { label: `${translate('db2')} (€)`, key: 'db2' },
      { label: translate('db2_rate'), key: 'db2Rate' },
      { label: `${translate('db3')} (€)`, key: 'db3' },
      { label: translate('db3_rate'), key: 'db3Rate' }
    ],
    stockHeader: [
      { label: translate('stock'), key: 'psyStock' },
      {
        label: `${translate('moving_average_price')} (€)`,
        key: 'movingAveragePrice'
      },
      { label: translate('sourceId'), key: 'sourceId' },
      { label: translate('source'), key: 'sourceName' }
    ],
    stockRangeHeader: [
      { label: translate('stock'), key: 'psyStock' },
      { label: `${translate('stock_range')}`, key: 'stockRange' }
    ],
    sourceHeader: [{ label: translate('source'), key: 'sourceName' }]
  };

  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });

  function mergeCsvHeadersByEvent(event: number) {
    switch (event) {
      case 1: {
        return [...csvData.basicHeader, ...csvData.returnsHeader];
      }
      case 2: {
        return [...csvData.basicHeader, ...csvData.profitHeader];
      }
      case 3: {
        return [...csvData.basicHeader, ...csvData.stockHeader];
      }
      case 6: {
        return [...csvData.basicHeader, ...csvData.stockRangeHeader];
      }
      default: {
        return [...csvData.basicHeader];
      }
    }
  }

  const formatArticlesCSV = (
    articles: Array<ArticlesCSVRow>,
    event: number,
    section: number
  ) => {
    let headers = mergeCsvHeadersByEvent(event);
    if (section === 1) {
      headers = [...headers, ...csvData.sourceHeader];
    }
    const formatted = articles.map((article) => {
      const fRow: { [key: string]: string | number } = {};
      let k: keyof typeof article;
      for (k in article) {
        if (Object.prototype.hasOwnProperty.call(article, k)) {
          switch (k) {
            case 'articleId':
            case 'variationId':
            case 'unitsSold':
            case 'orderStatusId':
            case 'unitsReturn':
            case 'returns':
            case 'returnQty':
            case 'psyStock':
            case 'stockRange':
            case 'orderQty': {
              fRow[k] = `${Number(article[k] ?? '0')}`;
              break;
            }
            case 'cancellations':
            case 'movingAveragePrice':
            case 'transportCosts':
            case 'storageCosts':
            case 'customs':
            case 'operatingCosts':
            case 'orderNetTotal':
            case 'orderGrossTotal':
            case 'orderPurchaseTotal':
            case 'returnNetTotal':
            case 'returnGrossTotal':
            case 'paymentFees':
            case 'advertisingCosts':
            case 'marketplaceFees':
            case 'revenueBrutto':
            case 'revenueNetto':
            case 'discount':
            case 'netAfterCancellations':
            case 'shippingCosts':
            case 'creditPayout':
            case 'netAfterCreditPayout':
            case 'bruttoAfterCreditPayout':
            case 'productCosts':
            case 'db1':
            case 'db2':
            case 'db3':
            case 'returnPurchaseTotal': {
              fRow[k] = `${Number(article[k] ?? '0').toFixed(2)}`;
              break;
            }
            case 'returnPercent':
            case 'db1Rate':
            case 'db2Rate':
            case 'db3Rate':
            case 'returnsRate': {
              fRow[k] = `${percent.format((article[k] ?? 0) / 100)}`;
              break;
            }
            default: {
              const t = `${article[k]}`;
              // fRow[k] = `=""${t.length > 0 ? t : '-'}""`;
              fRow[k] = `${t.length > 0 ? t : '-'}`;
              break;
            }
          }
        }
      }
      return fRow;
    });
    setCsvHeaders(headers);
    setCsvRows(formatted);
  };

  function fetchCSVArticles(selected: {
    uid: string;
    event: number;
    section: number;
  }) {
    dispatch(setLoadingState(APIStatus.PENDING));
    axiosInstance
      .get(`/api/v2/activityStream/articlesData/${selected.uid}`, {
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter
        }
      })
      .then((res) => {
        dispatch(setLoadingState(APIStatus.FULFILLED));
        if (res.status === 200) {
          formatArticlesCSV(res.data, selected.event, selected.section);
        }
      })
      .catch((err) => {
        dispatch(setLoadingState(APIStatus.REJECTED));
      });
  }

  return {
    csvRows,
    csvHeaders,
    fetchCSVArticles,
    tableHeaders,
    response,
    filters,
    state,
    error,
    showEmailNotificationModal,
    uidMessage,
    setFilters: (filters: ActivityStreamFiltersState) => {
      dispatch(updateFilters(filters));
    },
    getActivityStream: (
      filters: ActivityStreamFiltersState,
      pagination: Pagination,
      sortBy: SortBy,
      idArticle?: number
    ) => {
      dispatch(
        fetchActivityStream(
          filters,
          pagination,
          sortBy,
          idArticle,
          currentLang.value
        )
      );
    },
    setMessageReaded: (uid: string) =>
      dispatch(setMessageReaded(response.rows, uid)),
    sendEmailNotification: (email: string) =>
      dispatch(sendEmailNotification(uidMessage, email)),
    setEmailModalVisible: (show: boolean, uid: string) =>
      dispatch(setEmailNotificationModalVisible({ show, uid })),
    setMessageUid: (uidMessage: string) => dispatch(setUidMessage(uidMessage))
    // setLoadingState: (state: APIStatus) => dispatch(setLoadingState(state))
  };
}

export default useActivityStream;
