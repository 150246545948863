import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { store } from '../store';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { FiltersState } from '../../@types/reduxStates';
import {
  FiltersResponse,
  OnlineMarketingRanges
} from '../../@types/responsesAPI';
import { MultiselectItem, MultiselectTreeItem } from '../../@types/filters';

// ----------------------------------------------------------------------

const initialState: FiltersState = {
  response: {
    articles: {
      maxMargin: 0,
      maxReturns: 0,
      maxRevenue: 0,
      minMargin: 0,
      minReturns: 0,
      minRevenue: 0
    },
    orders: {
      maxMargin: 0,
      maxRevenue: 0,
      minMargin: 0,
      minRevenue: 0
    },
    orderStatus: [],
    countries: [],
    shops: [],
    sources: [],
    customers: [],
    categories: [],
    suppliers: [],
    warehouses: [],
    stockManagementTimePeriods: [],
    manufacturers: [],
    customConfigs: [],
    abc: [],
    campaigns: [],
    campaignTypes: [],
    campaignStatus: [],
    adGroups: [],
    onlineMarketingRanges: {
      maxDailyBudget: 100,
      minDailyBudget: 0,
      minClicks: 0,
      maxClicks: 10000,
      minConversions: 0,
      maxConversions: 100,
      minImpressions: 0,
      maxImpressions: 100000
    }
  },
  filtersStatus: {
    campaigns: APIStatus.IDLE,
    campaignTypes: APIStatus.IDLE,
    campaignStatus: APIStatus.IDLE,
    adGroups: APIStatus.IDLE,
    onlineMarketingRanges: APIStatus.IDLE
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // Campaigns filter
    getOnlineMarketingRanges: (state: FiltersState) => {
      state.filtersStatus.onlineMarketingRanges = APIStatus.PENDING;
      state.error = undefined;
    },
    getOnlineMarketingRangesSuccess: (
      state: FiltersState,
      action: PayloadAction<OnlineMarketingRanges>
    ) => {
      state.filtersStatus.onlineMarketingRanges = APIStatus.FULFILLED;
      state.response.onlineMarketingRanges = action.payload;
    },
    // Campaigns filter
    getCampaigns: (state: FiltersState) => {
      state.filtersStatus.campaigns = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaigns = APIStatus.FULFILLED;
      state.response.campaigns = action.payload;
    },
    // Campaign types filter
    getCampaignTypes: (state: FiltersState) => {
      state.filtersStatus.campaignTypes = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignTypesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaignTypes = APIStatus.FULFILLED;
      state.response.campaignTypes = action.payload;
    },
    // Campaign status filter
    getCampaignStatus: (state: FiltersState) => {
      state.filtersStatus.campaignStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignStatusSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaignStatus = APIStatus.FULFILLED;
      state.response.campaignStatus = action.payload;
    },
    // Ad groups filter
    getAdGroups: (state: FiltersState) => {
      state.filtersStatus.adGroups = APIStatus.PENDING;
      state.error = undefined;
    },
    getAdGroupsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectTreeItem>>
    ) => {
      state.filtersStatus.adGroups = APIStatus.FULFILLED;
      state.response.adGroups = action.payload;
    },
    // Other filters
    setFilters(state: FiltersState, action: PayloadAction<FiltersResponse>) {
      state.response = action.payload;
    },
    getFilters: (state: FiltersState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getFiltersSuccess: (
      state: FiltersState,
      action: PayloadAction<FiltersResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = {
        ...state.response,
        ...action.payload
      };
    },
    getFiltersError: (state: FiltersState, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchFilterValues() {
  return async (dispatch: any) => {
    try {
      dispatch(getFilters());
      const response = await axios.get(`/api/v2/filters`);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaigns() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaigns());
      const response = await axios.get('/api/v2/filters/campaigns');
      dispatch(getCampaignsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaignTypes() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaignTypes());
      const response = await axios.get('/api/v2/filters/campaignTypes');
      dispatch(getCampaignTypesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaignStatus() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaignStatus());
      const response = await axios.get('/api/v2/filters/campaignStatus');
      dispatch(getCampaignStatusSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchAdGroups() {
  return async (dispatch: any) => {
    try {
      dispatch(getAdGroups());
      const response = await axios.get('/api/v2/filters/adGroups');
      dispatch(getAdGroupsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchOnlineMarketingRanges() {
  return async (dispatch: any) => {
    try {
      dispatch(getOnlineMarketingRanges());
      const response = await axios.get('/api/v2/filters/onlineMarketingRanges');
      dispatch(getOnlineMarketingRangesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getFilters,
  getFiltersSuccess,
  getFiltersError,
  getCampaigns,
  getCampaignsSuccess,
  getCampaignTypes,
  getCampaignTypesSuccess,
  getCampaignStatus,
  getCampaignStatusSuccess,
  getAdGroups,
  getAdGroupsSuccess,
  getOnlineMarketingRanges,
  getOnlineMarketingRangesSuccess
} = slice.actions;
