import { makeStyles, Theme } from '@material-ui/core';

export const ShopSourcesClasses = makeStyles<Theme>((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: theme.palette.background.input
  }
}));

export const SelectTimePeriodClasses = makeStyles<
  Theme,
  { fillMaxWidth: boolean }
>((theme) => ({
  root: {
    fontFamily: 'Be Vietnam !important',
    // border: '1px solid blue',
    // minWidth: '220px'
    width: ({ fillMaxWidth }) => (fillMaxWidth ? '100%' : 'auto')
  },
  datepicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  },
  input: {
    backgroundColor: theme.palette.background.input
  },
  calendar: {
    [theme.breakpoints.up('xs')]: {
      '& .rdrCalendarWrapper': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .rdrCalendarWrapper': {
        display: 'inherit'
      }
    }
  }
}));
