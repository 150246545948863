import { SummaryCell } from '../components/general/TableRowSummary';

type Props = {
  articles: Array<any>;
};
// TODO: Merge with in useSummary
export function useOrderSummary({ articles }: Props) {
  const buyPriceNetto =
    articles.map((art) => art.purchasePrice).reduce((a, b) => a + b, 0) /
    articles.length;

  const soldPriceNetto =
    articles.map((art) => art.priceNet).reduce((a, b) => a + b, 0) /
    articles.length;
  const unitsTotal = articles.map((art) => art.qty).reduce((a, b) => a + b, 0);

  const amountTotalNeto = articles
    .map((art) => art.priceTotalNet)
    .reduce((a, b) => a + b, 0);

  const amountTotalBrutto = articles
    .map((art) => art.priceTotalGross)
    .reduce((a, b) => a + b, 0);
  const cells: Array<SummaryCell> = [
    {
      value: buyPriceNetto,
      value2: buyPriceNetto,
      align: 'center',
      paddingRight: '8px',
      calculation: 'average',
      type: 'currency'
    },
    {
      value: soldPriceNetto,
      value2: soldPriceNetto,
      align: 'center',
      paddingRight: '8px',
      calculation: 'average',
      type: 'currency'
    },
    {
      value: unitsTotal,
      value2: unitsTotal,
      align: 'center',
      paddingRight: '8px',
      calculation: 'sum'
    },
    {
      value: amountTotalNeto,
      value2: amountTotalNeto,
      align: 'center',
      paddingRight: '8px',
      calculation: 'sum',
      type: 'currency'
    },
    {
      value: amountTotalBrutto,
      value2: amountTotalBrutto,
      align: 'center',
      paddingRight: '8px',
      calculation: 'sum',
      type: 'currency'
    }
  ];

  return { cells };
}
