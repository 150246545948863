import { Grid } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import RevenueAndOrders from '../../charts/RevenueAndOrders';
import { SalesByCountry } from '../../sales/SalesByCountry';
import { Circles } from '../../circles/Circles';
import { PricesBySource } from '../../pricesbysource/PricesBySource';
import PriceChangesChart from '../../pricechanges/PriceChangesChart';
import { stringToNumber } from '../../../utils/helper';

export function DashboardTab() {
  const { id: idArticle } = useParams<{ id?: string }>();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RevenueAndOrders articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SalesByCountry articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Circles articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12}>
        <PriceChangesChart articleId={stringToNumber(idArticle)} />
      </Grid>
      <Grid item xs={12}>
        <PricesBySource articleId={stringToNumber(idArticle)} />
      </Grid>
    </Grid>
  );
}
