import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { PurchaseDetailState } from '../../@types/reduxStates';
import axios from '../../utils/axios';
import { PurchaseRow } from '../../@types/tableRows';

const initialState: PurchaseDetailState = {
  response: {
    orderId: 0,
    orderExtId: 0,
    supplier: 0,
    status: 0,
    orderDate: '',
    numberOfSku: 0,
    netProductValue: 0,
    orderedUnits: 0,
    receivedUnits: 0,
    estimatedTimeOfArrival: format(new Date(), 'yyyy-MM-dd'),
    actualArrivalTime: format(new Date(), 'yyyy-MM-dd'),
    stockLocation: '',
    delayDelivery: 0,
    actualStatus: '',
    shipCountry: ''
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'purchaseDetail',
  initialState,
  reducers: {
    getPurchaseDetail: (state: PurchaseDetailState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getPurchaseDetailSuccess: (
      state: PurchaseDetailState,
      action: PayloadAction<PurchaseRow>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getPurchaseDetailError: (
      state: PurchaseDetailState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchPurchaseDetail(purchaseId: string) {
  return async (dispatch: any) => {
    dispatch(getPurchaseDetail());
    try {
      const response = await axios.get(`/api/v1/purchaseDetail/${purchaseId}`);
      dispatch(getPurchaseDetailSuccess(response.data));
    } catch (error) {
      dispatch(getPurchaseDetailError(error as APIError));
    }
  };
}

export const {
  getPurchaseDetail,
  getPurchaseDetailError,
  getPurchaseDetailSuccess
} = slice.actions;

export default slice.reducer;
