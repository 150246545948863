import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { AreaChartFiltersState } from '../@types/filterBars';
import { RootState } from '../redux/rootReducer';
import { fetchRevenueAndOrders } from '../redux/slices/revenueAndOrders';

// ----------------------------------------------------------------------

function useRevenueAndOrders() {
  const dispatch = useDispatch();
  const { response, filters, APIStatus, error } = useSelector(
    (state: RootState) => state.revenueAndOrders
  );

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getRevenueAndOrders = (
    updatedFilters: AreaChartFiltersState,
    articleId: number
  ) => {
    cancelFetch();
    dispatch(
      fetchRevenueAndOrders(updatedFilters, abortController.current, articleId)
    );
  };

  return {
    response,
    filters,
    APIStatus,
    error,
    cancelFetch,
    getRevenueAndOrders
  };
}

export default useRevenueAndOrders;
