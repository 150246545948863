import { useTheme } from '@emotion/react';
import { Box, TablePagination, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PricesBySourceFilter from './PricesBySourceFilter';
import { PricesBySourceTable } from './PricesBySourceTable';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { PricesBySourceRow } from '../../@types/tableRows';
import { PricesBySourceFilterState } from '../../@types/filterBars';
import useDashboardTab from '../../hooks/useDashboardTab';
import { HeaderTableFilter } from '../../@types/filters';
import useArticlesDetail from '../../hooks/useArticlesDetail';

type Props = {
  title?: string;
  articleId?: number;
};

export function PricesBySource({
  title = 'global:rows_per_page',
  articleId = 0
}: Props) {
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translate } = useTranslation(['component']);
  const classesPagination = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const {
    data: {
      priceList: { APIStatus: state, data }
    },
    APIStatus: tabState
  } = useDashboardTab();

  const {
    article: { articleId: idArticle }
  } = useArticlesDetail();
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const { updatePriceList } = useDashboardTab();

  useEffect(() => {
    if (state === APIStatus.IDLE || articleId !== idArticle) {
      updatePriceList({ countryId: 0 }, articleId);
    }
  }, []);

  return (
    <PricesBySourceFilter>
      <PricesBySourceTable page={page} rowsPerPage={rowsPerPage} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <TablePagination
          component="div"
          className={classesPagination.root}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={!isMobile ? translate('global:rows_per_page') : ''}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
        />
      </Box>
    </PricesBySourceFilter>
  );
}
