import {
  Box,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProfitSummaryProps } from '../../../@types/products';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import useLocales from '../../../hooks/useLocales';
import { helperStyles } from '../../../theme/HelperClasses';
import { textStyles } from '../../../theme/TextClasses';
import { getPercentage } from '../../../utils/helper';

import { HeaderWidget } from './HeaderWidget';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles<Theme>((theme) => ({
  profitSummary: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  averagePrice: {
    width: '100%',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap'
    // border: '1px solid red'
  },
  title: {
    flex: 1.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tendingBlock: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  price: {
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  divider: {
    width: '100%'
  }
}));

export function ProfitSummary() {
  const {
    article: {
      profitSummary: { totalIncome, totalExpenses, totalProfit }
    }
  } = useArticlesDetail();
  const classes = useStyles();
  const textClasses = textStyles({});
  const helperClasses = helperStyles();
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  // const op: NumberFormatOptions

  return (
    <HeaderWidget
      title={`${translate('profit_summary')}`}
      content={
        <Box className={classes.profitSummary}>
          <Box className={helperClasses.row}>
            {/* //INCOME */}
            <Box className={`${helperClasses.cell} ${helperClasses.start}`}>
              <Typography variant="body1">
                {translate('total_income')}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              {/* <Typography
                variant="body1"
                align="right"
                fontWeight={700}
                sx={{
                  minWidth: '65px',
                  color:
                    totalIncomePercent > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main
                }}
              >
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'percent',
                  // @ts-ignore
                  signDisplay: 'exceptZero',
                  maximumFractionDigits: 1,
                  useGrouping: false
                }).format(totalIncomePercent / 100)}
              </Typography> */}
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              <Typography variant="body1">
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'currency',
                  currency: iso
                }).format(totalIncome)}
              </Typography>
            </Box>
          </Box>
          <Box className={helperClasses.row}>
            {/* //EXPENSES */}
            <Box className={`${helperClasses.cell} ${helperClasses.start}`}>
              <Typography variant="body1">
                {translate('total_expenses')}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              <Typography
                variant="body1"
                align="right"
                // className={classes.textPercent}
                fontWeight={500}
                sx={{
                  minWidth: '65px'
                  /* color:
                    totalExpensesPercent < 0
                      ? theme.palette.success.main
                      : theme.palette.error.main */
                }}
              >
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                  useGrouping: false
                  // @ts-ignore
                  // signDisplay: 'exceptZero'
                }).format(getPercentage(totalExpenses, totalIncome) / 100)}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              <Typography variant="body1">
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'currency',
                  currency: iso
                }).format(totalExpenses)}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={helperClasses.row}>
            {/* //PROFIT */}
            <Box className={`${helperClasses.cell} ${helperClasses.start}`}>
              <Typography variant="body1">
                {translate('total_profit')}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              <Typography
                variant="body1"
                align="right"
                // className={classes.textPercent}
                fontWeight={500}
                sx={{
                  minWidth: '65px'
                  /* color:
                    totalProfitPercent > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main */
                }}
              >
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                  useGrouping: false
                  // @ts-ignore
                  // signDisplay: 'exceptZero'
                }).format(getPercentage(totalProfit, totalIncome) / 100)}
              </Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
              <Typography variant="body1">
                {new Intl.NumberFormat(currentLang.value, {
                  style: 'currency',
                  currency: iso
                }).format(totalProfit)}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
