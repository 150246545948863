import { Grid, Card, CardHeader, CardContent, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useDashboardTab from '../../hooks/useDashboardTab';

type Props = {
  children: ReactNode;
  /* filters: PricesBySourceFilterState;
  onUpdate: (updatedFilters: PricesBySourceFilterState) => void; */
};

export default function PricesBySourceFilter({ children }: Props) {
  const { id } = useParams<{ id: string }>();
  const { t: translate } = useTranslation(['component']);
  /*  const {
    data: {
      priceList: { filters }
    },
    APIStatus: tabState,
    updatePriceList
  } = useDashboardTab();

  useEffect(() => {
    updatePriceList(
      {
        countryId: 0
      },
      id
    );
  }, []); */

  return (
    <Card sx={{ marginTop: '1px' }}>
      <CardHeader title={translate('selling_prices_by_source')} />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: 2
                  }}
                >
                  <Box sx={{ maxWidth: '230px', marginRight: 2 }}>
                    {/* <FilterSelect
                      selected={countrySelect}
                      title={translate('filter:country')}
                      labels={[
                        translate('filter:all_countries'),
                        ...countries.map((c) => c.country)
                      ]}
                      onChangeSelected={setCountrySelect}
                    /> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
