//
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// components
import Grid from '@material-ui/core/Grid';

import { TabContext, TabPanel } from '@material-ui/lab';
import useConfig from '../hooks/useConfig';
import Page from '../components/Page';
import { APIStatus } from '../@types/APIStatus';
import useOrder from '../hooks/useOrder';
import { useOrderStyles } from '../components/order/styles';
import Header from '../components/order/Header';
import { textStyles } from '../theme/TextClasses';
import OrderArticles from '../components/order/OrderArticles';
import OrderTimeline from '../components/order/OrderTimeline';

export default function Order() {
  const { id } = useParams<{ id: string }>();
  const classes = useOrderStyles();
  const textClasses = textStyles({});
  const tabsRef = useRef<HTMLDivElement>(null);
  const [tabSelected, setTabSelected] = useState<string>('0');

  const { APIStatus: state, getOrder, order, cancelFetch } = useOrder();

  const { config } = useConfig();
  const { t: translate } = useTranslation(['menu', 'component']);

  useEffect(() => {
    getOrder(id);
    return () => {
      cancelFetch();
    };
  }, []);

  if (state === APIStatus.REJECTED) {
    return <div>No order found</div>;
  }

  return (
    <Page title={`${config.company} - ${translate('order')}`}>
      <Container maxWidth="xl" sx={{ marginTop: '8px' }}>
        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Header />
          </Grid>

          <Grid container item xs={12}>
            <TabContext value={tabSelected}>
              <Box
                className={classes.boxContainer}
                component="div"
                ref={tabsRef}
              >
                <Tabs
                  className={classes.tabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  value={tabSelected}
                  onChange={(e, v) => setTabSelected(v)}
                  aria-label="Order detail tabs"
                >
                  <Tab
                    className={classes.tab}
                    label={translate('order')}
                    value="0"
                  />
                  <Tab
                    className={classes.tab}
                    label={translate('timeline')}
                    value="1"
                  />
                </Tabs>
              </Box>
              <Paper className={classes.tabContainer} variant="outlined">
                <Typography
                  variant="h5"
                  className={`${textClasses.ellipsis} ${classes.tabTitle}`}
                >
                  {order.orderExtId}
                </Typography>

                <TabPanel value="0">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <OrderArticles />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <OrderTimeline />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
