import {
  alpha,
  Box,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: theme.spacing(1),
    backgroundColor: alpha('#fff', 0.6),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    transition: 'background-color 0.3s'
  }
}));

type Props = {
  isLoading: boolean;
  isNoResult: boolean;
  isError: boolean;
  empty?: boolean;
};

function CardTableStatus({
  isLoading,
  isNoResult,
  isError,
  empty = false
}: Props) {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const { t: translate } = useTranslation(['component']);
  if (!isLoading && !isNoResult && !isError) {
    return null;
  }

  if ((isLoading || isNoResult || isError) && empty) {
    return <Box className={classes.root} />;
  }

  return (
    <Box className={classes.root}>
      {isLoading && <CircularProgress />}
      {isError && (
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ color: theme.palette.grey[500] }}
        >
          {translate('server_error')}
        </Typography>
      )}
      {isNoResult && !isError && (
        <Typography
          textAlign="center"
          variant="body1"
          sx={{ color: theme.palette.text.secondary }}
        >
          {translate('no_search_result')}
        </Typography>
      )}
    </Box>
  );
}

export default CardTableStatus;
