import { Box, Divider, Theme, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { helperStyles } from '../../../theme/HelperClasses';
import { AveragePriceChart } from '../../charts/AveragePriceChart';
import { HeaderWidget } from './HeaderWidget';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

export function AveragePrice() {
  const helperClasses = helperStyles();
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const theme: Theme = useTheme();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const {
    article: {
      currentPurchasePrice,
      averagePrice: { average, averageChart, averageBuying, averageBuyingChart }
    }
  } = useArticlesDetail();

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: iso
  });

  return (
    <HeaderWidget
      content={
        <Box className={`${helperClasses.widgetRoot} ${helperClasses.start}`}>
          <Box className={helperClasses.column}>
            <Box className={helperClasses.rowFlex}>
              <AveragePriceChart
                average={average}
                chart={averageChart}
                name={translate('average_price_index')}
              />
            </Box>
            <Divider className={helperClasses.maxWidth} />
            <Box className={helperClasses.rowFlex}>
              <AveragePriceChart
                average={averageBuying}
                chart={averageBuyingChart}
                name={translate('component:historical_buying_price')}
              />
            </Box>
            <Divider className={helperClasses.maxWidth} />
            <Box padding={1} width="100%" display="flex">
              <Box flex={0.7} display="flex" justifyContent="center">
                <Box display="inline-block">
                  <Typography
                    borderBottom={`2px dotted ${theme.palette.primary.main}`}
                    variant="body1"
                  >
                    {translate('component:current_buying_price')}
                  </Typography>
                </Box>
              </Box>

              <Box flex={0.3} paddingX={1}>
                <Typography variant="body1" textAlign="center">
                  {euro.format(currentPurchasePrice)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
