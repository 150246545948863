import { Theme, alpha, makeStyles } from '@material-ui/core';

export const useOrderStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(2)
    },
    borderRadius: 10,
    backgroundColor: '#fafafa'
  },
  widgetRoot: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  },
  widgetContainer: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: '0px',
      left: '0px'
    }
  },
  widget: {
    borderRadius: 10,
    backgroundColor: 'white',
    height: '185px'
  },
  imgPlaceholder: {
    width: 210,
    height: 210,
    [theme.breakpoints.down('md')]: {
      width: 105,
      height: 105
    },
    border: '1px solid green'
  },
  description: {
    backgroundColor: alpha('#6c7b88', 0.1),
    backdropFilter: 'blur(8px)',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  headerWidgetTitle: {
    borderBottom: `2px dotted ${theme.palette.primary.main}`
  },
  centerBox: {
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    height: 'calc(100% - 23px)',
    justifyContent: 'center',
    alignItems: 'center',
    '&.column': {
      flexDirection: 'column'
    }
  },
  boxContainer: {
    position: 'relative',
    bottom: '-1px',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(11)
    }
  },
  tabContainer: {
    width: '100%',
    backgroundColor: '#fafafa',
    padding: theme.spacing(3),
    paddingTop: 0
  },
  tabs: {
    height: 44,
    minHeight: 44,
    '& .MuiTabs-scrollableX ': {
      overflowX: 'auto !important'
    },
    '& .MuiTabs-indicator': {
      minHeight: 4,
      height: 4,
      bottom: -4,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: 0,
      '&::after': {
        position: 'absolute',
        left: '50%',
        width: 28,
        content: '" "',
        marginLeft: -14,
        borderRadius: 'inherit',
        height: '100%'
      }
    }
  },
  tab: {
    '&.MuiButtonBase-root': {
      height: 44,
      minHeight: 44,
      backgroundColor: '#eceeef',
      border: `1px solid ${theme.palette.divider}`,
      position: 'relative',
      borderRadius: '5px 5px 0px 0px',
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      '&::after': {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: 1,
        content: '" "',
        borderRadius: 'inherit'
      },
      '.MuiTouchRipple-root': {
        opacity: 0.1
      },
      '&:hover': {
        color: theme.palette.grey[800]
      },
      '&.Mui-selected': {
        zIndex: 1,
        color: theme.palette.grey[800],
        backgroundColor: '#fafafa',
        borderBottom: `1px solid #fafafa`,
        height: 46,
        '&::after': {
          height: 0
        }
      }
    }
  },
  tabTitle: {
    margin: theme.spacing(2, 0),
    color: theme.palette.text.primary
  }
}));
