import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  Paper,
  Typography,
  Theme
} from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { SortBy } from '../../@types/filters';
import { APIStatus } from '../../@types/APIStatus';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import { CardNoResult } from '../general/CardNoResult';
import { useWindowSize } from '../../hooks/useWindowSize';
import useActivityStream from '../../hooks/useActivityStream';
import Actions from './tableCells/Actions';
import YearRow from './tableCells/YearRow';
import Message from './tableCells/Message';
import MessageEvent from './tableCells/MessageEvent';
import CalendarDay from './tableCells/CalendarDay';
import { NavigateToParams } from '../../@types/menu';
import EventName from './tableCells/EventName';
import { ActivityStreamRow } from '../../@types/tableRows';

const useStyles = makeStyles<Theme>((theme) => ({
  hidden: {
    display: 'none'
  }
}));

type Props = {
  page: number;
  rowsPerPage: number;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
  // rows: Array<ActivityStreamRow>
  // onOpenEmailModal: (uid: string) => void;
};

export default function ActivityStreamTable({
  page,
  rowsPerPage,
  sortBy,
  onSortChange
}: // onOpenEmailModal
Props) {
  const { t: translate } = useTranslation(['component']);
  const history = useHistory();
  const { height } = useWindowSize();
  const classes = TableStyleClasses({
    height: 122,
    numRows: rowsPerPage,
    maxSize: height
  });
  const classesH = useStyles();

  const {
    csvRows,
    csvHeaders,
    fetchCSVArticles,
    tableHeaders,
    state,
    error,
    response: { rows },
    setMessageReaded,
    setEmailModalVisible
  } = useActivityStream();

  function getAreaName(area: number): string {
    switch (area) {
      case 1:
        return translate('component:source');
      case 2:
        return translate('component:customer_class');
      case 3:
        return translate('filter:category');
      case 4:
        return translate('component:product');
      default:
        return translate('filter:all');
    }
  }

  function getEventName(area: number): string {
    switch (area) {
      case 1:
        return translate('component:returns');
      case 2:
        return translate('component:profit_report');
      case 3:
        return translate('component:stock');
      case 4:
        return translate('component:sales_performance');
      case 6:
        return translate('component:stock');
      default:
        return translate('filter:all');
    }
  }

  const navigateToDetails = ({
    idArticle,
    buttonPressed = 3,
    tab = 0
  }: NavigateToParams) => {
    if (buttonPressed === 0) {
      history.push(`/product/${idArticle}/${tab}`);
    } else if (buttonPressed === 1) {
      window.open(`/product/${idArticle}/${tab}`);
    }
  };

  const initialRef: any = null;
  const excelRef = useRef(initialRef);

  const [selected, setSelected] = useState<{
    uid: string;
    event: number;
    section: number;
  }>({
    uid: '',
    event: 0,
    section: 0
  });

  useEffect(() => {
    if (excelRef && excelRef.current && csvRows.length > 0) {
      excelRef.current.link.click();
    }
  }, [csvRows]);

  useEffect(() => {
    if (selected.uid.length > 0) {
      fetchCSVArticles(selected);
    }
  }, [selected]);

  return (
    <Paper variant="outlined" className={classes.paper}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      {state === APIStatus.FULFILLED && rows.length === 0 && !error && (
        <CardNoResult />
      )}

      <CSVLink
        data={csvRows}
        headers={csvHeaders}
        filename={`${translate('activity_stream')}_${selected.uid}`}
        separator=";"
        ref={excelRef}
        className={classesH.hidden}
        target="_blank"
      />
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header: any, key) => (
                <TableCell
                  key={`${header.field}-${key}`}
                  align={header.align}
                  className={classes.headerCell}
                >
                  {header.field === '' ? (
                    <TableSortLabel
                      sx={{ boxShadow: 0 }}
                      active={sortBy.by === header.field}
                      direction={
                        sortBy.by === header.field ? sortBy.order : 'asc'
                      }
                      onClick={() => {
                        onSortChange({
                          order:
                            sortBy.by === header.field
                              ? sortBy.order === 'asc'
                                ? 'desc'
                                : 'asc'
                              : 'desc',
                          by: header.field
                        });
                      }}
                    >
                      <span>{header.label}</span>
                    </TableSortLabel>
                  ) : (
                    <span>{header.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, key) => (
                  <>
                    {row.showYear && <YearRow year={`${row.year}`} />}
                    <TableRow
                      hover
                      key={`${key}-${row.uid}`}
                      className={classes.tableRow}
                    >
                      <TableCell
                        align="left"
                        className={`${classes.firstTableCellLink} dayActivityStream`}
                      >
                        <CalendarDay
                          date={new Date(row.date * 1000)}
                          priority={row.priority}
                        />
                      </TableCell>

                      <TableCell align="center" sx={{ padding: '0px 16px' }}>
                        <Typography textAlign="center" variant="body1">
                          {getAreaName(row.section)}
                        </Typography>
                      </TableCell>

                      <TableCell align="center" sx={{ padding: '0px 16px' }}>
                        {row.name && <EventName {...row.name} />}
                      </TableCell>

                      <TableCell align="center" sx={{ padding: '0px 16px' }}>
                        <MessageEvent
                          periodDays={row.periodDays}
                          event={row.event}
                          translation={getEventName(row.event)}
                        />
                      </TableCell>

                      <TableCell align="center" sx={{ padding: '0px 16px' }}>
                        <Message
                          section={row.section}
                          sources={row.sourceNames}
                          event={row.event}
                          message={row.message}
                          messageData={row.messageData}
                          periodDays={row.periodDays}
                          skuList={row.articles.map((a) => a.articleNumber)}
                          ids={row.articles.map((a) => a.articleId)}
                          itemCount={row.itemCount}
                          onGoToDetail={(buttonPressed, id, tab) =>
                            navigateToDetails({
                              idArticle: `${id}`,
                              buttonPressed,
                              tab
                            })
                          }
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ padding: '0px 16px' }}>
                        <Actions
                          isRead={row.isRead}
                          onRead={() => setMessageReaded(row.uid)}
                          onOpenEmailModal={() =>
                            setEmailModalVisible(true, row.uid)
                          }
                          onRequestCSV={() =>
                            setSelected({
                              uid: row.uid,
                              event: row.event,
                              section: row.section
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
