import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductImages } from '../productImages/ProductImages';
import { ProductInfo } from './ProductInfo';
import { LineGraph } from './LineGrap';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { CardError } from '../../general/CardError';
import { APIStatus } from '../../../@types/APIStatus';
import { CardLoading } from '../../general/CardLoading';
import SelectTimePeriod from '../../filters/SelectTimePeriod';
import { DateRangeFilter } from '../../../@types/filters';
// import { LineGraph } from './LineGrap';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    // border: '1px solid red',
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
    // flexWrap: 'wrap'
  },
  info: {
    // border: '1px solid red',
    display: 'flex',
    // flex: 1,
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
    // flexWrap: 'wrap',
    /* [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2)
    } */
  },
  graphics: {
    // marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    // border: '1px solid green',
    // minWidth: '330px',
    height: '100%',
    minWidth: 290,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
    /* [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: theme.spacing(0)
    } */
  },
  container: {
    // width: '100%',
    // border: '1px solid blue',
    position: 'relative',
    marginTop: theme.spacing(2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

type Props = {
  timePeriod: DateRangeFilter;
  onDateChange: (timePeriod: DateRangeFilter) => void;
  onVariantsClicked: () => void;
};

export function HeaderTop({
  timePeriod,
  onVariantsClicked,
  onDateChange
}: Props) {
  const { id } = useParams<{ id: string }>();
  const {
    APIStatus: state,
    // filters: { timePeriod },
    error,
    article: {
      images,
      cover,
      orders,
      revenue,
      revenueTending,
      ordersTending,
      charts
    },
    getOneArticle
  } = useArticlesDetail();
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);

  const [widthScreen, updateWidthScreen] = useState<number>(0);

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateWidthScreen(window.innerWidth);
    });
  }, [window.innerWidth]);

  return (
    <Grid
      container
      spacing={2}
      wrap={`${widthScreen > 1279 ? 'nowrap' : 'wrap'}`}
    >
      {(images.length > 0 || cover.length > 0) && (
        <Grid item md="auto" order={{ md: 1, xs: 2 }}>
          <ProductImages />
        </Grid>
      )}

      <Grid item xs order={{ md: 2, xs: 1 }}>
        <ProductInfo onVariantsClicked={onVariantsClicked} />
      </Grid>

      <Grid item md="auto" order={{ md: 3, xs: 3 }}>
        <Box className={classes.graphics}>
          <SelectTimePeriod
            startFilter={timePeriod.startFilter}
            endFilter={timePeriod.endFilter}
            onChangePeriod={onDateChange}
          />
          <Box className={classes.container}>
            {error && <CardError />}
            {state === APIStatus.PENDING && <CardLoading />}
            <LineGraph
              qty={orders}
              name={translate('ordered_units')}
              data={charts.map((c) => ({ x: c.date, y: c.order }))}
              tending={ordersTending}
              type="number"
            />
            <LineGraph
              qty={revenue}
              name={translate('revenue')}
              data={charts.map((c) => ({ x: c.date, y: c.revenue }))}
              tending={revenueTending}
              type="euro"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
