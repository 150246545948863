import { MouseEvent, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import {
  useTheme,
  Box,
  makeStyles,
  Typography,
  IconButton,
  Fade,
  Theme
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { textStyles } from '../../../theme/TextClasses';
import { LightTooltip } from '../../general/LightTooltip';

const useProductStyles = makeStyles((theme) => ({
  root: {
    width: 350,

    // borderRight: '1px solid #f1f3f4',
    // border: '1px solid green',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  iconButton: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5)
  },
  name: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    zIndex: 1
  },
  tooltipLink: {
    color: '#fff'
  },
  imageContainer: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginRight: theme.spacing(1.5)
  },
  noImageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200]
  },
  image: {
    height: 'auto',
    maxWidth: '100%'
  },
  info: {
    // border: '1px solid green',
    minHeight: 81.69,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textContainer: {
    height: '100%',

    // width: '100%',
    // border: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  underline: {
    color: '#3c454e',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  variantLink: {
    border: 0,
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 0.5)
  },
  line: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));

type ProductCellProps = {
  imageSmall?: string;
  name: string;
  ean: string;
  asin?: string;
  sku: string;
  variants: number;
  onGoToDetail: (buttonPressed: number, tab?: number) => void;
  disableLink: boolean;
};

export function ProductCell({
  imageSmall,
  name,
  ean,
  asin,
  sku,
  variants,
  onGoToDetail,
  disableLink = false
}: ProductCellProps) {
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const classes = useProductStyles();
  const textClasses = textStyles({});
  const [isImageValid, setImageValid] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (imageSmall && imageSmall.length > 0) {
      setImageLoading(true);
      setImageValid(true);
    }
  }, [imageSmall]);

  function handleMouseClick(e: MouseEvent<HTMLButtonElement>, tab?: number) {
    e.preventDefault();
    onGoToDetail(e.button, tab);
  }

  return (
    <Box className={classes.root}>
      <LightTooltip
        placement="top"
        title={
          <Box>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {name}
            </Typography>
          </Box>
        }
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
      >
        <Typography
          variant="body2"
          fontWeight={700}
          className={`${textClasses.ellipsis} ${classes.name}`}
        >
          {name && name.length > 0
            ? name
            : translate('component:other_costs_shipping_fees')}
        </Typography>
      </LightTooltip>
      <Box className={classes.info}>
        {name && name.length > 0 && disableLink === false ? (
          <IconButton
            className={classes.iconButton}
            component="button"
            onMouseDown={(e: MouseEvent<HTMLButtonElement>) =>
              handleMouseClick(e)
            }
          >
            <Icon icon="eva:external-link-fill" />
          </IconButton>
        ) : (
          <Box
            sx={{
              width: '48px',
              height: '48px',
              marginRight: theme.spacing(0.5)
            }}
          />
        )}
        <Box className={classes.imageContainer}>
          {isImageValid && imageSmall !== null ? (
            <Box
              component="img"
              className={classes.image}
              src={imageSmall}
              alt="Product"
              onError={() => {
                setImageValid(false);
                setImageLoading(false);
              }}
              onLoad={() => setImageLoading(false)}
            />
          ) : (
            <Box className={classes.noImageContainer}>
              <Icon
                width={24}
                height={24}
                icon="bxs:camera-off"
                color={theme.palette.text.secondary}
              />
            </Box>
          )}
        </Box>

        {name && name.length > 0 && (
          <Box className={classes.textContainer}>
            <Box className={classes.line}>
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {translate('ean').charAt(0).toUpperCase() +
                  translate('ean').slice(1)}
                :
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={700}
                sx={{ margin: '0px 4px' }}
              >
                {ean && ean.length > 0 && ean !== 'n/a' ? ean : '-'}
              </Typography>
            </Box>

            <Box className={classes.line}>
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {translate('asin').charAt(0).toUpperCase() +
                  translate('asin').slice(1)}
                :
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={700}
                sx={{ margin: '0px 4px' }}
              >
                {asin && asin.length > 0 ? asin : '-'}
              </Typography>
            </Box>

            <Box className={classes.line}>
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {`${
                  translate('sku').charAt(0).toUpperCase() +
                  translate('sku').slice(1)
                }: `}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={700}
                sx={{ margin: '0px 4px' }}
              >
                {sku && sku.length > 0 ? sku : '-'}
              </Typography>
            </Box>

            <Box className={classes.line}>
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {`${translate('variants')}: `}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                fontWeight={700}
                sx={{ margin: '0px 4px' }}
              >
                {variants || 0}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
