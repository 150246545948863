import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import _debounce from 'lodash/debounce';
import useProductItems from '../../hooks/useProductItems';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import { APIStatus } from '../../@types/APIStatus';
import { CardError } from '../general/CardError';
import { CardLoading } from '../general/CardLoading';
import VariationsTable from './VariationsTable';
import { ProductListFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import useFilters from '../../hooks/useFilters';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import MenuDots from '../general/MenuDots';

type Props = {
  onSelectProduct: (articleId: number) => void;
  articleVariant?: number;
  idArticleUrl?: string;
};

export default function Variations({
  onSelectProduct,
  articleVariant = 0,
  idArticleUrl = ''
}: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    filters: {
      isActivity,
      isCritical,
      isRecommendations,
      shop,
      source,
      stock,
      customerId,
      searchName,
      searchId,
      timePeriod,
      isBundle
    },
    count,
    APIStatus: state,
    error,
    getProducts
  } = useProductItems();

  const {
    response: {
      articles: {
        maxMargin,
        maxReturns,
        maxRevenue,
        minMargin,
        minReturns,
        minRevenue
      }
    }
  } = useFilters();

  const {
    article: { articleId }
  } = useArticlesDetail();

  const debounceFilter = useCallback(_debounce(getProducts, 500), []);
  const classes = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsPerPageOpts, setRowsPerPageOpts] = useState<Array<number>>([
    5, 10, 25
  ]);
  const [filterBar, setFilterBar] = useState<ProductListFilterBarState>({
    isActivity,
    isCritical,
    isRecommendations,
    shop,
    source,
    stock,
    customerId,
    searchName,
    searchId,
    timePeriod,
    isBundle,
    margin: {
      config: {
        max: maxMargin,
        min: minMargin
      },
      range: {
        max: maxMargin,
        min: minMargin
      }
    },
    returns: {
      config: {
        max: maxReturns,
        min: minReturns
      },
      range: {
        max: maxReturns,
        min: minReturns
      }
    },
    revenue: {
      config: {
        max: maxRevenue,
        min: minRevenue
      },
      range: {
        max: maxRevenue,
        min: minRevenue
      }
    }
  });

  const [sort, setSort] = useState<SortBy>({ by: 'revenue', order: 'desc' });

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (count <= 5) setRowsPerPageOpts([5]);
    if (count > 5 && count <= 10) setRowsPerPageOpts([5, 10]);
    if (count > 10) setRowsPerPageOpts([5, 10, 25]);
  }, [count]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    debounceFilter(
      filterBar,
      { skip: page * rowsPerPage, limit: rowsPerPage },
      sort,
      `${articleVariant}`
    );
  }, []);

  useEffect(() => {
    if (
      (state === APIStatus.FULFILLED || state === APIStatus.REJECTED) &&
      loaded
    ) {
      debounceFilter(
        filterBar,
        { skip: page * rowsPerPage, limit: rowsPerPage },
        sort,
        `${articleVariant}`
      );
    } else {
      setLoaded(true);
    }
  }, [sort, page, rowsPerPage, filterBar]);

  const handleMenuAction = (option: number) => {
    switch (option) {
      case 0: {
        window.scrollTo({ top: 351, behavior: 'smooth' });
        break;
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={translate('component:variations')}
            action={
              <MenuDots
                options={[translate('center_table')]}
                onMenuAction={handleMenuAction}
              />
            }
          />
          <CardContent>
            <VariationsTable
              rowsPerPage={rowsPerPage}
              sortBy={sort}
              onSortChange={setSort}
              onSelectProduct={onSelectProduct}
            />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={rowsPerPageOpts}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
