import { Box, Typography } from '@material-ui/core';
import { VariantSize } from '../../@types/text';
import { ComplexText } from './DataResumeTotal';
import Euro from './Euro';
import Numeric from './Numeric';
import Percentage from './Percentage';

type FormattedComplexTextProps = {
  texts: Array<ComplexText>;
};

function FormattedComplexText({ texts }: FormattedComplexTextProps) {
  return (
    <Box>
      {texts.map(
        (
          { value, type, wrap, pre, post, variant = 'h6' }: ComplexText,
          key: number
        ) => (
          <Typography
            variant={variant}
            component="span"
            key={`${key}- ${value}`}
          >
            {wrap === 'bar' && '/'}
            {wrap === 'parentesis' && '('}
            {pre && `${pre} `}
            {type === 'currency' && <Euro euro={value} variantSize={variant} />}
            {type === 'percentage' && (
              <Percentage percentage={value} variantSize={variant} />
            )}
            {type === 'numeric' && (
              <Numeric numeric={value} variantSize={variant} />
            )}
            {post && ` ${post}`}
            {wrap === 'parentesis' && ')'}
          </Typography>
        )
      )}
    </Box>
  );
}

export default FormattedComplexText;
