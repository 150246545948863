import { Box, Fade, Theme, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { LightTooltip } from '../../general/LightTooltip';
import { Market } from '../../../@types/tableRows';

type ActiveMarketsCellProps = {
  markets: Array<Market>;
  onGoToDetail: () => void;
};

function ActiveMarketsCell({ markets, onGoToDetail }: ActiveMarketsCellProps) {
  const { t: translate } = useTranslation(['component']);
  const [showAll, setShowAll] = useState<boolean>(false);
  const theme: Theme = useTheme();
  const [mNames, setMNames] = useState<Array<string>>([]);
  useEffect(() => {
    const names = markets.map((m) => m.marketName).filter((el) => el);
    const n = Array.from(new Set(names)) as Array<string>;
    setMNames(n);
  }, [markets]);
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography
        sx={{
          '&:hover': { cursor: 'default' },
          margin: '0 8px',
          marginBottom: '4px'
        }}
        variant="body2"
        fontWeight={500}
      >
        {mNames.length}
      </Typography>
      <LightTooltip
        placement="top"
        title={
          <Box>
            {showAll &&
              mNames.map((marketName: string, i: number) => (
                <Typography
                  key={i}
                  variant="body2"
                  color="text.primary"
                  fontWeight={300}
                >{`\u2022 ${marketName}`}</Typography>
              ))}
            {!showAll &&
              mNames
                .slice(0, 5)
                .map((marketName: string, i: number) => (
                  <Typography
                    key={i}
                    variant="body2"
                    color="text.primary"
                    fontWeight={300}
                  >{`\u2022 ${marketName}`}</Typography>
                ))}
            {!showAll && mNames.length - 5 > 0 && (
              <Typography
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => onGoToDetail()}
                // onClick={() => setShowAll(old => !old)}
                component="span"
                variant="body2"
                color="text.link"
                fontWeight={300}
              >
                {`${translate('view_all')} (+${mNames.length - 5})`}
              </Typography>
            )}
          </Box>
        }
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
      >
        <Icon
          width={20}
          height={20}
          icon="bi:eye-fill"
          color={theme.palette.grey[400]}
        />
      </LightTooltip>
    </Box>
  );
}

export default ActiveMarketsCell;
