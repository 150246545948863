import { Grid } from '@material-ui/core';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import BundleProducts from '../../bundle/BundleProducts';

export function BundleTab() {
  const {
    article: { articleId }
  } = useArticlesDetail();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BundleProducts articleId={articleId} />
      </Grid>
    </Grid>
  );
}
