import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { RevenueByB2bB2cState } from '../../@types/reduxStates';
import { RevenueByB2bB2cResponse } from '../../@types/responsesAPI';

const initialState: RevenueByB2bB2cState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    countryId: 0,
    customerId: 0,
    shopId: 0,
    profit: 0
  },
  response: {
    chart: [],
    total: {
      name: '',
      margin: 0,
      marginBefore: 0,
      marginTending: 0,
      marginPercent: 0,
      revenue: 0,
      revenueBefore: 0,
      revenuePercent: 0,
      revenueTending: 0
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'revenueByB2bB2c',
  initialState,
  reducers: {
    setFilters: (
      state: RevenueByB2bB2cState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getRevenueByB2bB2c: (state: RevenueByB2bB2cState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getRevenueByB2bB2cSuccess: (
      state: RevenueByB2bB2cState,
      action: PayloadAction<RevenueByB2bB2cResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getRevenueByB2bB2cError: (
      state: RevenueByB2bB2cState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchRevenueByB2bB2c(
  filters: AreaChartFiltersState,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getRevenueByB2bB2c());
      const response = await axios.get('/api/v1/revenueByB2bB2c', {
        signal: abortController.signal,
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          shop: filters.shopId > 0 ? filters.shopId : undefined,
          source: filters.sourceId > 0 ? filters.sourceId : undefined,
          country: filters.countryId > 0 ? filters.countryId : undefined
        }
      });
      dispatch(getRevenueByB2bB2cSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getRevenueByB2bB2cError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getRevenueByB2bB2c,
  getRevenueByB2bB2cSuccess,
  getRevenueByB2bB2cError
} = slice.actions;
