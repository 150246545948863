import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { store } from '../store';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ApiStatusState } from '../../@types/reduxStates';
import { ApiStatusResponse } from '../../@types/responsesAPI';
import { sortByProperty } from '../../utils/sortValues';
import { HeaderTableFilter, SortBy } from '../../@types/filters';
import { ApiStatusRow } from '../../@types/tableRows';

// ----------------------------------------------------------------------

const initialState: ApiStatusState = {
  response: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'apiStatus',
  initialState,
  reducers: {
    setApiStatus(
      state: ApiStatusState,
      action: PayloadAction<ApiStatusResponse>
    ) {
      state.response = action.payload;
    },
    getApiStatus: (state: ApiStatusState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getApiStatusSuccess: (
      state: ApiStatusState,
      action: PayloadAction<ApiStatusResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getApiStatusError: (
      state: ApiStatusState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchApiStatus() {
  return async (dispatch: any) => {
    try {
      dispatch(getApiStatus());
      const response = await axios.get(`/api/v1/apiStats`);
      dispatch(getApiStatusSuccess(response.data));
    } catch (error) {
      dispatch(getApiStatusError(error as APIError));
    }
  };
}

export function sortApiStatusBy(
  list: Array<ApiStatusRow>,
  sortBy: SortBy,
  orderType?: HeaderTableFilter
): Array<ApiStatusRow> {
  return sortByProperty(list, sortBy.by, sortBy.order, orderType);
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setApiStatus,
  getApiStatus,
  getApiStatusSuccess,
  getApiStatusError
} = slice.actions;
