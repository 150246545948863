import { Icon } from '@iconify/react';
import { Box, Theme, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLocales from '../../../hooks/useLocales';

type Props = { asin: string; sku: string; ean: string };

function EventName({ asin, sku, ean }: Props) {
  const { t: translate } = useTranslation(['component']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
      }}
    >
      {ean.length > 0 && (
        <Box
          sx={{ padding: '2px 16px' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            textAlign="start"
            variant="body2"
            fontWeight={700}
            component="span"
          >
            {`${translate('ean')}:  `}
          </Typography>
          <Typography
            textAlign="start"
            variant="body1"
            component="span"
            sx={{ marginLeft: '4px' }}
          >
            {ean}
          </Typography>
        </Box>
      )}
      {asin.length > 0 && (
        <Box
          sx={{ padding: '2px 16px' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            textAlign="center"
            variant="body2"
            fontWeight={700}
            component="span"
          >
            {`${translate('asin')}:  `}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            component="span"
            sx={{ marginLeft: '4px' }}
          >
            {asin}
          </Typography>
        </Box>
      )}
      {sku.length > 0 && (
        <Box
          sx={{ padding: '2px 16px' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            textAlign="center"
            variant="body2"
            fontWeight={700}
            component="span"
          >
            {`${translate('sku')}:  `}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            component="span"
            sx={{ marginLeft: '4px' }}
          >
            {sku}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default EventName;
