import {
  Box,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import useLocales from '../../hooks/useLocales';
import { helperStyles } from '../../theme/HelperClasses';
import { textStyles } from '../../theme/TextClasses';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme, { dark?: boolean }>((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: ({ dark }) => (dark ? '#eceeef' : 'white')
  }
}));

type Props = {
  label: string;
  value: number;
  type: 'currency' | 'percent' | 'numeric';
  sign?: boolean;
  substract?: boolean;
  dark?: boolean;
};

function ProfitRow({
  label,
  value,
  type,
  sign = false,
  substract = false,
  dark = false
}: Props) {
  const helperClasses = helperStyles();
  const textClasses = textStyles({});
  const { currentLang } = useLocales();
  const theme: Theme = useTheme();
  const classes = useStyles({ dark });
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    // @ts-ignore
    signDisplay: 'auto',
    currency: iso
  });
  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2,
    // @ts-ignore
    signDisplay: sign ? 'exceptZero' : 'auto'
  });
  const getFormat = (t: string, v: number): string => {
    if (t === 'currency') return euro.format(substract ? v * -1 : v);
    if (t === 'percent') return percent.format(v / 100);
    return `${v}`;
  };
  const getColor = (t: string, v: number): string => {
    if (t === 'numeric' || t === 'percent') return theme.palette.text.primary;
    if (v >= 0) {
      if (substract) return theme.palette.error.main;
      return theme.palette.success.main;
    }
    if (v < 0) {
      if (substract) return theme.palette.success.main;
      return theme.palette.error.main;
    }
    return theme.palette.text.primary;
  };

  return (
    <Box className={`${helperClasses.rowNoPadding} ${classes.root}`}>
      <Box className={`${helperClasses.cell} ${helperClasses.start}`}>
        <Typography
          variant="body1"
          fontWeight={500}
          className={textClasses.ellipsis}
        >
          {label}
        </Typography>
      </Box>
      <Box className={`${helperClasses.cell} ${helperClasses.end}`}>
        <Typography
          variant="h6"
          fontWeight={500}
          sx={{ color: getColor(type, value) }}
        >
          {getFormat(type, value)}
        </Typography>
      </Box>
    </Box>
  );
}

export default ProfitRow;
