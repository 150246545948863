import { makeStyles, Theme, alpha, Box, Typography } from '@material-ui/core';
import { PurchaseStatusSelectItem } from '../../../@types/filters';

const useStyles = makeStyles<Theme, { color: string; display?: string }>(
  (theme) => ({
    label: {
      display: ({ display }) => display || 'inline-block',
      padding: ({ color }) => (color === 'transparent' ? '0px' : '6px'),
      borderRadius: theme.shape.borderRadius,
      border: ({ color }) => `1px solid ${color}`,
      backgroundColor: ({ color }) =>
        color === 'transparent' ? 'transparent' : alpha(color, 0.2),
      '& .MuiTypography-body1': {
        color: ({ color }) =>
          color === 'transparent' ? theme.palette.text.primary : color
      }
    }
  })
);

type Props = {
  purchaseStatusSelectItem: PurchaseStatusSelectItem;
  display?: string;
};

function PurchaseStatusCell({
  purchaseStatusSelectItem: { purchaseStatusName, color },
  display
}: Props) {
  const classes = useStyles({ color, display });
  return (
    <Box className={classes.label}>
      <Typography component="span" variant="body1" color={color}>
        {purchaseStatusName}
      </Typography>
    </Box>
  );
}

export default PurchaseStatusCell;
