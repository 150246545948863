import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fDateTime } from '../../utils/formatTime';
import { useOrderStyles } from './styles';

type Props = {
  orderExtId: number;
  countryName: string;
  orderTime: string;
  isMobile: boolean;
};
export function HeaderBox({
  orderExtId,
  countryName,
  orderTime,
  isMobile
}: Props) {
  const { t: translate } = useTranslation(['menu', 'component', 'filter']);
  const classes = useOrderStyles();

  return (
    <Grid container item xs={7} md={6} className={classes.description}>
      <Grid container item xs={12}>
        <Grid item xs={6} md={4}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={500}
            color="text.primary"
          >
            {translate('component:order_number')}
          </Typography>
        </Grid>

        <Grid item xs={6} md={8}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={700}
            color="text.primary"
          >
            {orderExtId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={6} md={4}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={500}
            color="text.primary"
          >
            {translate('component:ship_country')}
          </Typography>
        </Grid>
        <Grid item xs={6} md={8}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={700}
            color="text.primary"
          >
            {countryName || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} md={4}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={500}
            color="text.primary"
          >
            {translate('component:order_datum')}
          </Typography>
        </Grid>
        <Grid item xs={6} md={8}>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            fontWeight={700}
            color="text.primary"
          >
            {orderTime && orderTime.length > 0 ? fDateTime(orderTime) : ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
