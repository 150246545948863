import {
  alpha,
  Box,
  CircularProgress,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles<Theme, { color: string; size: number }>(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      minHeight: 60
    },
    circularProgressContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: ({ size }) => size,
      height: ({ size }) => size
    },
    colorCircularProgress: {
      position: 'absolute',
      top: 0,
      left: 0,
      color: ({ color }) => color
    },
    bgCircularProgress: {
      color: ({ color }) => alpha(color, 0.1)
    },
    textBlockLegend: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    label: {
      color: '#212B36'
    }
  })
);

type HealthCircleCellProps = {
  value: number;
  size: number;
};

export function HealthCircleCell({ value, size }: HealthCircleCellProps) {
  const [color, setColor] = useState<string>('#ff1943');
  const classes = useStyles({ color, size });

  useEffect(() => {
    if (value <= 15) {
      setColor('#ff1c00');
    } else if (value <= 35) {
      setColor('#ff7e00');
    } else if (value <= 45) {
      setColor('#ffac00');
    } else if (value <= 65) {
      setColor('#ffd600');
    } else if (value <= 85) {
      setColor('#a5fb00');
    } else {
      setColor('#00a83a');
    }
  }, [value]);

  return (
    <Box className={classes.root}>
      <Box className={classes.circularProgressContainer}>
        <CircularProgress
          className={classes.bgCircularProgress}
          variant="determinate"
          value={100}
          size={size}
        />
        <CircularProgress
          className={classes.colorCircularProgress}
          variant="determinate"
          value={value}
          size={size}
        />
        <Box className={classes.textBlockLegend}>
          <Typography variant="h6" fontWeight={700} className={classes.label}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
