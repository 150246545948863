import { TableCell, Theme, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type Props = { year: string };

function YearRow({ year }: Props) {
  const theme: Theme = useTheme();
  return (
    <TableCell colSpan={6} align="left">
      <Typography
        textAlign="left"
        variant="h5"
        color={theme.palette.primary.main}
      >
        {year}
      </Typography>
    </TableCell>
  );
}

export default YearRow;
