import { Box, Theme, useTheme, makeStyles, alpha } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocales from '../../../hooks/useLocales';

type Props = { date: Date; priority: number };

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '38px',
    borderRadius: '5px',
    padding: '4px 8px',
    marginTop: '8px',
    color: ({ color }) => color,
    backgroundColor: ({ color }) => alpha(color, 0.16)
  }
}));

function CalendarDay({ date, priority }: Props) {
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const classes = useStyles({
    color:
      priority === 2
        ? theme.palette.warning.main
        : priority === 3
        ? theme.palette.error.main
        : theme.palette.success.main
  });

  const fDate = {
    month: date.toLocaleString(currentLang.value, { month: 'long' }),
    day: date.getDate()
  };

  const getImportance = (priority: number): string => {
    switch (priority) {
      case 2:
        return translate('component:msg_important');
      case 3:
        return translate('component:msg_critical');
      default:
        return translate('component:msg_standard');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '38px',
          minHeight: '38px',
          backgroundColor: theme.palette.grey[100],
          borderRadius: '5px',
          padding: '8px'
        }}
      >
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ marginBottom: '4px' }}
        >
          {fDate.day}
        </Typography>
        <Typography
          textAlign="center"
          variant="caption"
          color={theme.palette.text.secondary}
        >
          {fDate.month}
        </Typography>
      </Box>
      <Box className={classes.root}>{getImportance(priority)}</Box>
    </Box>
  );
}

export default CalendarDay;
