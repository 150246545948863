export enum APIStatus {
  IDLE,
  PENDING,
  REJECTED,
  FULFILLED
}

export type APIError = {
  message: string;
  status: string;
  code: number;
};
