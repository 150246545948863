import {
  Box,
  makeStyles,
  Theme,
  Fade,
  Button,
  Typography,
  useTheme
} from '@material-ui/core';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { LightTooltip } from '../../../general/LightTooltip';

import { textStyles } from '../../../../theme/TextClasses';

import { capitalizeFirstLetter } from '../../../../utils/helper';
import ArticleLabel from './ArticleLabel';
import ArticleAttributes from './ArticleAttributes';

const useProductStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: 400,
    padding: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box'
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
    '& .MuiButton-label': {
      justifyContent: 'start'
    },
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  imageContainer: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginRight: theme.spacing(1)
  },
  noImageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200]
  },
  textContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  line: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  variants: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  info: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }
}));

type Props = {
  name: string;
  variants: number;
  imageSmall?: string;
  ean?: string;
  asin?: string;
  sku?: string;
  parentId?: number;
  attributes: Array<{ attributeText: string; attributeValue: string }>;
  onGoToDetail: (buttonPressed: number, tab?: number) => void;
};

function ArticleLinkInfo({
  name,
  imageSmall,
  ean,
  asin,
  sku,
  parentId,
  variants,
  attributes,
  onGoToDetail
}: Props) {
  const classes = useProductStyles();
  const { t: translate } = useTranslation(['component']);
  const textClasses = textStyles({});
  const theme: Theme = useTheme();

  function handleMouseClick(e: MouseEvent<HTMLButtonElement>, tab?: number) {
    e.preventDefault();
    onGoToDetail(e.button, tab);
  }

  return (
    <Box className={classes.root}>
      <Button
        fullWidth
        className={classes.title}
        startIcon={<Icon icon="eva:external-link-fill" width={24} />}
        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => handleMouseClick(e)}
      >
        <LightTooltip
          placement="top"
          title={
            <Box>
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {name}
              </Typography>
            </Box>
          }
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
        >
          <Typography
            variant="body2"
            fontWeight={700}
            className={`${textClasses.ellipsis} ${classes.name}`}
          >
            {name && name.length > 0
              ? name
              : translate('component:other_costs_shipping_fees')}
          </Typography>
        </LightTooltip>
      </Button>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
      >
        <Box className={classes.imageContainer}>
          {imageSmall ? (
            <Box
              component="img"
              className={classes.image}
              src={imageSmall}
              alt="Product"
              onError={() => {}}
              onLoad={() => {}}
            />
          ) : (
            <Box className={classes.noImageContainer}>
              <Icon
                width={24}
                height={24}
                icon="bxs:camera-off"
                color={theme.palette.text.secondary}
              />
            </Box>
          )}
        </Box>

        <Box className={classes.info}>
          <Box className={classes.textContainer}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
            >
              <Box
                flex={0.6}
                display="flex"
                flexDirection="column"
                maxWidth="187.2px"
              >
                <ArticleLabel
                  label={capitalizeFirstLetter(translate('component:ean'))}
                  value={ean && ean.length > 0 && ean !== 'n/a' ? ean : '-'}
                />
                {asin && asin.length > 0 && (
                  <ArticleLabel
                    label={capitalizeFirstLetter(translate('component:asin'))}
                    value={asin}
                  />
                )}
                {sku && sku.length > 0 && (
                  <ArticleLabel
                    label={capitalizeFirstLetter(translate('component:sku'))}
                    value={sku}
                  />
                )}
              </Box>

              <Box flex={0.4}>
                {attributes && attributes.length > 0 && (
                  <ArticleAttributes attributes={attributes} />
                )}
              </Box>
            </Box>

            {variants > 1 && parentId && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="100%"
              >
                <Box flex={0.6} textAlign="start">
                  <ArticleLabel
                    label={translate('component:parent_id')}
                    value={parentId}
                  />
                </Box>
                <Box flex={0.4} textAlign="start">
                  <ArticleLabel
                    label={translate('component:variants')}
                    value={`${variants}`}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ArticleLinkInfo;
