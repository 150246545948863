import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo(props: BoxProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/loading.svg"
      height={40}
      {...props}
    />
  );
}

export function FullLogo(props: BoxProps) {
  // const src = "/static/brand/DraftAlgoritmo.png"
  // const src = '/static/brand/logo_algoritmo2.svg';
  const src = '/static/brand/logo_algoritmo_clean.png';
  // const src = '/static/brand/logo_algoritmo_beta.png';
  // return <Box component="img" alt="logo" src={src} height={70} {...props} />;
  return (
    <Box
      component="img"
      alt="logo"
      src={src}
      width="100%"
      maxWidth={240}
      height="auto"
      {...props}
    />
  );
}
