import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import getUserLocale from 'get-user-locale';

// ----------------------------------------------------------------------
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/api/v1/locales/{{lng}}/{{ns}}.json'
    },
    load: 'currentOnly',
    lng: localStorage.getItem('i18nextLng') || getUserLocale(),
    fallbackLng: false,
    debug: false,
    ns: ['global'],
    defaultNS: 'global',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
