import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import Variations from '../../variations/Variations';

type VariantsProps = {
  onSelectProduct: (articleId: number) => void;
};

export function VariationsTab({ onSelectProduct }: VariantsProps) {
  const { id: idArticleUrl } = useParams<{ id?: string }>();
  const {
    article: { articleExtId }
  } = useArticlesDetail();
  useEffect(() => {
    console.log(articleExtId);
  }, [articleExtId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Variations
          onSelectProduct={onSelectProduct}
          articleVariant={articleExtId}
          idArticleUrl={idArticleUrl}
        />
      </Grid>
    </Grid>
  );
}
