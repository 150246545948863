import { Grid } from '@material-ui/core';
import Profit from '../../profit/Profit';

export function ProfitsTab() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Profit />
      </Grid>
    </Grid>
  );
}
