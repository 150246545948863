import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OrderFilterState } from '../../@types/filterBars';
import { OrderState } from '../../@types/reduxStates';
import { OrderResponse } from '../../@types/responsesAPI';

const initialState: OrderState = {
  response: {
    articles: [],
    orderId: 0,
    costsNet: 0,
    isCancelled: false,
    orderDate: '',
    orderExtId: 0,
    orderTime: '',
    orderTimeStamp: '',
    orderTypeId: 0,
    paymentMethodId: 0,
    paymentStatusId: 0,
    shopId: 0,
    countryName: '',
    currencyIso: '',
    customerClassName: '',
    sourceName: '',
    orderStatusNameDE: '',
    orderStatusNameEN: '',
    payMethodName: '',
    paymentStatusName: '',
    orderAmountNet: 0,
    orderAmount: 0,
    orderHasInvoice: false,
    orderInvoiceDate: '',
    orderInvoiceTotal: 0,
    orderChangeTime: '',
    orderShipping: 0,
    orderShippingNet: 0,
    avgPriceNet: 0,
    avgPriceGross: 0,
    positionQtyTotal: 0,
    psyStockTotal: 0,
    orderExitDate: ''
  },
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    customerId: 0,
    profit: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder(state: OrderState, action: PayloadAction<OrderResponse>) {
      state.response = action.payload;
    },
    setFilters: (
      state: OrderState,
      action: PayloadAction<OrderFilterState>
    ) => {
      state.filters = action.payload;
    },
    getOrder: (state: OrderState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrderSuccess: (
      state: OrderState,
      action: PayloadAction<OrderResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOrderError: (state: OrderState, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOrder(orderId: string, abortController: AbortController) {
  return async (dispatch: any) => {
    dispatch(getOrder());
    try {
      const response = await axios.get(`/api/v1/order/${orderId}`, {
        signal: abortController.signal
      });
      dispatch(getOrderSuccess(response.data));
    } catch (error) {
      dispatch(getOrderError(error as APIError));
    }
  };
}

export default slice.reducer;

export const {
  setOrder,
  getOrder,
  getOrderSuccess,
  getOrderError,
  setFilters
} = slice.actions;
