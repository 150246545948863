import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { sortByProperty } from '../../utils/sortValues';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { MarketplaceState } from '../../@types/reduxStates';
import {
  AverageOrderValueByMarketplaceResponse,
  ByChannelReponse,
  PerformanceByMarketplaceResponse,
  ProfitByMarketplaceResponse,
  ReturnsByMarketplaceResponse
} from '../../@types/responsesAPI';
import { HeaderTableFilter } from '../../@types/filters';

const initialState: MarketplaceState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  APIStatus: APIStatus.IDLE,
  data: {
    performanceByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: []
    },
    profitByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: []
    },
    revenueByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: [
        {
          name: '',
          margin: 0,
          marginBefore: 0,
          marginTending: 0,
          marginPercent: 0,
          revenue: 0,
          revenueBefore: 0,
          revenuePercent: 0,
          revenueTending: 0
        }
      ]
    },
    averageMarginByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: [
        {
          name: '',
          margin: 0,
          marginBefore: 0,
          marginTending: 0,
          marginPercent: 0,
          revenue: 0,
          revenueBefore: 0,
          revenuePercent: 0,
          revenueTending: 0
        }
      ]
    },
    averageOrderValueByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: []
    },
    returnsByMarketplace: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      data: []
    }
  }
};

const slice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    // PerformanceByMarketplace
    setPerformanceByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortPerformanceByMarketplace: (
      state: MarketplaceState,
      action: PayloadAction<PerformanceByMarketplaceResponse>
    ) => {
      state.data.performanceByMarketplace.data = action.payload;
    },
    getPerformanceByMarketplace: (state: MarketplaceState) => {
      state.data.performanceByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.performanceByMarketplace.error = undefined;
    },
    getPerformanceByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<PerformanceByMarketplaceResponse>
    ) => {
      state.data.performanceByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.performanceByMarketplace.data = action.payload;
    },
    getPerformanceByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.performanceByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.performanceByMarketplace.error = action.payload;
    },
    // ProfitByMarketplace
    setProfitByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortProfitByMarketplace: (
      state: MarketplaceState,
      action: PayloadAction<ProfitByMarketplaceResponse>
    ) => {
      state.data.profitByMarketplace.data = action.payload;
    },
    getProfitByMarketplace: (state: MarketplaceState) => {
      state.data.profitByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.profitByMarketplace.error = undefined;
    },
    getProfitByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<ProfitByMarketplaceResponse>
    ) => {
      state.data.profitByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.profitByMarketplace.data = action.payload;
    },
    getProfitByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.profitByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.profitByMarketplace.error = action.payload;
    },
    // ReturnsByMarketplace
    setReturnsByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortReturnsByMarketplace: (
      state: MarketplaceState,
      action: PayloadAction<ReturnsByMarketplaceResponse>
    ) => {
      state.data.returnsByMarketplace.data = action.payload;
    },
    getReturnsByMarketplace: (state: MarketplaceState) => {
      state.data.returnsByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.returnsByMarketplace.error = undefined;
    },
    getReturnsByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<ReturnsByMarketplaceResponse>
    ) => {
      state.data.returnsByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.returnsByMarketplace.data = action.payload;
    },
    getReturnsByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.returnsByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.returnsByMarketplace.error = action.payload;
    },
    // RevenueByMarketplace
    setRevenueByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortRevenueByMarketplace: (
      state: MarketplaceState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.data.revenueByMarketplace.data = action.payload;
    },
    getRevenueByMarketplace: (state: MarketplaceState) => {
      state.data.revenueByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.revenueByMarketplace.error = undefined;
    },
    getRevenueByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.data.revenueByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.revenueByMarketplace.data = action.payload;
    },
    getRevenueByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.revenueByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.revenueByMarketplace.error = action.payload;
    },
    // AverageOrderValueByMarketplace
    setAverageOrderValueByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortAverageOrderValueByMarketplace: (
      state: MarketplaceState,
      action: PayloadAction<AverageOrderValueByMarketplaceResponse>
    ) => {
      state.data.averageOrderValueByMarketplace.data = action.payload;
    },
    getAverageOrderValueByMarketplace: (state: MarketplaceState) => {
      state.data.averageOrderValueByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.averageOrderValueByMarketplace.error = undefined;
    },
    getAverageOrderValueByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<AverageOrderValueByMarketplaceResponse>
    ) => {
      state.data.averageOrderValueByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.averageOrderValueByMarketplace.data = action.payload;
    },
    getAverageOrderValueByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.averageOrderValueByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.averageOrderValueByMarketplace.error = action.payload;
    },
    // AverageMarginByMarketplace
    setAverageMarginByMarketplaceFilters: (
      state: MarketplaceState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getAverageMarginByMarketplace: (state: MarketplaceState) => {
      state.data.averageMarginByMarketplace.APIStatus = APIStatus.PENDING;
      state.data.averageMarginByMarketplace.error = undefined;
    },
    getAverageMarginByMarketplaceSuccess: (
      state: MarketplaceState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.data.averageMarginByMarketplace.APIStatus = APIStatus.FULFILLED;
      state.data.averageMarginByMarketplace.data = action.payload;
    },
    getAverageMarginByMarketplaceError: (
      state: MarketplaceState,
      action: PayloadAction<APIError>
    ) => {
      state.data.averageMarginByMarketplace.APIStatus = APIStatus.REJECTED;
      state.data.averageMarginByMarketplace.error = action.payload;
    }
  }
});

export function fetchPerformanceByMarketplace(
  filters: AreaChartFiltersState,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setPerformanceByMarketplaceFilters(filters));
    try {
      dispatch(getPerformanceByMarketplace());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/performanceByMarketplace',
        { params }
      );
      dispatch(getPerformanceByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getPerformanceByMarketplaceError(error as APIError));
    }
  };
}

export function fetchProfitByMarketplace(
  filters: AreaChartFiltersState,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setProfitByMarketplaceFilters(filters));
    try {
      dispatch(getProfitByMarketplace());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/profitByMarketplace',
        { params }
      );
      dispatch(getProfitByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getProfitByMarketplaceError(error as APIError));
    }
  };
}

export function fetchReturnsByMarketplace(
  filters: AreaChartFiltersState,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setReturnsByMarketplaceFilters(filters));
    try {
      dispatch(getReturnsByMarketplace());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/returnsByMarketplace',
        { params }
      );
      dispatch(getReturnsByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getReturnsByMarketplaceError(error as APIError));
    }
  };
}

export function fetchRevenueByMarketplace(
  filters: AreaChartFiltersState,
  articleId: string = ''
) {
  return async (dispatch: any) => {
    dispatch(setRevenueByMarketplaceFilters(filters));
    try {
      dispatch(getRevenueByMarketplace());
      const params: {
        from: string;
        till: string;
        idArticle?: string;
        shop?: number;
        // source?: number;
        country?: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        // source: filters.sourceIdId > 0 ? filters.sourceIdId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: articleId.length > 0 ? articleId : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/revenueByMarketplace',
        { params }
      );
      dispatch(getRevenueByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getRevenueByMarketplaceError(error as APIError));
    }
  };
}

export function fetchAverageMarginByMarketplace(
  filters: AreaChartFiltersState,
  idArticle: string = ''
) {
  return async (dispatch: any) => {
    dispatch(setAverageMarginByMarketplaceFilters(filters));
    try {
      dispatch(getAverageMarginByMarketplace());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
        idArticle?: string;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined,
        idArticle: idArticle.length > 0 ? idArticle : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/averageMarginByMarketplace',
        { params }
      );
      dispatch(getAverageMarginByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getAverageMarginByMarketplaceError(error as APIError));
    }
  };
}

export function fetchAverageOrderValueByMarketplace(
  filters: AreaChartFiltersState,
  idArticle: string = ''
) {
  return async (dispatch: any) => {
    dispatch(setAverageOrderValueByMarketplaceFilters(filters));
    try {
      dispatch(getAverageOrderValueByMarketplace());
      const params: {
        from: string;
        till: string;
        shop?: number;
        source?: number;
        country?: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shop: filters.shopId > 0 ? filters.shopId : undefined,
        source: filters.sourceId > 0 ? filters.sourceId : undefined,
        country: filters.countryId > 0 ? filters.countryId : undefined
      };
      const response: AxiosResponse = await axios.get(
        '/rest/v1/dashboard/averageOrderValueByMarketplace',
        { params }
      );
      dispatch(getAverageOrderValueByMarketplaceSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getAverageOrderValueByMarketplaceError(error as APIError));
    }
  };
}

export function sortPerformanceByMarketplaceBy(
  list: PerformanceByMarketplaceResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortPerformanceByMarketplace(orderedList));
}

export function sortProfitByMarketplaceBy(
  list: ProfitByMarketplaceResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortProfitByMarketplace(orderedList));
}

export function sortAverageOrderValueByMarketplaceBy(
  list: AverageOrderValueByMarketplaceResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortAverageOrderValueByMarketplace(orderedList));
}

export function sortReturnsByMarketplaceBy(
  list: AverageOrderValueByMarketplaceResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortReturnsByMarketplace(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setPerformanceByMarketplaceFilters,
  sortPerformanceByMarketplace,
  getPerformanceByMarketplace,
  getPerformanceByMarketplaceSuccess,
  getPerformanceByMarketplaceError,

  setProfitByMarketplaceFilters,
  sortProfitByMarketplace,
  getProfitByMarketplace,
  getProfitByMarketplaceSuccess,
  getProfitByMarketplaceError,

  setReturnsByMarketplaceFilters,
  sortReturnsByMarketplace,
  getReturnsByMarketplace,
  getReturnsByMarketplaceSuccess,
  getReturnsByMarketplaceError,

  setRevenueByMarketplaceFilters,
  sortRevenueByMarketplace,
  getRevenueByMarketplace,
  getRevenueByMarketplaceSuccess,
  getRevenueByMarketplaceError,

  setAverageOrderValueByMarketplaceFilters,
  sortAverageOrderValueByMarketplace,
  getAverageOrderValueByMarketplace,
  getAverageOrderValueByMarketplaceSuccess,
  getAverageOrderValueByMarketplaceError,

  setAverageMarginByMarketplaceFilters,
  getAverageMarginByMarketplace,
  getAverageMarginByMarketplaceSuccess,
  getAverageMarginByMarketplaceError
} = slice.actions;
