import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles<Theme, { color?: string }>((theme) => ({
  root: {
    height: 51,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: ({ color }) => color ?? theme.palette.text.primary
  }
}));

type TextCellProps = {
  text: string;
  color?: string;
};

export function TextCell({ text, color }: TextCellProps) {
  const classes = useStyles({ color });

  return (
    <Box className={classes.root}>
      <Typography variant="body2" fontWeight={700}>
        {text}
      </Typography>
    </Box>
  );
}
