import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useOrder from '../../hooks/useOrder';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TableHeaderCell, TableHeaderCellData } from '../../@types/tableRows';
import { ProductCell } from '../items/cells/ProductCell';
import { NavigateToParams } from '../../@types/menu';
import { SortBy } from '../../@types/filters';
import { PriceCell } from '../items/cells/PriceCell';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import { CardNoResult } from '../general/CardNoResult';
import { APIStatus } from '../../@types/APIStatus';
import TableRowSummary from '../general/TableRowSummary';
import { useOrderSummary } from '../../hooks/useOrderSummary';
import { StockCell } from '../items/cells/StockCell';

type Props = {
  rowsPerPage: number;
  page: number;
};

function OrderArticlesTable({ rowsPerPage, page }: Props) {
  const { t: translate } = useTranslation(['component']);
  const history = useHistory();

  const { height } = useWindowSize();
  const tableClasses = TableStyleClasses({
    height: 122,
    numRows: rowsPerPage,
    maxSize: height
  });

  const {
    APIStatus: state,
    error,
    order: { articles }
  } = useOrder();

  const { cells } = useOrderSummary({ articles });

  const [sort, setSort] = useState<SortBy>({ by: 'revenue', order: 'desc' });

  const TABLE_HEAD: TableHeaderCellData = [
    { field: 'name', label: translate('product'), align: 'left' },
    { field: 'psyStock', label: translate('stock'), align: 'center' },
    {
      field: 'purchasePrice',
      label: `${translate('purchase_price')} (Netto)`,
      align: 'center'
    },
    {
      field: 'priceTotalNet',
      label: `${translate('sold_price')} (Netto)`,
      align: 'center'
    },
    {
      field: 'qty',
      label: translate('article_qty'),
      align: 'center'
    },
    {
      field: 'priceNet',
      label: `${translate('net_revenue')}`,
      align: 'center'
    },

    {
      field: 'priceTotalGross',
      label: `${translate('revenue_brutto')}`,
      align: 'center'
    }
  ];

  const navigateToDetails = ({
    idArticle,
    buttonPressed = 3,
    tab = 0
  }: NavigateToParams) => {
    if (buttonPressed === 0) {
      history.push(`/product/${idArticle}/${tab}`);
    } else if (buttonPressed === 1) {
      window.open(`/product/${idArticle}/${tab}`);
    }
  };

  return (
    <Paper variant="outlined" sx={{ position: 'relative', minHeight: '122px' }}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      {state === APIStatus.FULFILLED && articles.length === 0 && (
        <CardNoResult />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="positions table">
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((header: TableHeaderCell, index: number) => (
                <TableCell
                  className={
                    index === 0
                      ? tableClasses.headerCellSticky
                      : tableClasses.headerCell
                  }
                  key={header.field}
                  align={header.align}
                >
                  <TableSortLabel
                    sx={{ boxShadow: 0 }}
                    active={sort.by === header.field}
                    direction={sort.order}
                    onClick={() => {
                      setSort({
                        order:
                          sort.by === header.field
                            ? sort.order === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'desc',
                        by: header.field
                      });
                    }}
                  >
                    <span>{header.label}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRowSummary
              colSpan={2}
              startEmptyCells={0}
              endEmptyCells={0}
              cells={cells}
            />
            {articles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => (
                <TableRow
                  key={`${row.articleId}-${i}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell align="left" className={tableClasses.cellSticky}>
                    <ProductCell
                      imageSmall={row.articlePicture}
                      name={row.articleName}
                      ean={row.articleEan}
                      // asin={row.asin}
                      sku={row.articleNumber}
                      variants={row.articleVariationId || 0}
                      onGoToDetail={(buttonPressed, tab) =>
                        navigateToDetails({
                          idArticle: `${row.articleId}` || '',
                          buttonPressed,
                          tab
                        })
                      }
                      disableLink={row.articleId === null}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <StockCell
                      label={row.stockLabel}
                      stock={row.psyStock || 0}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <PriceCell price={row.purchasePrice} />
                  </TableCell>
                  <TableCell align="center">
                    <PriceCell price={row.priceNet} />
                  </TableCell>
                  <TableCell align="center">
                    <PriceCell price={row.qty} isCurrency={false} />
                  </TableCell>

                  <TableCell align="center">
                    <PriceCell price={row.priceTotalNet} />
                  </TableCell>
                  <TableCell align="center">
                    <PriceCell price={row.priceTotalGross} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default OrderArticlesTable;
