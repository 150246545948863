import { number } from 'prop-types';
import React, { createContext, ReactNode, useContext } from 'react';

interface ConstantsContextProps {
  APPBAR_MOBILE: number;
  APPBAR_DESKTOP: number;
  HEIGHT_PRODUCT_CELL: number;
  WIDTH_PRODUCT_CELL: number;
}

const ConstantsContext = createContext<ConstantsContextProps | undefined>(
  undefined
);

export const ConstantsProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const constants = {
    APPBAR_MOBILE: 64,
    APPBAR_DESKTOP: 92,
    HEIGHT_PRODUCT_CELL: 138,
    WIDTH_PRODUCT_CELL: 400
  };

  return (
    <ConstantsContext.Provider value={constants}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => {
  const context = useContext(ConstantsContext);
  if (context === undefined) {
    throw new Error('useConstants must be used within a ConstantsProvider');
  }
  return context;
};
