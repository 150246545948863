import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProductListFilterBarState } from '../@types/filterBars';
import { DateRangeFilter, SortBy } from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import {
  fetchProductItems,
  fetchProductItemsData
} from '../redux/slices/productItems';
import { ProductListRow, TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';
import { divideIfNotZero, unknownToNumber } from '../utils/helper';
import { CSVExportData } from '../components/general/ExportCSV';

function useProductItems() {
  const { hash } = useLocation();
  const { currentLang } = useLocales();
  const dispatch = useDispatch();
  const {
    response: {
      rows,
      total: {
        stock,
        avgPrice,
        orders,
        revenue,
        margin,
        returns,
        activityNormal,
        activityCritical
      },
      totalCount: { count }
    },
    filters,
    APIStatus,
    error
  } = useSelector((state: RootState) => state.productItems);
  const { t: translate } = useTranslation(['component']);

  function getProductsUrlFilterValues(
    filterBarState: ProductListFilterBarState
  ): ProductListFilterBarState {
    const f: ProductListFilterBarState = {
      ...filterBarState,
      timePeriod: { ...filterBarState.timePeriod }
    };
    if (hash.length > 0) {
      hash
        .split('#')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'from': {
              f.timePeriod = { ...f.timePeriod, startFilter: value };
              break;
            }
            case 'till': {
              f.timePeriod = { ...f.timePeriod, endFilter: value };
              break;
            }
            case 'source': {
              f.source = Number(value);
              break;
            }
            case 'customerId': {
              f.customerId = Number(value);
              break;
            }
            case 'shop': {
              f.shop = Number(value);
              break;
            }
            case 'searchName': {
              f.searchName = value;
              break;
            }
            case 'searchId': {
              f.searchId = value;
              break;
            }
            case 'revenueFrom': {
              f.revenue.range.min = Number(value);
              break;
            }
            case 'revenueTo': {
              f.revenue.range.max = Number(value);
              break;
            }
            case 'margeFrom': {
              f.margin.range.min = Number(value);
              break;
            }
            case 'margeTo': {
              f.margin.range.max = Number(value);
              break;
            }
            case 'returnsFrom': {
              f.returns.range.min = Number(value);
              break;
            }
            case 'returnsTo': {
              f.returns.range.max = Number(value);
              break;
            }
            case 'isActivity': {
              f.isActivity = Number(value) > 0;
              break;
            }
            case 'isBundle': {
              f.isBundle = Number(value) > 0;
              break;
            }
            case 'isRecommendations': {
              f.isRecommendations = Number(value) > 0;
              break;
            }
            case 'isCritical': {
              f.isCritical = Number(value) > 0;
              break;
            }
            case 'stock': {
              f.stock = Number(value);
              break;
            }
            default: {
              break;
            }
          }
        });
    }
    return f;
  }

  const articleFields = [
    'articleId',
    'image',
    'name',
    'articleExtId',
    'ean',
    'asin',
    'articleNumber',
    'attributes',
    'variants',
    'variationNumber',
    'stock',
    'stockLabel',
    'avgPrice',
    'orders',
    'revenue',
    'margin',
    'returns',
    'activityNormal',
    'activityCritical',
    'recommendationsToday',
    'recommendationsTotal',
    'activeMarketsData',
    'salesRank',
    'salesRankTending',
    'productHealth'
  ];

  function getCsvHeaders(
    responseData: Array<ProductListRow>,
    summary: Array<TableHeaderCell>
  ): Array<{ label: string; key: string }> {
    if (responseData.length === 0) {
      return [];
    }

    const labelsNotInSummary = new Map<string, string>([
      ['name', 'component:product'],
      ['ean', 'component:ean'],
      ['asin', 'component:asin'],
      ['articleNumber', 'component:sku'],
      ['variants', 'component:variants'],
      ['articleExtId', 'component:parent_id'],
      ['activityNormal', 'component:activity_normal'],
      ['activityCritical', 'component:activity_critical'],
      ['variationNumber', 'component:bundle_variationId'],
      ['recommendationsToday', 'component:recommendations_today'],
      ['recommendationsTotal', 'component:recommendations_total'],
      ['stockLabel', 'filter:stock'],
      ['salesRankTending', 'component:sales_rank_tending'],
      ['attributes', 'component:attributes']
    ]);

    const excludedKeys = new Set(['image', 'articleId']);

    const minimumColumns = new Set([
      ...labelsNotInSummary.keys(),
      ...summary.map((s) => s.field)
    ]);

    const firstRow = responseData[0];

    const getLabel = (key: string): string => {
      const summaryColumn = summary.find((col) => col.field === key);
      if (summaryColumn) {
        return summaryColumn.label;
      }
      if (labelsNotInSummary.has(key)) {
        return labelsNotInSummary.get(key)!;
      }
      return key;
    };

    const result = articleFields
      .filter(
        (key) =>
          !excludedKeys.has(key) &&
          Object.prototype.hasOwnProperty.call(firstRow, key)
      )
      .filter((key) => minimumColumns.has(key))
      .map((key) => ({
        label: translate(getLabel(key)),
        key
      }));

    return result;
  }

  function getCsvRows(
    headers: Array<{ label: string; key: string }>,
    responseData: Array<ProductListRow>
  ): Array<{ [key: string]: string | number }> {
    const dot = new Intl.NumberFormat(currentLang.value);
    const dotDecimal = new Intl.NumberFormat(currentLang.value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
    return responseData.map((row) => {
      const fRow: { [key: string]: string | number } = {};
      headers.forEach(({ key }) => {
        const value = row[key as keyof ProductListRow];
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          switch (key) {
            case 'ean':
            case 'asin':
            case 'name':
            case 'variants':
            case 'articleNumber':
            case 'activityNormal':
            case 'stock':
            case 'orders':
            case 'returns':
            case 'variationNumber':
            case 'activityCritical':
            case 'articleExtId':
            case 'recommendationsTotal':
            case 'recommendationsToday':
            case 'salesRank':
            case 'salesRankTending':
            case 'productHealth': {
              fRow[key] = value as string | number;
              break;
            }
            case 'stockLabel': {
              const stock_label = translate(`component:${value as string}`);
              fRow[key] = stock_label;
              break;
            }
            case 'avgPrice':
            case 'revenue':
            case 'margin': {
              const res = dotDecimal.format(unknownToNumber(value));
              fRow[key] = res;
              break;
            }
            case 'activeMarketsData': {
              if (row.activeMarketsData.length === 0) {
                fRow[key] = '-';
              } else {
                fRow[key] = row.activeMarketsData
                  .map((attr) => `${attr.marketName}`)
                  .join(', ');
              }
              break;
            }
            case 'attributes': {
              if (row.attributes.length === 0) {
                fRow[key] = '-';
              } else {
                fRow[key] = row.attributes
                  .map(
                    (attr) => `${attr.attributeText}: ${attr.attributeValue}`
                  )
                  .join(', ');
              }
              break;
            }

            default: {
              fRow[key] = value as string;
              break;
            }
          }
        }
      });
      return fRow;
    });
  }

  const generateFilename = (date: DateRangeFilter): string => {
    const translatedTitle = translate('component:product_listing');
    const startDate = date.startFilter;
    const endDate = date.endFilter;

    return `${translatedTitle} ${startDate}-${endDate}`;
  };

  const processCsvExportData = (rows: Array<ProductListRow>): CSVExportData => {
    const headers = getCsvHeaders(rows, summary);
    const data = getCsvRows(headers, rows);
    const fileName = generateFilename(filters.timePeriod);
    return { headers, data, date: filters.timePeriod, fileName };
  };

  const activeMarketIdsSize = useMemo(() => {
    const am = new Set<number>();
    rows.forEach((r) => {
      r.activeMarketsData.forEach((aMarket) => am.add(aMarket.marketId));
    });
    return am.size;
  }, [rows]);

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 1,
        field: 'stock',
        label: translate('stock'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => unknownToNumber(r.stock))
            .reduce((prev, next) => prev + next, 0),
          value2: stock,
          align: 'left',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'qty'
        }
      },
      {
        id: 2,
        field: 'avgPrice',
        label: translate('av_price'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: divideIfNotZero(
            rows.map((r) => r.avgPrice).reduce((prev, next) => prev + next, 0),
            rows.length
          ),
          value2: avgPrice,
          align: 'center',
          paddingRight: '8px',
          calculation: 'average',
          type: 'currency'
        }
      },
      {
        id: 3,
        field: 'orders',
        label: translate('orders'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.orders)
            .reduce((prev, next) => prev + next, 0),
          value2: orders,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'qty'
        }
      },
      {
        id: 4,
        field: 'revenue',
        label: translate('net_revenue'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.revenue)
            .reduce((prev, next) => prev + next, 0),
          value2: revenue,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 5,
        field: 'margin',
        label: translate('margin'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.margin)
            .reduce((prev, next) => prev + next, 0),
          value2: margin,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 6,
        field: 'returns',
        label: translate('return'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.returns)
            .reduce((prev, next) => prev + next, 0),
          value2: returns,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'qty'
        }
      },
      {
        id: 7,
        field: 'activity',
        label: translate('activity'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(${activityNormal}/${activityCritical})`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 8,
        field: 'recommendations',
        label: translate('recommendations'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(0/0)`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 9,
        field: 'activeMarketsData',
        label: translate('active_markets'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: activeMarketIdsSize,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'qty'
        }
      },
      {
        id: 10,
        field: 'salesRank',
        label: translate('sales_rank'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 11,
        field: 'productHealth',
        label: translate('product_health'),
        align: 'right',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      }
    ],
    [currentLang, rows]
  );

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getProducts = (
    filters: ProductListFilterBarState,
    pagination: Pagination,
    sortBy: SortBy,
    variant?: string
  ) => {
    cancelFetch();
    dispatch(
      fetchProductItems(
        filters,
        pagination,
        sortBy,
        abortController.current,
        variant
      )
    );
  };

  const getCsvProductList = async (
    pagination: Pagination,
    sortBy: SortBy,
    signal: AbortSignal
  ) => fetchProductItemsData(filters, pagination, sortBy, signal, '', true);

  return {
    rows,
    count,
    stock,
    avgPrice,
    orders,
    revenue,
    margin,
    returns,
    activityNormal,
    activityCritical,
    filters,
    APIStatus,
    error,
    summary,
    getProductsUrlFilterValues,
    cancelFetch,
    getProducts,
    processCsvExportData,
    getCsvProductList
  };
}

export default useProductItems;
