import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { PurchaseArticlesState } from '../../@types/reduxStates';
import { PurchaseArticlesReponse } from '../../@types/responsesAPI';

const initialState: PurchaseArticlesState = {
  response: {
    rows: [],
    total: {
      articleExtId: '',
      articleId: '',
      articleImage: '',
      articleName: '',
      articleEan: '',
      articleAsin: '',
      articleSku: '',
      articleVariants: 0,
      articleStock: 0,
      stockLabel: '',
      purchasePrice: 0,
      netProductValue: 0,
      orderedUnits: 0,
      receivedUnits: 0,
      estimatedTimeOfArrival: '',
      actualArrivalTime: '',
      stockLocation: '',
      delayDelivery: 0,
      actualStatus: '',
      shipCountry: ''
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'purchaseArticles',
  initialState,
  reducers: {
    getPurchaseArticles: (state: PurchaseArticlesState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getPurchaseArticlesSuccess: (
      state: PurchaseArticlesState,
      action: PayloadAction<PurchaseArticlesReponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getPurchaseArticlesError: (
      state: PurchaseArticlesState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchPurchaseArticles(
  purchaseId: string,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(getPurchaseArticles());
    try {
      const response = await axios.get(
        `/api/v1/purchaseArticles/${purchaseId}`,
        { signal: abortController.signal }
      );
      dispatch(getPurchaseArticlesSuccess(response.data));
    } catch (error) {
      dispatch(getPurchaseArticlesError(error as APIError));
    }
  };
}

export default slice.reducer;

export const {
  getPurchaseArticles,
  getPurchaseArticlesSuccess,
  getPurchaseArticlesError
} = slice.actions;
