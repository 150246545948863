import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Paper } from '@material-ui/core';
// utils
import { useTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';
import useLocales from '../../hooks/useLocales';
import { APIError, APIStatus } from '../../@types/APIStatus';
import FormatedComplexText from './FormattedComplexText';
import { CardLoading } from './CardLoading';
import { CardError } from './CardError';
import { VariantSize } from '../../@types/text';
import useAuth from '../../hooks/useAuth';

export type ComplexText = {
  value: number;
  type: 'currency' | 'numeric' | 'percentage';
  wrap?: 'bar' | 'parentesis';
  pre?: string;
  post?: string;
  showInChart?: boolean;
  variant?: VariantSize;
};

type DataResumeTotalProps = {
  title: string;
  values: Array<ComplexText>;
  // chartData: ChartDataSerieNum;
  chartData: Array<{ x: number; y: number }>;
  state: APIStatus;
  error?: APIError;

  // percent?: number;
  // total: number;
  // type?: 'euro' | 'numeric';
};

export function DataResumeTotal({
  title,
  values,
  chartData,
  state,
  error
}: DataResumeTotalProps) {
  const theme = useTheme();
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const currency = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso
  });
  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  const MAX_BARS_NUM = 5;

  const chartOptions: ApexOptions = {
    colors: [theme.palette.primary.main],
    chart: {
      toolbar: { show: false },
      sparkline: { enabled: true },
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: false
        }
      }
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        columnWidth: '68%',
        borderRadius: 2
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter(y: any) {
          const valueInchart = values.find((v) => v.showInChart) ?? values[0];
          switch (valueInchart.type) {
            case 'currency':
              return currency.format(y);
            case 'percentage':
              return percent.format(y / 100);
            default:
              return new Intl.NumberFormat(currentLang.value).format(y);
          }
        }
      },
      marker: { show: false }
    }
  };

  const processData = (
    chartData: Array<{ x: number; y: number }>,
    maxBarsNum: number
  ) => {
    const numTotal = chartData.length;
    let bars = [];

    if (numTotal > maxBarsNum) {
      const daysPerBar = Math.ceil(numTotal / maxBarsNum);
      let currentSum = 0;
      let count = 0;
      let pos = 0;

      for (let i = 0; i < numTotal; i++) {
        currentSum += chartData[i].y;
        count++;

        if (count === daysPerBar || i === numTotal - 1) {
          bars.push({
            x: pos,
            y: Number(currentSum.toFixed(2))
          });
          currentSum = 0;
          count = 0;
          pos++;
        }
      }
    } else {
      bars = chartData.map((data) => ({
        x: data.x,
        y: Number(data.y.toFixed(2))
      }));
    }
    return bars;
  };

  const processedData = useMemo(
    () => ({
      options: chartOptions,
      data: processData(chartData, MAX_BARS_NUM)
    }),
    [chartData, chartOptions]
  );

  return (
    <Paper variant="outlined" sx={{ position: 'relative' }}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      <Typography variant="h6" sx={{ padding: '16px', paddingBottom: '0px' }}>
        {translate(title)}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              mt: 1.5,
              mb: 0.5,
              display: 'flex',
              alignItems: 'center'
            }}
          />
          <FormatedComplexText texts={values} />
        </Box>

        <Box sx={{ width: '100px', height: '65px' }}>
          <ReactApexChart
            type="bar"
            series={[{ data: processedData.data, name: title }]}
            options={processedData.options}
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
    </Paper>
  );
}
