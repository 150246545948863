import { Typography } from '@material-ui/core';
import { VariantSize } from '../../@types/text';
import useLocales from '../../hooks/useLocales';

type PercentageProps = {
  percentage: number;
  fractionDigits?: number;
  // parentesis?: boolean;
  variantSize?: VariantSize;
};

function Percentage({
  percentage,
  variantSize,
  fractionDigits = 2
}: PercentageProps) {
  const { currentLang } = useLocales();
  return (
    <Typography variant={variantSize} component="span">
      {new Intl.NumberFormat(currentLang.value, {
        style: 'percent',
        maximumFractionDigits: fractionDigits
      }).format(percentage / 100)}
    </Typography>
  );
}

export default Percentage;
