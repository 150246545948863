import {
  makeStyles,
  Theme,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { Icon } from '@iconify/react';
import { format, subDays } from 'date-fns';
import { ChipFilter } from '../filters/ChipFilter';
import SelectTimePeriod from '../filters/SelectTimePeriod';
import { FilterSelect } from '../items/filters/FilterSelect';
import useLocales from '../../hooks/useLocales';
import { OrderFiltersState } from '../../@types/filterBars';
import FilterSlide from '../items/filters/FilterSlide';
import { usefilterBarStyles } from '../../theme/forms/FilterBarClasses';
import useOrdersTab from '../../hooks/useOrdersTab';
import { APIStatus } from '../../@types/APIStatus';

import useFilters from '../../hooks/useFilters';
import {
  CountrySelectItem,
  SourceSelectItem,
  StatusSelectItem,
  SortBy
} from '../../@types/filters';

import { useCopyFilters } from '../../hooks/useCopyFilters';
import AutocompleteMulti from '../general/form/AutocompleteMulti';

const useStyles = makeStyles<Theme>((theme) => ({
  returns: {
    paddingLeft: theme.spacing(2)
  },
  content: {
    width: '100%',
    backgroundColor: 'white'
  }
}));

type OrdersFilterBarProps = {
  page: number;
  rowsPerPage: number;
  sortBy: SortBy;
  setInitialPage: () => void;
  articleId?: string;
  tab?: string;
};

export function OrdersFilterBar({
  page,
  rowsPerPage,
  sortBy,
  setInitialPage,
  articleId,
  tab = ''
}: OrdersFilterBarProps) {
  const { t: translate } = useTranslation(['component']);
  const filterClasses = usefilterBarStyles();
  const classesLocal = useStyles();
  const { currentLang } = useLocales();

  const {
    response: {
      sources,
      orderStatus,
      countries,
      customers,
      orders: { maxMargin, maxRevenue, minMargin, minRevenue }
    }
  } = useFilters();

  const {
    data: {
      orderList: { APIStatus: state }
    },
    updateOrders,
    getOrdersUrlFilterValues,
    cancelOrderListFetch
  } = useOrdersTab();
  const { copyUrlToClipboard, iconData } = useCopyFilters();

  const initialFilterValues = useMemo(
    () =>
      getOrdersUrlFilterValues({
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        amount: {
          range: { min: minRevenue, max: maxRevenue },
          config: { min: minRevenue, max: maxRevenue }
        },
        margin: {
          range: { min: minMargin, max: maxMargin },
          config: { min: minMargin, max: maxMargin }
        },
        countryId: 0,
        returns: false,
        text: '',
        status: 0,
        channelTags: [],
        customers: []
      }),
    []
  );

  const [filterBar, setFilterBar] =
    useState<OrderFiltersState>(initialFilterValues);

  const [orderStatusLabels, setOrderStatusLabels] = useState<
    Array<{ label: string; id: number }>
  >([]);

  useEffect(() => {
    if (state !== APIStatus.IDLE) {
      setInitialPage();
    }
  }, [filterBar]);

  useEffect(() => {
    if (state !== APIStatus.PENDING) {
      updateOrders(
        filterBar,
        { skip: page * rowsPerPage, limit: rowsPerPage },
        sortBy,
        articleId
      );
    }
    return () => {
      cancelOrderListFetch();
    };
  }, [filterBar, sortBy, page, rowsPerPage]);

  useEffect(() => {
    const labels: Array<{ label: string; id: number }> = [];
    orderStatus.map((oStatus: StatusSelectItem, i: number) => {
      const labelDE =
        oStatus.orderStatusNameDE && oStatus.orderStatusNameDE.length > 0
          ? oStatus.orderStatusNameDE
          : '-';
      const labelEN =
        oStatus.orderStatusNameEN && oStatus.orderStatusNameEN.length > 0
          ? oStatus.orderStatusNameEN
          : '-';
      if (currentLang.value === 'de-DE') {
        if (labelDE.length > 0) {
          labels.push({
            label: labelDE,
            id: oStatus.orderStatusId
          });
        } else {
          labels.push({
            label: labelEN,
            id: oStatus.orderStatusId
          });
        }
      } else if (currentLang.value === 'en-GB') {
        if (labelEN.length > 0) {
          labels.push({
            label: labelEN,
            id: oStatus.orderStatusId
          });
        } else {
          labels.push({
            label: labelDE,
            id: oStatus.orderStatusId
          });
        }
      }
    });
    setOrderStatusLabels(labels);
  }, []);

  return (
    <Paper elevation={5} className={filterClasses.root}>
      <Grid container spacing={2} sx={{ padding: '16px', paddingTop: '0px' }}>
        <Grid item xs={12} md={6}>
          {/*  Sources (Channels)  */}
          <AutocompleteMulti
            onClose={(ev, reason) => {}}
            onOpen={(ev) => {}}
            label={translate('filter:channel')}
            placeholder={translate('component:select_channels')}
            valueSelected={filterBar.channelTags}
            options={sources}
            onChange={(ev, value) =>
              setFilterBar((old) => ({ ...old, channelTags: value }))
            }
            getOptionLabel={(option) =>
              option.sourceName ? option.sourceName : ''
            }
            getOptionSelected={(option, value) =>
              option.sourceId === value.sourceId
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Customers */}
          <AutocompleteMulti
            onClose={(ev, reason) => {}}
            onOpen={(ev) => {}}
            label={translate('filter:customer_class')}
            placeholder={translate('filter:select_customer')}
            valueSelected={filterBar.customers}
            options={customers}
            onChange={(ev, value) =>
              setFilterBar((old) => ({
                ...old,
                customers: value
              }))
            }
            getOptionLabel={(option) =>
              option.customerName ? option.customerName : ''
            }
            getOptionSelected={(option, value) =>
              option.customerId === value.customerId
            }
          />
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={2}>
            <FilterSlide
              type="euro"
              label={translate('amount')}
              values={{
                range: {
                  min: filterBar.amount.range.min,
                  max: filterBar.amount.range.max
                },
                config: {
                  min: filterBar.amount.config.min,
                  max: filterBar.amount.config.max
                }
              }}
              onValueChange={(value) => {
                setFilterBar((old) => ({
                  ...old,
                  amount: {
                    range: { min: value[0], max: value[1] },
                    config: old.amount.config
                  }
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FilterSlide
              type="percent"
              label={translate('margin')}
              values={{
                range: {
                  min: filterBar.margin.range.min,
                  max: filterBar.margin.range.max
                },
                config: {
                  min: filterBar.margin.config.min,
                  max: filterBar.margin.config.max
                }
              }}
              onValueChange={(value) => {
                setFilterBar((old) => ({
                  ...old,
                  margin: {
                    range: { min: value[0], max: value[1] },
                    config: old.margin.config
                  }
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FilterSelect
              disabled={
                filterBar.returns === true || state === APIStatus.PENDING
              }
              selected={
                orderStatus.findIndex(
                  (s) => Number(s.orderStatusId) === filterBar.status
                ) + 1
              }
              title={translate('status')}
              labels={[
                translate('filter:all'),
                ...orderStatusLabels.map((os) => os.label)
              ]}
              onChangeSelected={(option) => {
                setFilterBar((old) => ({
                  ...old,
                  status:
                    option === 0 ? 0 : Number(orderStatusLabels[option - 1].id)
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FilterSelect
              disabled={state === APIStatus.PENDING}
              selected={
                countries.findIndex(
                  (c: CountrySelectItem) => c.countryId === filterBar.countryId
                ) + 1
              }
              title={translate('filter:country')}
              labels={[
                translate('filter:all_countries'),
                ...countries.map((c) => c.countryName)
              ]}
              onChangeSelected={(option) => {
                const cId = option === 0 ? 0 : countries[option - 1].countryId;
                setFilterBar((old) => ({
                  ...old,
                  countryId: cId
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <FormControlLabel
              disabled={state === APIStatus.PENDING}
              checked={filterBar.returns}
              value={filterBar.returns}
              onChange={(ev: React.SyntheticEvent, checked: boolean) =>
                setFilterBar((old) => ({ ...old, returns: checked }))
              }
              control={<Checkbox />}
              label={translate('returns')}
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={3} justifyContent="flex-end">
            <SelectTimePeriod
              disabled={state === APIStatus.PENDING}
              startFilter={filterBar.timePeriod.startFilter}
              endFilter={filterBar.timePeriod.endFilter}
              onChangePeriod={(timePeriod) =>
                setFilterBar((old) => ({ ...old, timePeriod }))
              }
            />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Tooltip title={iconData.tooltip}>
              <IconButton
                color="primary"
                aria-label="copy url"
                onClick={() => {
                  copyUrlToClipboard(filterBar, {
                    page,
                    rowsPerPage,
                    by: sortBy.by,
                    order: sortBy.order
                  });
                }}
              >
                <Icon icon={iconData.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
