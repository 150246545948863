import { Box, Paper, Typography } from '@material-ui/core';
import { ReactNode } from 'react';
import { useOrderStyles } from './styles';
import { textStyles } from '../../theme/TextClasses';

type Props = {
  title: string;
  children: ReactNode;
};

function HeaderWidget({ title, children }: Props) {
  const classes = useOrderStyles();
  const textClasses = textStyles({});
  return (
    <Paper variant="outlined" className={classes.widget}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          component="span"
          variant="body1"
          className={`${textClasses.ellipsis} ${classes.headerWidgetTitle}`}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
}

export default HeaderWidget;
