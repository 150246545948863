import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  Theme,
  useMediaQuery,
  useTheme,
  Box,
  makeStyles
} from '@material-ui/core';
import { ByChannelReponse } from '../../@types/responsesAPI';
import useLocales from '../../hooks/useLocales';

const useStyles = makeStyles<Theme, { height: number }>((theme) => ({
  content: {
    height: ({ height }) => height,
    width: '100%'
  }
}));

type Props = {
  data: ByChannelReponse;
  onSourceSelect: (index: number) => void;
};

function PieChart({ data, onSourceSelect }: Props) {
  const theme: Theme = useTheme();
  const under_lg = useMediaQuery(theme.breakpoints.down('lg'));
  const { currentLang } = useLocales();
  const classes = useStyles({ height: 420 });
  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });
  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: 'EUR'
  });
  const [indexSelected, setIndexSelected] = useState<number>(-1);
  const percentages = data.map((item) => item.revenuePercent);

  useEffect(() => {
    onSourceSelect(indexSelected);
  }, [indexSelected]);

  const [pie] = useState<{ series: Array<any>; options: ApexOptions }>({
    series: data.map((item) => item.revenue),
    options: {
      chart: {
        // width: 480,
        // type: 'pie',
        events: {
          dataPointMouseEnter(event, chartContext, config) {
            setIndexSelected((prev) => config.dataPointIndex);
          },
          dataPointMouseLeave(event, chartContext, config) {
            setIndexSelected((prev) => -1);
          },
          legendClick(chartContext, seriesIndex, config) {
            setIndexSelected((prev) => {
              if (prev === seriesIndex || seriesIndex === undefined) return -1;
              return seriesIndex;
            });
          }
        }
      },
      labels: data.map((item) => item.name.split('-')[0].replace(/\s/g, '')),
      legend: {
        show: true,
        width: under_lg ? undefined : 220,
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
        position: under_lg ? 'bottom' : 'right',
        formatter(seriesName: string, data: any) {
          return `${percent.format(
            percentages[data.seriesIndex] / 100
          )} ${seriesName}`;
        }
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false
        },
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        },
        y: {
          formatter(value, { series, seriesIndex, dataPointIndex, w }) {
            return `${euro.format(value)}`;
          }
          /* title: {
            formatter(seriesName: string) {
              return seriesName;
            }
          },
          formatter(y: number) {
            return euro.format(y);
          } */
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  });

  return (
    <Box className={classes.content}>
      <ReactApexChart
        options={pie.options}
        series={pie.series}
        type="pie"
        height="100%"
        width="100%"
      />
    </Box>
  );
}

export default PieChart;
