import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Paper,
  CircularProgress,
  Theme,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { APIStatus } from '../../@types/APIStatus';
import AreaChartFilter from '../filters/AreaChartFilter';
import usePriceChanges from '../../hooks/usePriceChanges';
import { AreaChartFiltersState } from '../../@types/filterBars';
import useLocales from '../../hooks/useLocales';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import useAuth from '../../hooks/useAuth';
import CardTableStatus from '../general/CardTableStatus';

type Props = {
  title?: string;
  articleId?: number;
};

export default function PriceChangesChart({
  title = 'price_changes_title',
  articleId = 0
}: Props) {
  const { t: translate } = useTranslation();
  const theme: Theme = useTheme();
  const { currentLang } = useLocales();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso
  });
  const {
    data: { avPrice, stock, xaxis, revenue },
    APIStatus: state,
    error,
    filters,
    getPriceChanges,
    updatePriceChanges
  } = usePriceChanges();

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);
  const [loaded, setLoaded] = useState<boolean>(false);

  /* const seriesReduced = useMemo(() => {
    if(xaxis.length > 62){


    }
  }, [filterBar]) */

  const {
    article: { articleId: idArticle }
  } = useArticlesDetail();

  const series = [
    {
      name: translate('component:revenue'),
      data: revenue,
      type: 'area'
    },
    {
      name: translate('component:average_price'),
      data: avPrice,
      type: 'area'
    },
    {
      name: translate('component:stock'),
      data: stock,
      type: 'column'
    }
  ];

  const options: ApexOptions = {
    colors: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main
    ],
    fill: {
      type: 'solid',
      colors: [
        theme.palette.primary.main,
        theme.palette.success.main,
        theme.palette.warning.main,
        theme.palette.error.main
      ],
      opacity: [0.2, 0.2, 0.8]
    },
    stroke: {
      colors: [
        theme.palette.primary.main,
        theme.palette.success.main,
        theme.palette.warning.main,
        theme.palette.error.main
      ],
      width: [3, 3, 0],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 3
      }
    },
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },

    xaxis: {
      categories: xaxis,
      type: 'datetime',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily,
          colors: theme.palette.grey[500]
        },
        // show: showLabels,
        formatter(value: any) {
          // return new Date(value * 1000).toLocaleDateString(currentLang.value);
          return new Date(value).toLocaleDateString(currentLang.value);
        },
        rotate: isMobile ? -90 : -45
      }
    },
    yaxis: [
      {
        seriesName: translate('component:revenue'),
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: theme.typography.fontFamily,
            colors: [theme.palette.grey[500]]
          },
          formatter: (value: any) => euro.format(value)
        },
        title: {
          text: !isMobile ? `${translate('component:revenue')}` : undefined,
          style: {
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.grey[500]
          }
        }
      },
      {
        seriesName: translate('component:average_price'),
        opposite: true,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: theme.typography.fontFamily,
            colors: [theme.palette.grey[500]]
          },
          formatter: (value: any) => euro.format(value)
        },
        title: {
          text: !isMobile
            ? `${translate('component:average_price')}`
            : undefined,
          style: {
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.grey[500]
          }
        }
      }
    ],
    legend: {
      horizontalAlign: isMobile ? 'center' : 'right',
      position: isMobile ? 'bottom' : 'top',
      show: true,
      fontSize: '13',
      markers: { radius: 12 },
      itemMargin: { horizontal: 12 },
      labels: {
        colors: [
          theme.palette.primary.main,
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.error.main
        ]
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        fillColors: [
          theme.palette.primary.main,
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.error.main
        ]
      },
      style: {
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily
      },
      x: {
        show: false
      },
      y: {
        formatter(y: any, { seriesIndex }: any) {
          switch (seriesIndex) {
            case 1:
            case 0:
              return euro.format(y);
            default:
              return `${y}`;
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2],
      offsetY: -10,
      style: {
        fontSize: '13px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        colors: [theme.palette.warning.main]
      },
      formatter(value: any) {
        return value;
      }
    },
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider
    },
    chart: {
      // animations: { enabled: false},
      zoom: {
        enabled: !isMobile && xaxis.length > 62,
        type: 'x'
        /* resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
        }, */
        /* selection: {
            background: '#90CAF9',
            border: '#0D47A1'
        }  */
      },
      offsetY: 30,
      toolbar: { show: !isMobile },
      // zoom: { enabled: !isMobile },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88
        }
      }
    }
  };

  useEffect(() => {
    if (state === APIStatus.IDLE || articleId !== idArticle) {
      getPriceChanges(articleId);
    }
  }, []);

  useEffect(() => {
    if (
      (state === APIStatus.FULFILLED || state === APIStatus.REJECTED) &&
      loaded
    ) {
      updatePriceChanges(filterBar, articleId);
    } else {
      setLoaded(true);
    }
  }, [filterBar]);

  return (
    <AreaChartFilter
      ready={
        (state === APIStatus.FULFILLED || state === APIStatus.REJECTED) &&
        loaded
      }
      title={title}
      filters={filters}
      onFiltersChange={setFilterBar}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Paper
            variant="outlined"
            sx={{
              py: 2.5,
              textAlign: 'center',
              minHeight: '377px',
              position: 'relative'
            }}
          >
            <CardTableStatus
              isLoading={state === APIStatus.PENDING}
              isError={error !== undefined}
              isNoResult={false}
            />
            {state === APIStatus.FULFILLED && (
              <ReactApexChart
                // type={series[0].data.length === 1 ? 'bar' : 'area'}
                series={series}
                xaxis={xaxis}
                options={options}
                height={320}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </AreaChartFilter>
  );
}
