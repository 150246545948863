import { Box } from '@material-ui/core';

export interface ICountryFlagProps {
  code: string;
  widthPx: number;
}

export function CountryFlag(props: ICountryFlagProps) {
  const { code, widthPx } = props;
  const getImageSource = (code: string | undefined) => {
    if (!code || code.length === 0) {
      return '/static/placeholder.svg';
    }
    return `/static/icons/flags/${code.toLowerCase()}.svg`;
  };
  const imageSource = getImageSource(code);
  return <Box component="img" alt="flag" src={imageSource} width={widthPx} />;
}
