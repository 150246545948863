import { useDispatch, useSelector } from 'react-redux';
import { setConfig } from '../redux/slices/config';

// ----------------------------------------------------------------------

function useConfig() {
  const dispatch = useDispatch();
  const { config } = useSelector((state: { config: any }) => state.config);

  return {
    config,
    getConfig: () => dispatch(setConfig())
  };
}

export default useConfig;
