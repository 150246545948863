import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import {
  fetchFilterValues,
  fetchCampaigns,
  fetchCampaignTypes,
  fetchCampaignStatus,
  fetchAdGroups,
  fetchOnlineMarketingRanges
} from '../redux/slices/filters';

// ----------------------------------------------------------------------

function useFilters() {
  const dispatch = useDispatch();
  const { response, APIStatus, error, filtersStatus } = useSelector(
    (state: RootState) => state.filters
  );

  return {
    response,
    APIStatus,
    error,
    filtersStatus,
    getFilterValues: () => dispatch(fetchFilterValues()),
    getCampaignOptions: () => dispatch(fetchCampaigns()),
    getCampaignTypesOptions: () => dispatch(fetchCampaignTypes()),
    getCampaignStatusOptions: () => dispatch(fetchCampaignStatus()),
    getAdGroups: () => dispatch(fetchAdGroups()),
    getOnlineMarketingRanges: () => dispatch(fetchOnlineMarketingRanges())
  };
}

export default useFilters;
