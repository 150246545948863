import { Icon } from '@iconify/react';
import { Box, Theme, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  isRead: boolean;
  onRead: () => void;
  onOpenEmailModal: () => void;
  onRequestCSV: () => void;
};

const useStyles = makeStyles<Theme>((theme) => ({
  hidden: {
    display: 'none'
  },
  actionLink: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& a': {
      color: theme.palette.grey[700],
      textDecoration: 'none'
    }
  },
  email: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.grey[700],
    textDecoration: 'none',
    paddingLeft: '8px',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

function Actions({ isRead, onRead, onOpenEmailModal, onRequestCSV }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const classes = useStyles();
  const cursor = isRead ? 'default' : 'pointer';

  return (
    <Box>
      <Box
        component="a"
        onClick={() => {
          if (!isRead) onRead();
        }}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Icon
          icon="ic:baseline-remove-red-eye"
          width="18px"
          color={isRead ? theme.palette.grey[400] : theme.palette.grey[600]}
        />
        <Typography
          textAlign="center"
          variant="body1"
          color={isRead ? theme.palette.grey[400] : theme.palette.primary.main}
          sx={{
            paddingLeft: '8px',
            paddingBottom: '2px',
            '&:hover': { cursor }
          }}
        >
          {isRead ? translate('read') : translate('mark_as_read')}
        </Typography>
      </Box>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        className={classes.actionLink}
        onClick={() => onRequestCSV()}
      >
        <Icon
          icon="ic:baseline-download"
          width="18px"
          color={theme.palette.grey[600]}
        />
        <Typography
          component="span"
          variant="body1"
          fontWeight={500}
          className={classes.email}
        >
          {translate('download_list')}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Icon icon="mdi:email" width="18px" color={theme.palette.grey[600]} />
        <Link
          to="#"
          className={classes.email}
          onClick={() => onOpenEmailModal()}
        >
          {translate('send_email')}
        </Link>
      </Box>
    </Box>
  );
}

export default Actions;
