import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import useOrdersTab from '../../hooks/useOrdersTab';
import AreaChartFilter from '../filters/AreaChartFilter';
import PolarAreaComponent from '../general/PolarAreaComponent';

type Props = {
  title?: string;
  articleId?: number;
};

export default function RevenueByChannel({
  title = 'revenue_by_channel',
  articleId = 0
}: Props) {
  const { t: translate } = useTranslation(['component']);

  const {
    data: {
      revenueByChannel: { APIStatus: state, data, filters, error }
    },
    APIStatus: tabState,
    updateRevenueByChannel
  } = useOrdersTab();

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);
  const [loaded, setLoaded] = useState<boolean>(false);

  /* useEffect(() => {
    if (state === APIStatus.IDLE) {
      updateRevenueByChannel(filterBar, articleId);
    }
  }, [articleId]);

  useEffect(() => {
    if (
      (state === APIStatus.FULFILLED || state === APIStatus.REJECTED) &&
      loaded
    ) {
      updateRevenueByChannel(filterBar, articleId);
    } else {
      setLoaded(true);
    }
  }, [filterBar]); */

  useEffect(() => {
    updateRevenueByChannel(filters, articleId);
  }, [filterBar]);

  return (
    <AreaChartFilter
      showCountry={false}
      showSources={false}
      title={translate(title)}
      filters={filters}
      onFiltersChange={setFilterBar}
    >
      {state === APIStatus.FULFILLED && (
        <PolarAreaComponent data={data} state={state} error={error} />
      )}
    </AreaChartFilter>
  );
}
