import {
  alpha,
  Box,
  CircularProgress,
  makeStyles,
  Theme
} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    // border: '1px solid red',
    position: 'absolute',
    borderRadius: theme.spacing(2),
    backgroundColor: alpha('#fff', 0.6),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 11,
    transition: 'background-color 0.3s'
  }
}));

export function CardLoading() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress />
    </Box>
  );
}
