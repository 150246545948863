import { Icon } from '@iconify/react';
import { Box, Theme, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLocales from '../../../hooks/useLocales';

type Props = { event: number; translation: string; periodDays: string };

function MessageEvent({ event, translation, periodDays }: Props) {
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();

  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });

  function getIcon(event: number): string {
    switch (event) {
      case 1:
        return 'tabler:truck-return';
      case 2:
        return 'bi:cash-coin';
      default:
        return 'ant-design:stock-outlined';
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.primary.main,
          width: '42px',
          height: '42px',
          borderRadius: '50%'
        }}
      >
        <Icon
          icon={getIcon(event)}
          width="32px"
          color={theme.palette.common.white}
        />
      </Box>
      <Typography textAlign="center" variant="body1">
        {translation}
      </Typography>
      <Typography
        textAlign="center"
        variant="caption"
        color={theme.palette.grey[400]}
      >
        {translate(periodDays.toLowerCase())}
      </Typography>
    </Box>
  );
}

export default MessageEvent;
