import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type selectProduct = {
  selectedProduct: Object;
};

const initialState: selectProduct = {
  selectedProduct: {
    name: '',
    articleName: '',
    articleId: 0
  }
};

const slice = createSlice({
  name: 'selectedProduct',
  initialState,
  reducers: {
    setProduct(state, action) {
      state.selectedProduct = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setProduct } = slice.actions;
