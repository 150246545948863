import { useDispatch, useSelector } from 'react-redux';
// redux
import { RootState } from '../redux/store';
import { menuTree } from '../redux/slices/menu';

// ----------------------------------------------------------------------

export default function useMenu() {
  const dispatch = useDispatch();
  const { menu, APIStatus } = useSelector((state: RootState) => state.menuTree);

  return {
    APIStatus,
    method: 'jwt',
    menu,
    getMenu: (iso: string) => dispatch(menuTree(iso))
  };
}
