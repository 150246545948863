import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Theme,
  makeStyles,
  useMediaQuery,
  Tabs,
  Paper,
  Typography,
  Button
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

import Page from '../components/Page';
import useConfig from '../hooks/useConfig';
import { useOrderStyles } from '../components/order/styles';
import { textStyles } from '../theme/TextClasses';
import SidebarIcon from '../components/general/SidebarIcon';
import ic_cart from '../lotties/ic_cart.json';
import { HeaderBalance } from '../components/order/HeaderBalance';
import HeaderWidget from '../components/order/HeaderWidget';
import usePurchaseDetail from '../hooks/usePurchaseDetail';
import { APIStatus } from '../@types/APIStatus';
import { helperStyles } from '../theme/HelperClasses';
import { ProgressCircle } from '../components/general/ProgressCircle';
import PurchaseStatusCell from '../components/general/tableCell/PurchaseStatusCell';
import ArticlesPurchase from '../components/stock/ArticlesPurchase';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0)
  }
}));

type Props = {};

function PurchaseDetail({}: Props) {
  const { id } = useParams<{ id: string }>();
  const classesOrder = useOrderStyles();
  const helperClasses = helperStyles();
  const classes = useStyles();
  const tabsRef = useRef<HTMLDivElement>(null);
  const textClasses = textStyles({});
  const [tabSelected, setTabSelected] = useState<string>('0');
  const { config } = useConfig();
  const { t: translate } = useTranslation(['menu', 'component']);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isUnderMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  const history = useHistory();

  const {
    APIStatus: state,
    response: {
      actualArrivalTime,
      estimatedTimeOfArrival,
      netProductValue,
      numberOfSku,
      orderDate,
      orderExtId,
      orderedUnits,
      receivedUnits,
      status,
      stockLocation,
      shipCountry,
      actualStatus
    },
    getPurchaseDetail,
    getPurchaseStatus
  } = usePurchaseDetail();

  useEffect(() => {
    getPurchaseDetail(id);
  }, [id]);

  if (state === APIStatus.REJECTED) {
    return <div>{translate('component:purchase_order_not_found')}</div>;
  }

  return (
    <Page
      title={`${config.company} - ${translate('component:purchase_detail')}`}
    >
      <Container maxWidth="xl" sx={{ marginTop: '8px' }}>
        <Grid item xs={12}>
          <Button variant="text" onClick={() => history.goBack()}>
            {translate('global:backLabel')}
          </Button>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classesOrder.container} variant="outlined">
              <Grid container spacing={4}>
                <Grid container spacing={2} item xs={12}>
                  <Grid item md={3} xs={12}>
                    <SidebarIcon
                      json={ic_cart}
                      stop={true}
                      width={isMobile ? 105 : 210}
                      height={isMobile ? 105 : 210}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      height: '220px',
                      marginLeft: isUnderMd ? '16px' : '0px'
                    }}
                    xs={12}
                    md={6}
                    className={classesOrder.description}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={6} md={4}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={500}
                          color="text.primary"
                        >
                          {translate('component:order_number')}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={8}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={700}
                          color="text.primary"
                          className={textClasses.ellipsis}
                        >
                          {orderExtId}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={6} md={4}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={500}
                          color="text.primary"
                        >
                          {translate('component:stock_location')}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={8}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={700}
                          color="text.primary"
                          className={textClasses.ellipsis}
                        >
                          {stockLocation}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={6} md={4}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={500}
                          color="text.primary"
                        >
                          {translate('component:ship_country')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={700}
                          color="text.primary"
                          className={textClasses.ellipsis}
                        >
                          {shipCountry}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={6} md={4}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={500}
                          color="text.primary"
                        >
                          {translate('component:order_datum')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <Typography
                          variant={isMobile ? 'body1' : 'h6'}
                          fontWeight={700}
                          color="text.primary"
                          className={textClasses.ellipsis}
                        >
                          {orderDate}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      height: '220px',
                      marginTop: '16px',
                      marginLeft: isUnderMd ? '16px' : '0px'
                    }}
                    md={3}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    direction={isUnderMd ? 'row' : 'column'}
                  >
                    <Grid item xs={isUnderMd ? 4 : 6} justifyContent="center">
                      <HeaderBalance
                        isMobile={isMobile}
                        title={translate('component:article_qty')}
                        value={orderedUnits}
                      />
                    </Grid>
                    <Grid item xs={isUnderMd ? 4 : 6} alignContent="center">
                      <HeaderBalance
                        isMobile={isMobile}
                        title={translate('component:number_of_sku')}
                        value={numberOfSku}
                      />
                    </Grid>
                    <Grid item xs={isUnderMd ? 4 : 6} alignContent="center">
                      <HeaderBalance
                        type="currency"
                        isMobile={isMobile}
                        title={translate('component:net_product_value')}
                        value={netProductValue}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  className={classesOrder.widgetRoot}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classesOrder.widgetContainer}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <HeaderWidget title={translate('component:order')}>
                        <Box className={`${classesOrder.centerBox} column`}>
                          <Grid container spacing={2}>
                            <Grid
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              xs={12}
                            >
                              <PurchaseStatusCell
                                purchaseStatusSelectItem={getPurchaseStatus(
                                  status
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </HeaderWidget>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <HeaderWidget
                        title={translate('component:actual_status')}
                      >
                        <Box className={`${classesOrder.centerBox} column`}>
                          <Grid container spacing={2}>
                            <Grid item justifyContent="center" xs={12}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                textAlign="center"
                              >
                                {actualStatus || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </HeaderWidget>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <HeaderWidget title={translate('component:the_units')}>
                        <Box className={classes.root}>
                          <Box className={helperClasses.row}>
                            <Box className={helperClasses.cell}>
                              <Typography
                                variant="body1"
                                className={textClasses.ellipsis}
                              >
                                {translate('component:ordered')}
                              </Typography>
                            </Box>
                            <Box className={helperClasses.cell}>
                              <Typography
                                variant="body1"
                                className={textClasses.ellipsis}
                              >
                                {translate('component:receive')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider className={classes.divider} />
                          <Box className={helperClasses.row}>
                            <Box
                              className={`${helperClasses.cell} ${helperClasses.maxHeight}`}
                              sx={{ paddingBottom: '16px' }}
                            >
                              <Typography variant="body1">
                                {orderedUnits}
                              </Typography>
                            </Box>
                            <Box
                              className={`${helperClasses.cell} ${helperClasses.maxHeight}`}
                              sx={{ paddingBottom: '16px' }}
                            >
                              <ProgressCircle
                                invert={false}
                                value={(receivedUnits / orderedUnits) * 100}
                                size={95}
                                centerLegend={
                                  <Box sx={{ paddingBottom: '4px' }}>
                                    <Typography align="center" variant="body1">
                                      {receivedUnits}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </HeaderWidget>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <HeaderWidget
                        title={translate('component:estimated_time_of_arrival')}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            paddingBottom: '32px',
                            paddingTop: '8px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={700}
                            color="text.primary"
                            align="center"
                          >
                            {estimatedTimeOfArrival.length > 0
                              ? estimatedTimeOfArrival
                              : '-'}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body1"
                            className={`${textClasses.ellipsis} ${classesOrder.headerWidgetTitle}`}
                          >
                            {translate('component:actual_arrival_time')}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={700}
                            color="text.primary"
                            align="center"
                          >
                            {actualArrivalTime.length > 0
                              ? actualArrivalTime
                              : '-'}
                          </Typography>
                        </Box>
                      </HeaderWidget>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid container item xs={12}>
            <TabContext value={tabSelected}>
              <Box
                className={classesOrder.boxContainer}
                component="div"
                ref={tabsRef}
              >
                <Tabs
                  className={classesOrder.tabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  value={tabSelected}
                  onChange={(e, v) => setTabSelected(v)}
                  aria-label="Purchase detail tabs"
                >
                  <Tab
                    className={classesOrder.tab}
                    label={translate('component:articles')}
                    value="0"
                  />
                </Tabs>
              </Box>
              <Paper className={classesOrder.tabContainer} variant="outlined">
                <Typography
                  variant="h5"
                  className={`${textClasses.ellipsis} ${classesOrder.tabTitle}`}
                >
                  {orderExtId}
                </Typography>

                <TabPanel value="0">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ArticlesPurchase id={id} />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default PurchaseDetail;
