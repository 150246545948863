import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  Paper,
  Typography,
  Box
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountryCell from './CountryCell';
import useLocales from '../../hooks/useLocales';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import TableRowSummary from '../general/TableRowSummary';
import Tending from '../general/Tending';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import { CardNoResult } from '../general/CardNoResult';
import { HeaderTableFilter } from '../../@types/filters';
import { RevenueByCountryResponse } from '../../@types/responsesAPI';
import { RevenueByCountryRow } from '../../@types/tableRows';
import useAuth from '../../hooks/useAuth';

type RevenueProps = {
  loading: boolean;
  error: boolean;
  data: RevenueByCountryResponse;
  revenueTotal: number;
  page: number;
  rowsPerPage: number;
  onRequestSort: (
    orderBy: string,
    order: 'asc' | 'desc',
    orderType?: HeaderTableFilter
  ) => void;
};

export default function RevenueByCountryTable(props: RevenueProps) {
  const {
    error,
    loading,
    data,
    page,
    rowsPerPage,
    revenueTotal,
    onRequestSort
  } = props;
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const classes = TableStyleClasses({ height: 65, numRows: rowsPerPage });
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('orders');
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  // Summary values
  const [summary, setSummary] = useState<{
    orders: number;
    phyOrders: number;
    revenue: number;
    returns: number;
    phyReturns: number;
    tending: number;
  }>({
    orders: 0,
    phyOrders: 0,
    revenue: 0,
    returns: 0,
    phyReturns: 0,
    tending: 0
  });

  const [emptyRows, setEmptyRows] = useState<number>(0);
  const countryLabel = translate('filter:country');
  const TABLE_HEAD = [
    {
      id_h: 'name',
      label: countryLabel.charAt(0).toUpperCase() + countryLabel.slice(1),
      align: 'left'
    },
    { id_h: 'tending', label: translate('tending'), align: 'center' },
    { id_h: 'orders', label: translate('order_qty'), align: 'center' },
    { id_h: 'phyOrders', label: translate('article_qty'), align: 'center' },
    { id_h: 'revenue', label: translate('order_rev'), align: 'center' },
    { id_h: 'orders', label: translate('return_orders'), align: 'center' }
  ];

  const handleRequestSort = (property: string) => {
    let ord: 'asc' | 'desc' = 'desc';
    if (orderBy === property) {
      if (order === 'asc') {
        ord = 'desc';
      } else if (order === 'desc') {
        ord = 'asc';
      }
    }
    setOrder(ord);
    setOrderBy(property);
    onRequestSort(property, ord);
  };

  useEffect(() => {
    if (data.length > 0) {
      if (Math.floor(data.length / rowsPerPage) === page) {
        const rpPage = data.length % rowsPerPage;
        makeSummary(rpPage);
        setEmptyRows(rowsPerPage - rpPage);
      } else {
        makeSummary(rowsPerPage);
        setEmptyRows(0);
      }
    } else {
      setSummary({
        orders: 0,
        phyOrders: 0,
        revenue: 0,
        returns: 0,
        phyReturns: 0,
        tending: 0
      });
    }
  }, [data, page, orderBy, rowsPerPage]);

  function makeSummary(vRows: number) {
    const s = {
      orders: 0,
      phyOrders: 0,
      revenue: 0,
      returns: 0,
      phyReturns: 0,
      tending: 0
    };
    data.length > 0 &&
      data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach(
          ({
            orders,
            returns,
            revenue,
            tending,
            phyOrders,
            phyReturns
          }: RevenueByCountryRow) => {
            s.orders += orders;
            s.phyOrders += phyOrders;
            s.revenue += revenue;
            s.returns += returns;
            s.phyReturns += phyReturns;
            s.tending += tending;
          }
        );
    setSummary({
      orders: s.orders,
      phyOrders: s.phyOrders,
      revenue: s.revenue,
      returns: s.returns,
      phyReturns: s.phyReturns,
      tending: s.tending / vRows
    });
  }

  return (
    <Paper variant="outlined" className={classes.tablePaper}>
      {loading && <CardLoading />}
      {error && <CardError />}
      {!loading && data.length === 0 && !error && <CardNoResult />}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((header: any, key) => (
                <TableCell
                  className={classes.headerCell}
                  key={`${header.field}-${key}`}
                  align="center"
                >
                  <TableSortLabel
                    sx={{ boxShadow: 0 }}
                    active={orderBy === header.id_h}
                    direction={orderBy === header.id_h ? order : 'asc'}
                    onClick={() => handleRequestSort(header.id_h)}
                  >
                    <span>{header.label}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 && (
              <TableRowSummary
                startEmptyCells={0}
                endEmptyCells={0}
                cells={[
                  {
                    value2:
                      data
                        .map((row) => row.tending)
                        .reduce((prev: number, next: number) => prev + next) /
                      data.length,
                    value: summary.tending,
                    type: 'percent',
                    align: 'center',
                    paddingRight: '18px',
                    calculation: 'average'
                  },
                  {
                    value2: data
                      .map((row) => row.phyOrders)
                      .reduce((prev: number, next: number) => prev + next),
                    value: summary.phyOrders,
                    type: 'integer',
                    align: 'center',
                    paddingRight: '18px',
                    calculation: 'sum'
                  },
                  {
                    value2: data
                      .map((row) => row.orders)
                      .reduce((prev: number, next: number) => prev + next),
                    value: summary.orders,
                    type: 'integer',
                    align: 'center',
                    paddingRight: '18px',
                    calculation: 'sum'
                  },
                  {
                    value2: data
                      .map((row) => row.revenue)
                      .reduce((prev: number, next: number) => prev + next),
                    value: summary.revenue,
                    type: 'currency',
                    align: 'center',
                    paddingRight: '18px',
                    calculation: 'sum'
                  },
                  {
                    value2: data
                      .map((row) => row.returns)
                      .reduce((prev: number, next: number) => prev + next),
                    value: summary.phyReturns,
                    type: 'integer',
                    align: 'right',
                    paddingRight: '18px',
                    calculation: 'sum'
                  }
                ]}
              />
            )}
            {data.length > 0 &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (
                    {
                      code,
                      country,
                      orders,
                      phyOrders,
                      revenue,
                      phyReturns,
                      tending,
                      revenuePrev
                    },
                    i: number
                  ) => (
                    <TableRow
                      hover
                      key={`${i}-${Math.random()}`}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.tableCellPaddingRightSmall}>
                        <CountryCell
                          percent={
                            revenueTotal > 0
                              ? (100 * revenue) / revenueTotal
                              : 0
                          }
                          code={code ?? 'de'}
                          name={country}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingRight: '18px'
                          }}
                        >
                          <Tending
                            showTrend={revenuePrev > 0}
                            value={tending}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: 'center', paddingRight: '18px' }}
                        >
                          {phyOrders}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: 'center', paddingRight: '18px' }}
                        >
                          {orders}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: 'center', paddingRight: '18px' }}
                        >
                          {new Intl.NumberFormat(currentLang.value, {
                            style: 'currency',
                            currency: iso
                          }).format(Number(revenue))}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body1"
                          sx={{ textAlign: 'center' }}
                        >
                          {phyReturns}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
            {emptyRows > 0 &&
              [...Array(emptyRows)].map((_, key) => (
                <TableRow
                  key={Math.random() + key}
                  className={classes.tableRow}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
