import { Box, Theme, Typography, alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  label: {
    display: 'inline-block',
    padding: ({ color }) => (color === 'transparent' ? '0px' : '6px'),
    borderRadius: theme.shape.borderRadius,
    border: ({ color }) => `1px solid ${color}`,
    backgroundColor: ({ color }) =>
      color === 'transparent' ? 'transparent' : alpha(color, 0.2),
    '& .MuiTypography-body1': {
      color: ({ color }) =>
        color === 'transparent' ? theme.palette.text.primary : color
    }
  }
}));

type Props = {
  label: string;
  color: string;
};

function StockSelectOption({ label, color }: Props) {
  const classes = useStyles({ color });

  return (
    <Box className={classes.label}>
      <Typography component="span" variant="body1" color={color}>
        {label || ''}
      </Typography>
    </Box>
  );
}

export default StockSelectOption;
