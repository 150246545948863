import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StockRangeProps } from '../../../@types/products';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { helperStyles } from '../../../theme/HelperClasses';
import { textStyles } from '../../../theme/TextClasses';
import { ProgressCircle } from '../../general/ProgressCircle';
import { HeaderWidget } from './HeaderWidget';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0)
  }
}));

export function StockRange() {
  const {
    article: {
      stockRange: { byableStock, daysLeft, noHistory }
    }
  } = useArticlesDetail();
  const { t: translate } = useTranslation(['component']);
  const classes = useStyles();
  const helperClasses = helperStyles();
  const textClasses = textStyles({});
  return (
    <HeaderWidget
      // title={`${translate('stock_range')} - ${period}`}
      title={translate('stock_range')}
      content={
        <Box className={classes.root}>
          <Box className={helperClasses.row}>
            <Box className={helperClasses.cell}>
              <Typography variant="body1" className={textClasses.ellipsis}>
                {translate('stock')}
              </Typography>
            </Box>
            <Box className={helperClasses.cell}>
              <Typography variant="body1" className={textClasses.ellipsis}>
                {translate('reach')}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={helperClasses.row}>
            <Box className={`${helperClasses.cell} ${helperClasses.maxHeight}`}>
              <Typography variant="body1">{byableStock}</Typography>
            </Box>
            <Box className={`${helperClasses.cell} ${helperClasses.maxHeight}`}>
              <ProgressCircle
                invert={false}
                value={daysLeft > 100 ? 100 : daysLeft}
                size={100}
                centerLegend={
                  <Box sx={{ paddingBottom: '4px' }}>
                    {noHistory ? (
                      <Typography align="center" variant="body1">
                        {translate('no_history')}
                      </Typography>
                    ) : (
                      <Typography align="center" variant="body1">
                        {`${Math.floor(
                          daysLeft >= 0 ? daysLeft : 0
                        )} ${translate('days', {
                          count: daysLeft
                        })}`}
                      </Typography>
                    )}
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
