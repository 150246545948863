import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import useLocales from '../../hooks/useLocales';
import { TendingIcon } from './TendingIcon';
// import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles<Theme>({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    height: '100%',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

type Props = {
  value: number;
  // width?: number;
  showTrend?: boolean;
  inverse?: boolean;
};

function Tending({
  value,
  // width = 75,
  showTrend = false,
  inverse = false
}: Props) {
  const classes = useStyles();
  const { currentLang } = useLocales();
  return (
    <Box className={classes.root}>
      {showTrend ? (
        <Box className={classes.container}>
          <TendingIcon tending={value} inverse={inverse} />
          <Typography
            variant="caption"
            sx={{ color: 'text.secondary', marginLeft: '8px' }}
          >
            {new Intl.NumberFormat(currentLang.value, {
              style: 'percent',
              maximumFractionDigits: 1
            }).format(value)}
          </Typography>
        </Box>
      ) : (
        <Icon
          icon="eva:minus-fill"
          width={16}
          height={16}
          color="text.secondary"
        />
      )}
    </Box>
  );
}

export default Tending;
