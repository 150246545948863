import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody
} from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIStatus } from '../../@types/APIStatus';
import { SortBy } from '../../@types/filters';
import { TableHeaderCell } from '../../@types/tableRows';
import useBundle from '../../hooks/useBundle';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { CardError } from '../general/CardError';
import { CardLoading } from '../general/CardLoading';
import { CardNoResult } from '../general/CardNoResult';

type Props = {
  rowsPerPage: number;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
};

function BundleTable({ rowsPerPage, sortBy, onSortChange }: Props) {
  const { height } = useWindowSize();
  const { t: translate } = useTranslation();
  const classes = TableStyleClasses({
    height: 60,
    numRows: rowsPerPage,
    maxSize: height
  });

  const {
    response: { rows, summary },
    APIStatus: state,
    error
  } = useBundle();

  const configTable = (): Array<TableHeaderCell> => {
    const head: Array<TableHeaderCell> = Object.keys(summary).map((h, i) => {
      const align:
        | 'left'
        | 'center'
        | 'right'
        | 'inherit'
        | 'justify'
        | undefined = i === 0 ? 'left' : 'center';

      return {
        field: h,
        label: translate(h),
        align
      };
    });
    return head;
  };

  const [headers] = useState<Array<TableHeaderCell>>(configTable());
  let k: keyof typeof headers;

  return (
    <Paper variant="outlined" className={classes.paper}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      {state === APIStatus.FULFILLED && rows.length === 0 && !error && (
        <CardNoResult />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead sx={{ fontSize: '14px' }}>
            <TableRow>
              {headers.map((header: TableHeaderCell, index: number) => (
                <TableCell
                  className={classes.headerCell}
                  key={header.field}
                  align={header.align}
                >
                  <TableSortLabel
                    sx={{ boxShadow: 0 }}
                    active={sortBy.by === header.field}
                    direction={sortBy.order}
                    onClick={() => {
                      onSortChange({
                        order:
                          sortBy.by === header.field
                            ? sortBy.order === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'desc',
                        by: header.field
                      });
                    }}
                  >
                    <span>{translate(`component:bundle_${header.label}`)}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ variationId, name, asin, ean, sku, qty }, i) => (
              <TableRow
                hover
                key={`${i}_${variationId}`}
                className={classes.tableRow}
              >
                <TableCell>{variationId}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{name}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{asin}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{ean}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{sku}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{qty}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default BundleTable;
