import { useDispatch, useSelector } from 'react-redux';
import { ProfitFilterState } from '../@types/filterBars';
import { RootState } from '../redux/rootReducer';
import { fetchProfit } from '../redux/slices/profit';

// ----------------------------------------------------------------------

function useProfit() {
  const dispatch = useDispatch();
  const { data, filters, APIStatus, error } = useSelector(
    (state: RootState) => state.profit
  );

  return {
    data,
    filters,
    APIStatus,
    error,
    updateProfit: (updatedFilters: ProfitFilterState, articleId: string) => {
      dispatch(fetchProfit(updatedFilters, articleId));
    }
  };
}

export default useProfit;
