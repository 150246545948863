import { alpha, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import { textStyles } from '../../../theme/TextClasses';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    minWidth: 290,
    backgroundColor: alpha('#6c7b88', 0.1),
    backdropFilter: 'blur(8px)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1, 2, 1, 3)
  },

  linesConainer: {
    border: '1px solid red'
  },
  title: {
    // color: '#6c7b88',
    // color: theme.palette.text.primary,
    color: '#3c454e',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(2)
  },
  line: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block'
    // alignItems: 'center',
    // justifyContent: 'flex-start',
    // marginBottom: theme.spacing(1)
  },
  iconContainer: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.5),
    border: `1px solid ${alpha('#fff', 0.5)}`,
    borderRadius: '50%'
  },
  icon: {
    color: '#fff',
    fontSize: 18
    // color: alpha('#fff', 0.5),
  },

  underline: {
    color: '#3c454e',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  label: {
    // border: '1px solid red',
    width: '90px',
    color: '#3c454e'
    // color: '#6c7b88',
    // color: theme.palette.text.primary,
    // marginRight: theme.spacing(1)
  }
}));

type Props = {
  onVariantsClicked: () => void;
};

export function ProductInfo({ onVariantsClicked }: Props) {
  const textClasses = textStyles({ lines: 1 });
  const {
    article: { name, ean, asin, sku, variants, articleVariationId }
  } = useArticlesDetail();
  const classes = useStyles();
  const { t: translate } = useTranslation(['component']);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.title}>
          {name}
        </Typography>
      </Grid>
      <Grid container item xs={12} lg={6} spacing={1} flexDirection="column">
        <Grid container item>
          <Grid item xs={4} sx={{ flexGrow: 0 }}>
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {translate('component:ean')}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              fontWeight={700}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {ean}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={4} sx={{ flexGrow: 0 }}>
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {translate('component:asin')}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              fontWeight={700}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {asin}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={4} sx={{ flexGrow: 0 }}>
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {translate('component:sku')}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              fontWeight={700}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {sku}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item sx={{ flexGrow: 0 }}>
          <Grid item xs={4}>
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {translate('component:variants')}
            </Typography>
          </Grid>

          <Grid
            item
            sx={{ flexGrow: 1 }}
            xs={8}
            onClick={() => onVariantsClicked()}
          >
            <Typography
              variant="h6"
              fontWeight={700}
              className={classes.underline}
            >
              {variants}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        lg={6}
        sx={{ paddingTop: { xs: '8px', lg: '0px' } }}
        flexDirection="column"
      >
        <Grid container item>
          <Grid item xs={4} sx={{ flexGrow: 0 }}>
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {translate('component:product_internal_id')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              fontWeight={700}
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {articleVariationId}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
