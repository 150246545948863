import { Theme, Typography, Box } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import { TimelineDate } from '../../@types/orders';

const useStyles = makeStyles<Theme>((theme) => ({
  iconDot: {
    '&.MuiTimelineDot-root.MuiTimelineDot-filled': {
      border: 0,
      margin: 0
    }
  },
  lineConnector: {
    '&.MuiTimelineConnector-root': {
      backgroundColor: 'transparent'
    }
  },
  dateContainer: {
    minHeight: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

type Props = {
  timelineDate: TimelineDate;
};

export default function OrderTimelineItem({
  timelineDate: { formatDate, formatTime, text, icon }
}: Props) {
  const classes = useStyles();
  const theme: Theme = useTheme();

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Box
          className={classes.dateContainer}
          sx={{ paddingRight: icon ? '0px' : '24px' }}
        >
          <Typography textAlign="start" fontWeight={700} variant="body1">
            {formatDate}
          </Typography>
          <Typography textAlign="start" variant="body2">
            {formatTime}
          </Typography>
        </Box>
      </TimelineOppositeContent>

      <TimelineSeparator>
        <TimelineConnector />
        {icon ? (
          <TimelineDot
            color="inherit"
            variant="filled"
            className={classes.iconDot}
          >
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: icon,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={48}
              width={48}
              isStopped={true}
            />
          </TimelineDot>
        ) : (
          <TimelineDot color="primary" variant="outlined" />
        )}
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent>
        <Box
          className={classes.dateContainer}
          sx={{ paddingLeft: icon ? '0px' : '24px' }}
        >
          <Typography textAlign="start" fontWeight={700} variant="body1">
            {text}
          </Typography>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}
