import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { MouseEvent } from 'react';

const useStyles = makeStyles<Theme, { warning: boolean }>((theme) => ({
  root: {
    height: 51,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: theme.palette.text.primary
  },
  allText: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  today: {
    color: ({ warning }) =>
      warning ? theme.palette.error.main : theme.palette.text.primary
  }
}));

type TotalTodayProps = {
  total: number;
  today: number;
  warning: boolean;
  onNavigateTo: (buttonPressed: number) => void;
};

export function TotalTodayCell({
  total,
  today,
  warning,
  onNavigateTo
}: TotalTodayProps) {
  const classes = useStyles({ warning });

  function handleMouseClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    onNavigateTo(e.button);
  }

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.allText}
        variant="body2"
        fontWeight={700}
        component="span"
        onClick={handleMouseClick}
      >
        ({total}/
        <Box component="span" className={classes.today}>
          {today}
        </Box>
        )
      </Typography>
    </Box>
  );
}
