import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { store } from '../store';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { BundleState } from '../../@types/reduxStates';
import { BundleResponse } from '../../@types/responsesAPI';

// ----------------------------------------------------------------------

const initialState: BundleState = {
  response: {
    rows: [],
    count: 0,
    summary: {
      variationId: 0,
      name: '',
      asin: '',
      ean: '',
      sku: '',
      qty: 0
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'bundle',
  initialState,
  reducers: {
    setBundle(state: BundleState, action: PayloadAction<BundleResponse>) {
      state.response = action.payload;
    },
    getBundle: (state: BundleState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getBundleSuccess: (
      state: BundleState,
      action: PayloadAction<BundleResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getBundleError: (state: BundleState, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchBundle(articleId: number = 0) {
  return async (dispatch: any) => {
    try {
      dispatch(getBundle());
      const response = await axios.get(`/api/v2/bundleProducts`, {
        params: {
          articleId: articleId > 0 ? articleId : undefined
        }
      });
      dispatch(getBundleSuccess(response.data));
    } catch (error) {
      dispatch(getBundleError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const { setBundle, getBundle, getBundleSuccess, getBundleError } =
  slice.actions;
