import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { TendingIcon } from '../../general/TendingIcon';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  trend: {
    height: 51,
    width: 55,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}));

type TextCellProps = {
  rank: number;
  tending: number;
};

export function TendingCell({ rank, tending }: TextCellProps) {
  const theme: any = useTheme();
  const [color, setColor] = useState<string>(theme.palette.warning.main);
  const classes = useStyles({ color });
  useEffect(() => {
    setColor(
      tending === 0
        ? theme.palette.warning.main
        : tending > 0
        ? theme.palette.success.main
        : theme.palette.error.main
    );
  }, [tending]);

  return (
    <Box className={classes.root}>
      <Box className={classes.trend}>
        <Typography variant="body2" fontWeight={700}>
          {`${rank}.`}
        </Typography>
        <TendingIcon tending={tending} />
      </Box>
    </Box>
  );
}
