import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { PurchaseFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { Pagination, PurchaseState } from '../../@types/reduxStates';
import { PurchaseResponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: PurchaseState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    suppliers: [],
    purchaseStatus: {
      purchaseStatusId: 0,
      purchaseStatusName: 'all',
      color: 'transparent'
    },
    searchText: '',
    columns: []
  },
  response: {
    rows: [],
    total: {
      orderId: 0,
      orderExtId: 0,
      supplier: 0,
      status: 0,
      orderDate: '',
      numberOfSku: 0,
      netProductValue: 0,
      orderedUnits: 0,
      receivedUnits: 0,
      estimatedTimeOfArrival: format(new Date(), 'yyyy-MM-dd'),
      actualArrivalTime: format(new Date(), 'yyyy-MM-dd'),
      stockLocation: '',
      delayDelivery: 0,
      actualStatus: undefined,
      shipCountry: undefined
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setFilters: (
      state: PurchaseState,
      action: PayloadAction<PurchaseFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getPurchase: (state: PurchaseState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getPurchaseSuccess: (
      state: PurchaseState,
      action: PayloadAction<PurchaseResponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getPurchaseError: (
      state: PurchaseState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updatePurchaseFilterBarState(
  filterBar: PurchaseFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
  };
}

export function fetchPurchase(
  filterBar: PurchaseFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
    dispatch(getPurchase());
    try {
      const params = {
        skip,
        limit,
        by,
        order,
        from: filterBar.timePeriod.startFilter,
        till: filterBar.timePeriod.endFilter,
        suppliers:
          filterBar.suppliers.length > 0
            ? filterBar.suppliers.map((c) => c.supplierId).join(',')
            : undefined,
        purchaseStatusId:
          filterBar.purchaseStatus.purchaseStatusId > 0
            ? filterBar.purchaseStatus.purchaseStatusId
            : undefined,
        searchText:
          filterBar.searchText.length > 0 ? filterBar.searchText : undefined
      };
      const response = await axios.get('/api/v1/purchase', {
        params
      });

      dispatch(getPurchaseSuccess(response.data));
    } catch (error) {
      dispatch(getPurchaseError(error as APIError));
    }
  };
}

export const { setFilters, getPurchase, getPurchaseError, getPurchaseSuccess } =
  slice.actions;

export default slice.reducer;
