import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  GridSize
} from '@material-ui/core';
import { ReactNode } from 'react';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { FilterSelect } from '../items/filters/FilterSelect';
import SelectTimePeriod from './SelectTimePeriod';
import { AreaChartFiltersState } from '../../@types/filterBars';
import useFilters from '../../hooks/useFilters';
import {
  ShopSelectItem,
  SourceSelectItem,
  CustomerSelectItem
} from '../../@types/filters';

type AreaChartFilterProps = {
  ready?: boolean;
  showCountry?: boolean;
  showSources?: boolean;
  showDateRange?: boolean;
  showProfit?: boolean;
  title: string;
  children: ReactNode;
  filters: AreaChartFiltersState;
  columnsByFilter?: GridSize;
  onFiltersChange: (filters: AreaChartFiltersState) => void;
};

export default function AreaChartFilter({
  ready = true,
  showCountry = true,
  showSources = true,
  showDateRange = true,
  showProfit = false,
  columnsByFilter = 3,
  title,
  children,
  filters,
  onFiltersChange
}: AreaChartFilterProps) {
  const { t: translate } = useTranslation(['component']);

  const { response } = useFilters();

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={translate(title)} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12} justifyContent="flex-end">
            {response.shops.length > 1 && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    response.shops.findIndex(
                      (s) => s.shopId === filters.shopId
                    ) + 1 ?? 0
                  }
                  title={translate('component:account')}
                  labels={[
                    translate('filter:all_shops'),
                    ...response.shops.map(
                      (shop: ShopSelectItem) => shop.shopName
                    )
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      shopId:
                        option === 0 ? 0 : response.shops[option - 1].shopId
                    });
                  }}
                />
              </Grid>
            )}
            {response.sources.length > 0 && showSources && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    response.sources.findIndex(
                      (s) => s.sourceId === filters.sourceId
                    ) + 1 ?? 0
                  }
                  title={translate('sources')}
                  labels={[
                    translate('filter:all_sources'),
                    ...response.sources.map(
                      (s: SourceSelectItem) => s.sourceName
                    )
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      sourceId:
                        option === 0 ? 0 : response.sources[option - 1].sourceId
                    });
                  }}
                />
              </Grid>
            )}
            {response.customers &&
              response.customers.length > 0 &&
              showSources && (
                <Grid item xs={12} sm={6} lg={columnsByFilter}>
                  <FilterSelect
                    disabled={!ready}
                    selected={
                      response.customers.findIndex(
                        (s) => s.customerId === filters.customerId
                      ) + 1 ?? 0
                    }
                    title={translate('component:customer_class')}
                    labels={[
                      translate('filter:all_customers'),
                      ...response.customers.map(
                        (s: CustomerSelectItem) => s.customerName
                      )
                    ]}
                    onChangeSelected={(option) => {
                      onFiltersChange({
                        ...filters,
                        customerId:
                          option === 0
                            ? 0
                            : response.customers[option - 1].customerId
                      });
                    }}
                  />
                </Grid>
              )}
            {showProfit && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={filters.profit ?? 0}
                  title={translate('profit_by')}
                  labels={[translate('by_order'), translate('by_invoice')]}
                  onChangeSelected={() => {}}
                />
              </Grid>
            )}

            {showCountry && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    response.countries.findIndex(
                      (c) => c.countryId === filters.countryId
                    ) + 1 ?? 0
                  }
                  title={translate('filter:country')}
                  labels={[
                    translate('filter:all_countries'),
                    ...response.countries.map((c) => c.countryName)
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      countryId:
                        option === 0
                          ? 0
                          : response.countries[option - 1].countryId
                    });
                  }}
                />
              </Grid>
            )}
            {showDateRange && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <SelectTimePeriod
                  disabled={!ready}
                  startFilter={filters.timePeriod.startFilter}
                  endFilter={filters.timePeriod.endFilter}
                  onChangePeriod={(dRange) => {
                    onFiltersChange({
                      ...filters,
                      timePeriod: dRange
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
