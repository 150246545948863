import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { AreaChartFiltersState } from '../@types/filterBars';
import { RootState } from '../redux/rootReducer';
import {
  fetchSalesByCountry,
  sortTableBy
} from '../redux/slices/salesByCountry';
import { SortBy } from '../@types/filters';

// ----------------------------------------------------------------------

function useSalesByCountry() {
  const dispatch = useDispatch();
  const { response, filters, APIStatus, error } = useSelector(
    (state: RootState) => state.salesByCountry
  );
  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getSalesByCountry = (
    updatedfilters: AreaChartFiltersState,
    idArticle: number
  ) => {
    cancelFetch();
    dispatch(
      fetchSalesByCountry(updatedfilters, abortController.current, idArticle)
    );
  };

  return {
    response,
    filters,
    APIStatus,
    error,
    cancelFetch,
    getSalesByCountry,
    sortTableBy: (sortBy: SortBy) => dispatch(sortTableBy(response, sortBy))
  };
}

export default useSalesByCountry;
