import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { useWindowSize } from '../../hooks/useWindowSize';
import { PurchaseArticlesRow } from '../../@types/tableRows';
import { NavigateToParams } from '../../@types/menu';
import { SortBy } from '../../@types/filters';
import { CardLoading } from '../general/CardLoading';
import { CardError } from '../general/CardError';
import { CardNoResult } from '../general/CardNoResult';
import { APIStatus } from '../../@types/APIStatus';
import usePurchaseArticles from '../../hooks/usePurchaseArticles';
import TableRowSummary, { SummaryCell } from '../general/TableRowSummary';
import { ProductCell } from '../items/cells/ProductCell';
import { StockCell } from '../items/cells/StockCell';

import useLocales from '../../hooks/useLocales';
import { unknownToNumber } from '../../utils/helper';
import useAuth from '../../hooks/useAuth';

type Props = {
  rowsPerPage: number;
  page: number;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
};

function ArticlesPurchaseTable({
  rowsPerPage,
  page,
  sortBy,
  onSortChange
}: Props) {
  const history = useHistory();
  const { currentLang } = useLocales();
  const { height } = useWindowSize();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const tableClasses = TableStyleClasses({
    height: 122,
    numRows: rowsPerPage,
    maxSize: height
  });

  const { APIStatus: state, error, rows, summary } = usePurchaseArticles();

  const numberFormat = new Intl.NumberFormat(currentLang.value);

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: iso
  });

  function getCell(field: string, row: PurchaseArticlesRow): React.ReactNode {
    switch (field) {
      case 'product': {
        return (
          <ProductCell
            imageSmall={row.articleImage}
            ean={row.articleEan}
            name={row.articleName}
            sku={row.articleSku}
            variants={row.articleVariants}
            asin={row.articleAsin}
            onGoToDetail={(button, tab) =>
              navigateToDetails({
                idArticle: row.articleId,
                buttonPressed: button,
                tab
              })
            }
            disableLink={false}
          />
        );
      }
      case 'articleStock': {
        return <StockCell stock={row.articleStock} label={row.stockLabel} />;
      }
      case 'purchasePrice':
      case 'netProductValue': {
        return (
          <Typography variant="body2">
            {euro.format(
              unknownToNumber(row[field as keyof PurchaseArticlesRow] as number)
            )}
          </Typography>
        );
      }
      case 'delayDelivery': {
        return (
          <Typography variant="body2">
            {numberFormat.format(
              unknownToNumber(row[field as keyof PurchaseArticlesRow] as number)
            )}
          </Typography>
        );
      }
      case 'actualArrivalTime':
      case 'estimatedTimeOfArrival': {
        const data = row[field as keyof PurchaseArticlesRow];
        return <Typography variant="body2">{data || '-'}</Typography>;
      }

      default: {
        return (
          <Typography variant="body2">
            {row[field as keyof PurchaseArticlesRow]}
          </Typography>
        );
      }
    }
  }

  const navigateToDetails = ({
    idArticle,
    buttonPressed = 3,
    tab = 0
  }: NavigateToParams) => {
    if (buttonPressed === 0) {
      history.push(`/product/${idArticle}/${tab}`);
    } else if (buttonPressed === 1) {
      window.open(`/product/${idArticle}/${tab}`);
    }
  };

  return (
    <Paper variant="outlined" sx={{ position: 'relative', minHeight: '122px' }}>
      {state === APIStatus.PENDING && <CardLoading />}
      {error && <CardError />}
      {state === APIStatus.FULFILLED && rows.length === 0 && <CardNoResult />}
      <TableContainer className={tableClasses.tableContainer}>
        <Table stickyHeader>
          <TableHead sx={{ fontSize: '14px' }}>
            <TableRow>
              {summary.map((header, i) => (
                <TableCell
                  className={
                    i === 0
                      ? tableClasses.headerCellSticky
                      : tableClasses.headerCell
                  }
                  key={header.field}
                  align={header.align}
                >
                  <TableSortLabel
                    className={header.field}
                    active={sortBy.by === header.field}
                    direction={
                      sortBy.by === header.field ? sortBy.order : 'asc'
                    }
                    onClick={() => {
                      onSortChange({
                        order:
                          sortBy.by === header.field
                            ? sortBy.order === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'desc',
                        by: header.field
                      });
                    }}
                  >
                    <span>{header.label}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRowSummary
              colSpan={0}
              startEmptyCells={0}
              endEmptyCells={0}
              sticky
              cells={summary
                .map((c) => c.summary)
                .filter((summary) => summary !== undefined)
                .map((summary) => summary as SummaryCell)}
            />
            {rows.map((row: any, key: any) => (
              <TableRow hover key={key} className={tableClasses.tableRow}>
                {summary.map((c, k) => (
                  <TableCell
                    className={
                      k === 0
                        ? `${tableClasses.cellSticky} overChart`
                        : tableClasses.centerCellContent
                    }
                    key={`${c.field}-${k}`}
                    align={c.align}
                  >
                    {getCell(c.field, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ArticlesPurchaseTable;
